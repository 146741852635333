import {
  getCandidateAfterSaleServicesForAppleWatchSlice,
  getDecisionDeviceChangeIncreaseSlice,
  getDeliveryAvailableDateSlice
} from './productServiceSlice';

export {
  getSalesProduct,
  getCandidateAfterSaleServices,
  getInstallmentPriceByProductStockCode,
  getOptionalProducts,
  getOptionalProductDetail,
  getDecisionDeviceChangeIncrease,
  getCandidateAfterSaleServicesForAppleWatch,
  getDeliveryAvailableDate
} from './thunk';
export const { resetDecisionDeviceChangeIncreaseServiceState } = getDecisionDeviceChangeIncreaseSlice.actions;
export const { resetDeliveryAvailableDateServiceState } = getDeliveryAvailableDateSlice.actions;
export const {
  resetCandidateAfterSaleServicesForAppleWatchServiceState
} = getCandidateAfterSaleServicesForAppleWatchSlice.actions;
