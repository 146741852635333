export type IdentityVerificationMethod = '0' | '1' | '2';
export const IdentityVerificationMethods = {
  E_KYC: '0',
  UPLOAD: '1',
  MAIL: '2'
} as const;

export const IdentityVerificationMethodLabels = {
  0: '撮影による本人確認',
  1: '画像アップロードによる本人確認',
  2: '本人限定受取郵便による本人確認'
} as const;
