import { CaseReducer, combineReducers, createSlice } from '@reduxjs/toolkit';
import { order } from 'ols-order-webservice-typescript-client';
import { ServiceState } from '@/typings/ServiceState';
import { getAreas, getPrefectures, getDistricts, getInstorePickupAgencies } from './thunk';

const initialState = {
  loaded: false,
  failed: false,
  response: null,
  error: null
};

/**
 * BFF-AGC-001: エリア情報取得API
 */
export const getAreasSlice = createSlice<ServiceState<order.AreasGetResponseV1>, Record<string, CaseReducer>>({
  name: 'agencyService/getAreas',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAreas.pending, () => initialState)
      .addCase(getAreas.rejected, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: true,
          response: null,
          error: action.payload || null
        }
      }))
      .addCase(getAreas.fulfilled, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: false,
          response: action.payload,
          error: null
        }
      }));
  }
});

/**
 * BFF-AGC-002: 都道府県情報取得API
 */
export const getPrefecturesSlice = createSlice<
  ServiceState<order.PrefecturesGetResponseV1>,
  Record<string, CaseReducer>
>({
  name: 'agencyService/getPrefectures',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPrefectures.pending, () => initialState)
      .addCase(getPrefectures.rejected, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: true,
          response: null,
          error: action.payload || null
        }
      }))
      .addCase(getPrefectures.fulfilled, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: false,
          response: action.payload,
          error: null
        }
      }));
  }
});

/**
 * BFF-AGC-003: 市区町村情報取得API
 */
export const getDistrictsSlice = createSlice<ServiceState<order.DistrictsGetResponseV1>, Record<string, CaseReducer>>({
  name: 'agencyService/getDistricts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDistricts.pending, () => initialState)
      .addCase(getDistricts.rejected, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: true,
          response: null,
          error: action.payload || null
        }
      }))
      .addCase(getDistricts.fulfilled, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: false,
          response: action.payload,
          error: null
        }
      }));
  }
});

/**
 * BFF-AGC-004: 受取可能店舗情報取得API
 */
export const getInstorePickupAgenciesSlice = createSlice<
  ServiceState<order.InstorePickupAgenciesGetResponseV1>,
  Record<string, CaseReducer>
>({
  name: 'agencyService/getInstorePickupAgencies',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getInstorePickupAgencies.pending, () => initialState)
      .addCase(getInstorePickupAgencies.rejected, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: true,
          response: null,
          error: action.payload || null
        }
      }))
      .addCase(getInstorePickupAgencies.fulfilled, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: false,
          response: action.payload,
          error: null
        }
      }));
  }
});

export const reducers = combineReducers({
  getAreas: getAreasSlice.reducer,
  getPrefectures: getPrefecturesSlice.reducer,
  getDistricts: getDistrictsSlice.reducer,
  getInstorePickupAgencies: getInstorePickupAgenciesSlice.reducer
});
