import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import loadable from '@loadable/component';

import { ScrollToTop } from './logicComponents/ScrollToTop';
import { ScreenUrlPaths } from './typings/ScreenUrlPath';
import { FooterWrap as Footer } from './logicComponents/FooterWrap';
import { HeaderWrap as Header } from './logicComponents/HeaderWrap';

const Entrypoint = loadable(() => import('./pages/Entrypoint'), {
  resolveComponent: (components) => components.Entrypoint
});

const OAD0100 = loadable(() => import('./pages/OAD0100'), {
  resolveComponent: (components) => components.OAD0100
});
const OAD0200 = loadable(() => import('./pages/OAD0200'), {
  resolveComponent: (components) => components.OAD0200
});
const OAD0250 = loadable(() => import('./pages/OAD0250'), {
  resolveComponent: (components) => components.OAD0250
});
const OAD0251 = loadable(() => import('./pages/OAD0251'), {
  resolveComponent: (components) => components.OAD0251
});
const AuthCallback = loadable(() => import('./pages/AuthCallback'), {
  resolveComponent: (components) => components.AuthCallback
});
const OAD0300 = loadable(() => import('./pages/OAD0300'), {
  resolveComponent: (components) => components.OAD0300
});
const OAD0400 = loadable(() => import('./pages/OAD0400'), {
  resolveComponent: (components) => components.OAD0400
});
const OAD0410 = loadable(() => import('./pages/OAD0410'), {
  resolveComponent: (components) => components.OAD0410
});
const OAD0500 = loadable(() => import('./pages/OAD0500'), {
  resolveComponent: (components) => components.OAD0500
});
const OAD0600 = loadable(() => import('./pages/OAD0600'), {
  resolveComponent: (components) => components.OAD0600
});
const OAD0610 = loadable(() => import('./pages/OAD0610'), {
  resolveComponent: (components) => components.OAD0610
});
const OAD0620 = loadable(() => import('./pages/OAD0620'), {
  resolveComponent: (components) => components.OAD0620
});
const OAD0700 = loadable(() => import('./pages/OAD0700'), {
  resolveComponent: (components) => components.OAD0700
});
const OAD0710 = loadable(() => import('./pages/OAD0710'), {
  resolveComponent: (components) => components.OAD0710
});
const OAD0720 = loadable(() => import('./pages/OAD0720'), {
  resolveComponent: (components) => components.OAD0720
});
const OAD0750 = loadable(() => import('./pages/OAD0750'), {
  resolveComponent: (components) => components.OAD0750
});
const OAD0751 = loadable(() => import('./pages/OAD0751'), {
  resolveComponent: (components) => components.OAD0751
});
const OAD0752 = loadable(() => import('./pages/OAD0752'), {
  resolveComponent: (components) => components.OAD0752
});
const OAD0800 = loadable(() => import('./pages/OAD0800'), {
  resolveComponent: (components) => components.OAD0800
});
const OAE9000 = loadable(() => import('./pages/OAE9000'), {
  resolveComponent: (components) => components.OAE9000
});
const OAE9000ErrorBoundary = loadable(() => import('./pages/OAE9000'), {
  resolveComponent: (components) => components.OAE9000ErrorBoundary
});
const OAE0100 = loadable(() => import('./pages/OAE0100'), {
  resolveComponent: (components) => components.OAE0100
});
const NotFoundPage = loadable(() => import('./pages/NotFound'), {
  resolveComponent: (components) => components.NotFoundPage
});

const App: React.FC = () => (
  <div>
    <Router>
      <Header />
      <ScrollToTop />
      <OAE9000ErrorBoundary>
        <Switch>
          <Route exact path={ScreenUrlPaths.Root}>
            <Redirect to={`${ScreenUrlPaths.EntryPoint}${window.location.search}`} />
          </Route>
          <Route exact path={ScreenUrlPaths.EntryPointOld}>
            <Redirect to={`${ScreenUrlPaths.EntryPoint}${window.location.search}`} />
          </Route>
          <Route exact path={ScreenUrlPaths.EntryPoint}>
            <Entrypoint />
          </Route>
          <Route exact path={ScreenUrlPaths.ContractType}>
            <OAD0100 />
          </Route>
          <Route exact path={ScreenUrlPaths.PhoneNumberSelection}>
            <OAD0200 />
          </Route>
          <Route exact path={ScreenUrlPaths.MnpCompanySelection}>
            <OAD0250 />
          </Route>
          <Route exact path={ScreenUrlPaths.MnpCompanySelectionCallback}>
            <OAD0251 />
          </Route>
          <Route exact path={ScreenUrlPaths.PhoneNumberSelectionCallback}>
            <AuthCallback />
          </Route>
          <Route exact path={ScreenUrlPaths.ProcedureSelection}>
            <OAD0300 />
          </Route>
          <Route exact path={ScreenUrlPaths.CategorySelection}>
            <OAD0400 />
          </Route>
          <Route exact path={`${ScreenUrlPaths.AccessorySelection}:categoryId`}>
            <OAD0410 />
          </Route>
          <Route exact path={ScreenUrlPaths.Estimate}>
            <OAD0500 />
          </Route>
          <Route exact path={ScreenUrlPaths.Confirming}>
            <OAD0600 />
          </Route>
          <Route exact path={ScreenUrlPaths.AccountTransfer}>
            <OAD0610 />
          </Route>
          <Route exact path={ScreenUrlPaths.AccountTransferCallBack}>
            <OAD0620 />
          </Route>
          <Route exact path={ScreenUrlPaths.PaymentInformationInput}>
            <OAD0700 />
          </Route>
          <Route exact path={ScreenUrlPaths.PaymentInformationAuth}>
            <OAD0710 />
          </Route>
          <Route exact path={ScreenUrlPaths.PaymentInformationAuthCallback}>
            <OAD0720 />
          </Route>
          <Route exact path={ScreenUrlPaths.AuEasyPayment}>
            <OAD0750 />
          </Route>
          <Route exact path={ScreenUrlPaths.AuEasyPaymentCallBack}>
            <OAD0751 />
          </Route>
          <Route exact path={ScreenUrlPaths.AuEasyPaymentError}>
            <OAD0752 />
          </Route>
          <Route exact path={ScreenUrlPaths.Completed}>
            <OAD0800 />
          </Route>
          <Route exact path={`${ScreenUrlPaths.BusinessError}:errorCode`}>
            <OAE0100 />
          </Route>
          <Route exact path={ScreenUrlPaths.SystemError}>
            <OAE9000 />
          </Route>
          <Route exact path="*">
            <NotFoundPage />
          </Route>
        </Switch>
      </OAE9000ErrorBoundary>
      <Footer />
    </Router>
  </div>
);

export default App;
