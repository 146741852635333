import { AppDispatch } from '@/store';
import { ContractDetailScreenNames } from '@/typings/ContractDetailScreenNames';
import { ContractDetailScreenStatuses, twoContractDetailScreenNames } from './contractDetailScreenStatusesSlice';
import {
  setScreenStatus,
  resetScreenStatus,
  setTrueToHideFlag as setTrueHide,
  setFalseToHideFlag as setFalseHide,
  setFalseToFirstTimeFlag as setFalseFirstTime,
  changeTwoScreenStatus as changeScreenStatue
} from './actions';

/**
 * 画面状態更新オペレータ
 * @param screenStatus - 画面状態
 * @returns
 */
export const set = (screenStatus: Partial<ContractDetailScreenStatuses>) => (
  dispatch: AppDispatch
): ReturnType<typeof setScreenStatus> => dispatch(setScreenStatus(screenStatus));

/**
 * 画面状態初期化オペレータ
 * @returns
 */
export const reset = () => (dispatch: AppDispatch): ReturnType<typeof resetScreenStatus> =>
  dispatch(resetScreenStatus());

/**
 * 画面状態変更オペレータ
 * @returns
 */
export const setTrueToHideFlag = (screenName: ContractDetailScreenNames) => (
  dispatch: AppDispatch
): ReturnType<typeof setTrueHide> => dispatch(setTrueHide(screenName));

/**
 * 画面状態変更オペレータ
 * @returns
 */
export const setFalseToHideFlag = (screenName: ContractDetailScreenNames) => (
  dispatch: AppDispatch
): ReturnType<typeof setFalseHide> => dispatch(setFalseHide(screenName));

/**
 * 画面状態変更オペレータ
 * @returns
 */
export const setFalseToFirstTimeFlag = (screenName: ContractDetailScreenNames) => (
  dispatch: AppDispatch
): ReturnType<typeof setFalseFirstTime> => dispatch(setFalseFirstTime(screenName));

/**
 * 画面状態変更オペレータ
 * @returns
 */
export const changeTwoScreenStatus = (screenNames: twoContractDetailScreenNames) => (
  dispatch: AppDispatch
): ReturnType<typeof changeScreenStatue> => dispatch(changeScreenStatue(screenNames));
