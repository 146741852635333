import { order } from 'ols-order-webservice-typescript-client';
import { REACT_APP_BFF_BASE_PATH } from '@/config/env';

type Provider<T> = () => T;

/**
 * API接続設定プロバイダー
 */
export const apiConfigProvider: Provider<order.Configuration> = () => {
  const configuration = new order.Configuration({
    basePath: REACT_APP_BFF_BASE_PATH,
    baseOptions: {
      withCredentials: true,
      timeout: 30000
    }
  });

  return configuration;
};
