/**
 * 電話番号有無区分
 */
export type AuIdTelNumUmuKbnType = '1' | '2' | '3';
export const AuIdTelNumUmuKbnTypes = {
  AuIdNo: '1',
  AuIdOpen: '2',
  AuIdYes: '3'
} as const;
export const AuIdTelNumUmuKbnTypeLabels = {
  '1': 'AuIdNo',
  '2': 'AuIdOpen',
  '3': 'AuIdYes'
} as const;
