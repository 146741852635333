import { order } from 'ols-order-webservice-typescript-client';
import { consoleLog } from '@/utils/functions';
import { NetworkType } from '@/typings/NetworkType';

const { ChargesPlanApi } = order;

export class PlanServiceAvailables {
  private static instance: PlanServiceAvailables;

  private api: order.ChargesPlanApi;

  private constructor(config: order.Configuration) {
    this.api = new ChargesPlanApi(config);
  }

  public static getInstance(config: order.Configuration): PlanServiceAvailables {
    if (!this.instance) {
      this.instance = new PlanServiceAvailables(config);
    }

    return this.instance;
  }

  /**
   * BFF-CHP-007: 料金プランサービス適用可否判定API

   * @returns
   */
  getPlanServiceAvailables(
    auContractId: string,
    dataPlanCode: string,
    callOptionCode: string,
    networkType: NetworkType
  ): Promise<order.PlanServiceAvailableGetResponseV1> {
    consoleLog('BFF-CHP-007: 料金プランサービス適用可否判定API call');
    return this.api
      .getPlanServiceAvailableV1(auContractId, dataPlanCode, callOptionCode, networkType)
      .then((res) => res.data);
  }
}
