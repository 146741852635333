import { Polyfill } from '@/polyfills/index';

const ElementMatchPolyfill: Polyfill = () => {
  if (Element && !Element.prototype.matches) {
    const proto = Element.prototype;
    /* eslint-disable */
    proto.matches =
      // @ts-ignore
      proto.matchesSelector ||
      // @ts-ignore
      proto.mozMatchesSelector ||
      // @ts-ignore
      proto.msMatchesSelector ||
      // @ts-ignore
      proto.oMatchesSelector ||
      proto.webkitMatchesSelector;
    /* eslint-enable */
  }
};

export default ElementMatchPolyfill;
