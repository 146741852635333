import axios from 'axios';
import { REACT_APP_TOKEN_CORP_INFO_REQUEST_URI } from '@/config/env';
import { consoleLog } from '@/utils/functions';

/**
 * トークン・会社情報(リクエスト)
 */
export type TokenCorpInfoRequestObject = {
  /**
   * 加盟店ID
   *
   * 開発、商用どちらも"1K364800000"固定で設定。
   */
  memberShopId: string;

  /**
   * カード情報入力方法
   *
   * "1"固定で設定。
   * 入力した情報を識別する区分
   *  "1"：平文マニュアル入力
   *  "2"：磁気ストライプ
   */
  inputKbn: string;

  /**
   * 会員番号
   *
   * 画面入力した【カード番号】を設定。
   * ※「XXXXXXXXXXXXXXXX」形式(ハイフン不要)
   */
  cardNo: string;

  /**
   * 有効期限
   *
   * 画面入力した【有効期限】を設定。
   * ※「MMYY」形式
   */
  expirationDate: string;

  /**
   * R/W連携フォーマット
   *
   * NULL固定を設定。
   */
  rwCardInfoFormat: null;

  /**
   * カード情報1（磁気情報（JIS1 Trac1））
   *
   * NULL固定を設定。
   */
  jis1Trac1Info: null;

  /**
   * カード情報2（磁気情報（JIS1 Trac2））
   *
   * NULL固定を設定。
   */
  jis1Trac2Info: null;

  /**
   * カード情報3（磁気情報（JIS1 Trac3））
   *
   * NULL固定を設定。
   */
  jis1Trac3Info: null;

  /**
   * カード情報4（磁気情報（JIS2））
   *
   * NULL固定を設定。
   */
  jis2Info: null;

  /**
   * カード情報（JIS1+JIS2）
   *
   * NULL固定を設定。
   */
  jis1jis2Info: null;

  /**
   * ksn情報1
   *
   * NULL固定を設定。
   */
  ksnInfo1: null;

  /**
   * ksn情報2
   *
   * NULL固定を設定。
   */
  ksnInfo2: null;

  /**
   * ksn情報3
   *
   * NULL固定を設定。
   */
  ksnInfo3: null;

  /**
   * APIキー
   *
   * 開発、商用で設定値が異なります。
   */
  apiKey: string;

  /**
   * システムID
   *
   * 開発、商用どちらも"AUCOMONLINESYSID"固定で設定。
   */
  systemId: string;

  /**
   * 処理要求番号
   *
   * "0"固定で設定。
   */
  processingRequestNo: string;
};

/**
 * トークン・会社情報(レスポンス)
 */

export type TokenCorpInfoResponseObject = {
  /**
   * 加盟店ID
   *
   * BFF-ORD-004: 注文情報登録API要求時に、この値を設定。
   */
  memberShopId: string;

  /**
   * アクワイアラ会社コード
   *
   * BFF-ORD-004: 注文情報登録API要求時に、この値を設定。
   */
  acquirerCompCode: string;

  /**
   * カード識別コード
   *
   * BFF-ORD-004: 注文情報登録API要求時に、この値を設定。
   */
  cardDiscriminationCode: string;

  /**
   * トークン化会員番号
   *
   * BFF-ORD-004: 注文情報登録API要求時に、この値を設定。
   */
  tokenizedCardNo: string;

  /**
   * 有効期限
   *
   * BFF-ORD-004: 注文情報登録API要求時に、この値を設定。
   */
  expirationDate: string;

  /**
   * 処理結果区分
   *
   * 処理結果 1:正常。
   */
  resultKbn: string;

  /**
   * 受付番号
   *
   * BFF-ORD-004: 注文情報登録API要求時に、この値を設定。
   */
  receptionNo: string;

  /**
   * システムID
   *
   * BFF-ORD-004: 注文情報登録API要求時に、この値を設定。
   */
  systemId: string;

  /**
   * 処理要求番号
   *
   * BFF-ORD-004: 注文情報登録API要求時に、この値を設定。
   */
  processingRequestNo: string;

  /**
   * 端末番号
   *
   * BFF-ORD-004: 注文情報登録API要求時に、この値を設定。
   */
  clientNo: string;

  /**
   * メッセージコード
   *
   * エラー時のみ返却される。
   */
  messageCode?: string;
};

/**
 * トークン・会社情報取得API
 * ※ 当APIは外部のAPIを直接呼ぶため、自動生成クライアントは使用しない
 */
export const getTokenCorpInfoApi = async (
  request: TokenCorpInfoRequestObject
): Promise<TokenCorpInfoResponseObject> => {
  consoleLog('トークン・会社情報取得API call');
  const response = await axios.post<TokenCorpInfoResponseObject>(
    String(REACT_APP_TOKEN_CORP_INFO_REQUEST_URI),
    request
  );
  return response.data;
};
