import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { order } from 'ols-order-webservice-typescript-client';
import { DiscountCampaignApiService, GetCouponListParameter } from '@/services/discountCampaignApiService';
import { apiConfigProvider } from '@/services/http/apiConfig';
import { ErrorInfo } from '@/typings/ErrorInfo';
import { handleAxiosError } from '@/services/handleAxiosError';

/**
 * BFF-DCP-002: 割引施策一覧取得API
 */
export const getCouponList = createAsyncThunk<
  // Api正常時レスポンスの型
  order.CandidateCouponsGetResponseV1,
  // Apiリクエストパラメータの型
  GetCouponListParameter,
  // Api異常時レスポンスの型
  { rejectValue: ErrorInfo }
>('couponService/getCouponList', async (getCouponListParameter, { rejectWithValue }) => {
  const config = apiConfigProvider();
  try {
    return await DiscountCampaignApiService.getInstance(config).getCouponList(getCouponListParameter);
  } catch (err) {
    const errInfo: AxiosError<order.ApiErrors> = err;
    return rejectWithValue(handleAxiosError(errInfo, 'BFF-DCP-002'));
  }
});
