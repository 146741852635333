import type { RootState } from '@/store';

/**
 * クーポン番号指定方法取得セレクタ
 * @param state - ステート
 * @returns クーポン番号指定方法
 */
export const getCouponNoSpecifyType = (
  state: RootState
): RootState['flow']['sectionPointCouponUse']['couponNoSpecifyType'] =>
  state.flow.sectionPointCouponUse.couponNoSpecifyType;

/**
 * セクションデータチェック処理実施要否取得セレクタ
 * @param state - ステート
 * @returns  セクションデータチェック処理実施要否
 */
export const getIsSectionDataCheckRequired = (
  state: RootState
): RootState['flow']['sectionPointCouponUse']['isSectionDataCheckRequired'] =>
  state.flow.sectionPointCouponUse.isSectionDataCheckRequired;
