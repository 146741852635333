import { CaseReducer, combineReducers, createSlice } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { order } from 'ols-order-webservice-typescript-client';
import { ServiceState } from '@/typings/ServiceState';
import {
  createIdentification,
  uploadIdentificationImages,
  getIdentificationImage,
  createOneTimePassword,
  validOneTimePassword
} from './thunk';

const initialState = {
  loaded: false,
  failed: false,
  response: null,
  error: null
};

/**
 * BFF-IDE-001: 本人確認画像登録API
 */
export const uploadIdentificationImagesSlice = createSlice<
  ServiceState<order.IdentificationImagePostResponseV1>,
  Record<string, CaseReducer>
>({
  name: 'identificationService/uploadIdentificationImages',
  initialState,
  reducers: {
    resetUploadIdentificationImages: (state) => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadIdentificationImages.pending, () => initialState)
      .addCase(uploadIdentificationImages.rejected, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: true,
          response: null,
          error: action.payload || null
        }
      }))
      .addCase(uploadIdentificationImages.fulfilled, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: false,
          response: action.payload,
          error: null
        }
      }));
  }
});

/**
 * BFF-IDE-002: 本人確認画像取得API
 */
export const getIdentificationImageSlice = createSlice<ServiceState<AxiosResponse<ArrayBuffer>>, Record<string, never>>(
  {
    name: 'identificationService/getIdentificationImage',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(getIdentificationImage.pending, () => initialState)
        .addCase(getIdentificationImage.rejected, (state, action) => ({
          ...state,
          ...{
            loaded: true,
            failed: true,
            response: null,
            error: action.payload || null
          }
        }))
        .addCase(getIdentificationImage.fulfilled, (state, action) => ({
          ...state,
          ...{
            loaded: true,
            failed: false,
            response: action.payload,
            error: null
          }
        }));
    }
  }
);

/**
 * BFF-IDE-003: eKYCを利用した本人確認手続き申請API
 */
export const createIdentificationSlice = createSlice<
  ServiceState<order.IdentificationEKycPostResponseV1>,
  Record<string, never>
>({
  name: 'identificationService/createIdentification',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createIdentification.pending, () => initialState)
      .addCase(createIdentification.rejected, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: true,
          response: null,
          error: action.payload || null
        }
      }))
      .addCase(createIdentification.fulfilled, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: false,
          response: action.payload,
          error: null
        }
      }));
  }
});

/**
 * BFF-IDE-004: OTP発行API
 */
export const createOneTimePasswordSlice = createSlice<
  ServiceState<order.CreateOneTimePasswordPostResponseV1>,
  Record<string, never>
>({
  name: 'identificationService/createOneTimePassword',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createOneTimePassword.pending, () => initialState)
      .addCase(createOneTimePassword.rejected, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: true,
          response: null,
          error: action.payload || null
        }
      }))
      .addCase(createOneTimePassword.fulfilled, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: false,
          response: action.payload,
          error: null
        }
      }));
  }
});

/**
 * BFF-IDE-005: OTPチェックAPI
 */
export const validOneTimePasswordSlice = createSlice<
  ServiceState<order.ValidOneTimePasswordGetResponseV1>,
  Record<string, CaseReducer>
>({
  name: 'identificationService/validOneTimePassword',
  initialState,
  reducers: {
    resetValidOneTimePasswords: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(validOneTimePassword.pending, () => initialState)
      .addCase(validOneTimePassword.rejected, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: true,
          response: null,
          error: action.payload || null
        }
      }))
      .addCase(validOneTimePassword.fulfilled, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: false,
          response: action.payload,
          error: null
        }
      }));
  }
});

export const reducers = combineReducers({
  createIdentification: createIdentificationSlice.reducer,
  uploadIdentificationImages: uploadIdentificationImagesSlice.reducer,
  getIdentificationImage: getIdentificationImageSlice.reducer,
  createOneTimePassword: createOneTimePasswordSlice.reducer,
  validOneTimePassword: validOneTimePasswordSlice.reducer
});
