import { order } from 'ols-order-webservice-typescript-client';
import { consoleLog } from '@/utils/functions';

const { UserApi } = order;

export class UserApiService {
  private static instance: UserApiService;

  private api: order.UserApi;

  private constructor(config: order.Configuration) {
    this.api = new UserApi(config);
  }

  public static getInstance(config: order.Configuration): UserApiService {
    if (!this.instance) {
      this.instance = new UserApiService(config);
    }

    return this.instance;
  }

  /**
   * BFF-USR-001: アカウント取得API
   * @returns
   */
  getAccount(): Promise<order.AccountGetResponseV1> {
    consoleLog('BFF-USR-001: アカウント取得API call');
    return this.api.getAccountV1().then((res) => res.data);
  }

  /**
   * BFF-USR-002: ポイント取得API
   * @returns
   */
  getPoint(auContractId: string): Promise<order.PointsGetResponseV1> {
    consoleLog('BFF-USR-002: ポイント取得API call', auContractId);
    return this.api.getPointV1(auContractId).then((res) => res.data);
  }

  /**
   * BFF-USR-003: 契約取得API
   * @param auContractId - au契約ID
   * @param tCategoryOptionCodes - ユーザーの契約関連情報に含まれるT系オプションコード一覧
   * @param birthday - 生年月日
   * @param auPhoneNo - au電話番号
   * @returns
   */
  getContract(
    auContractId: string,
    tCategoryOptionCodes: Array<string>,
    birthday?: string,
    auPhoneNo?: string
  ): Promise<order.ContractGetResponseV1> {
    consoleLog('BFF-USR-003: 契約取得API call', auContractId, tCategoryOptionCodes, birthday, auPhoneNo);
    return this.api.getContractV1(auContractId, tCategoryOptionCodes, birthday, auPhoneNo).then((res) => res.data);
  }

  /**
   * BFF-USR-004: アカウント追加情報取得API
   * @returns
   */
  getAccountComplements(): Promise<order.AccountComplementsGetResponseV1> {
    consoleLog('BFF-USR-004: アカウント追加情報取得API call');
    return this.api.getAccountComplementsV1().then((res) => res.data);
  }

  /**
   * BFF-USR-005: auかんたん決済残高情報取得API
   * @returns
   */
  getAuEasyPayments(processType: '1' | '2' | '3'): Promise<order.AuPaySimplePaymentGetResponseV1> {
    consoleLog('BFF-USR-005: auかんたん決済残高情報取得API call');
    return this.api.getAuEasyPaymentsV1(processType).then((res) => res.data);
  }
}
