/**
 * 保証・サポート区分コード
 */
export type AfterSaleServiceCode =
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | 'e'
  | 'f'
  | '7'
  | '8'
  | '9'
  | 'a'
  | 'b'
  | 'c'
  | 'd'
  | '0'
  | 'z'
  | 'i'
  | 'j'
  | 'k'
  | 'l'
  | 'm'
  | 'n'
  | 'o'
  | 'p'
  | 'q';
export const AfterSaleServiceCodes = {
  RELIABLE_SUPPORT_PACK1: '2',
  FAILURE_LOSS_SUPPORT1: '3',
  USE_SUPPORT1: '4',
  RELIABLE_SUPPORT_PACK2: '5',
  FAILURE_LOSS_SUPPORT_WITH_ACS1: '6',
  FAILURE_LOSS_SUPPORT_WITH_ACS_AND_ICLOUD_STORAGE: 'e',
  APPLE_CARE_PLUS_AU_TERMINAL_SUPPORT: 'f',
  USE_SUPPORT2: '7',
  RELIABLE_SUPPORT_PACK3: '8',
  FAILURE_LOSS_SUPPORT2: '9',
  USE_SUPPORT3: 'a',
  RELIABLE_SUPPORT_PACK4: 'b',
  FAILURE_LOSS_SUPPORT_WITH_ACS2: 'c',
  USE_SUPPORT4: 'd',
  NOT_APPLY: '0',
  CONTRACT_SUPPORT_SERVICE_CANCEL: 'z',
  FAILURE_LOSS_SUPPORT_WITH_CLOUD1: 'i',
  FAILURE_LOSS_SUPPORT_WITH_CLOUD2: 'j',
  RELIABLE_SUPPORT_PACK5: 'k',
  RELIABLE_SUPPORT_PACK6: 'l',
  FAILURE_LOSS_SUPPORT_WIDE_WITH_CLOUD1: 'm',
  FAILURE_LOSS_SUPPORT_WIDE_WITH_CLOUD2: 'n',
  FAILURE_LOSS_SUPPORT_WIDE_WITH_CLOUD3: 'o',
  FAILURE_LOSS_SUPPORT_WIDE_WITH_CLOUD4: 'p',
  FAILURE_LOSS_SUPPORT_WITH_ACS3: 'q'
} as const;
