import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type NetworkState = {
  onProgressCount: number;
  error: string | undefined;
};

const initialState: NetworkState = {
  onProgressCount: 0,
  error: undefined
};

const networkSlice = createSlice({
  name: 'network',
  initialState,
  reducers: {
    start: (state) => ({
      ...state,
      onProgressCount: state.onProgressCount + 1
    }),
    finish: (state) => ({
      ...state,
      onProgressCount: Math.max(state.onProgressCount - 1, 0)
    }),
    errorOccurred: (state, action: PayloadAction<string>) => ({
      ...state,
      error: action.payload
    })
  }
});

export const { actions, reducer } = networkSlice;
