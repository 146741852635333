import { AppDispatch } from '@/store';
import { CartInfo } from '@/typings/CartInfo';
// import { DeliveryDate } from '@/typings/DeliveryDate';
// import { ReceivingPlace } from '@/typings/ReceivingPlace';
import { FileLabelType } from '@/typings/IdentificationFileLabelType';
import { ImportantMatterInfo } from '@/typings/ImportantMatterInfo';
import {
  Accessory,
  BuyTerminal,
  Contract,
  ContractTypeGroup,
  Estimate,
  LineContract,
  LineContractDiscount,
  OrderAdditionInfo,
  OrderState,
  OwnedTerminal,
  Payment,
  Option,
  ReceivingShopInformation,
  ContractAdditionInfo,
  Identification,
  LineContractInfo,
  ContractorInfo,
  Discount,
  AccessoryBuyMethod,
  TerminalBuyMethod,
  AfterCareService,
  Login,
  MnpOnestopInfo,
  EkycRequestParams,
  AuthOpenAuIdInfo
} from './orderSlice';
import {
  setOrderValue,
  setLogin as setLoginValue,
  setMnpOnestopInfo as setMnpOnestopInfoValue,
  setAuthOpenAuIdInfo as setAuthOpenAuIdInfoValue,
  setOwnedTerminal as setOwnedTerminalValue,
  setEstimate as setEstimateValue,
  setContract as setContractValue,
  setPayment as setPaymentValue,
  setOrderAdditionInfo as setOrderAdditionInfoValue,
  setContractTypeGroup as setContractTypeGroupValue,
  setBuyTerminal as setBuyTerminalValue,
  setAccessory as setAccessoryValue,
  setLineContract as setLineContractValue,
  setLineContractDiscount as setLineContractDiscountValue,
  setOption as setOptionValue,
  setAfterCareService as setAfterCareServiceValue,
  setTerminalBuyMethod as setTerminalBuyMethodValue,
  setAccessoryBuyMethod as setAccessoryBuyMethodValue,
  setDiscount as setDiscountValue,
  setContractorInfo as setContractorInfoValue,
  setLineContractInfo as setLineContractInfoValue,
  setIdentification as setIdentificationValue,
  setEkycRequestParams as setEkycRequestParamValue,
  setContractAdditionInfo as setContractAdditionInfoValue,
  setReceivingShopInformation as setReceivingShopInformationValue,
  setEnabledDeliveryDocument,
  setImportantMatter as setImportantMatterInfo,
  setImportantMatterAgree,
  setCreditFacilityNoticeAgree,
  setInstSalelawMailSendingAgree,
  resetOrderWork as resetOrderWorkValue,
  addOptionalGoodsCartInfo,
  deleteOptionalGoodsCartInfo,
  changeOptionalGoodsCartInfoPurchaseCount,
  deleteUploadFile,
  setForcedOpeningAgree
} from './actions';

/**
 * 注文ステート更新オペレータ
 * @param state - 注文ステート
 * @returns
 */
export const setOrder = (state: Partial<OrderState>) => (dispatch: AppDispatch): ReturnType<typeof setOrderValue> =>
  dispatch(setOrderValue(state));

/**
 * ログインステート更新オペレータ
 * @param login - ログイン情報
 * @returns
 */
export const setLogin = (login: Partial<Login>) => (dispatch: AppDispatch): ReturnType<typeof setLoginValue> =>
  dispatch(setLoginValue(login));

/**
 * 事業者選択情報更新オペレータ
 * @param mnpOnestopInfo - ログイン情報
 * @returns
 */
export const setMnpOnestopInfo = (mnpOnestopInfo: Partial<MnpOnestopInfo>) => (
  dispatch: AppDispatch
): ReturnType<typeof setMnpOnestopInfoValue> => dispatch(setMnpOnestopInfoValue(mnpOnestopInfo));

/**
 * OpenAuId認証情報オペレータ
 * @param authOpenAuIdInfo - ログイン情報
 * @returns
 */
export const setAuthOpenAuIdInfo = (authOpenAuIdInfo: Partial<AuthOpenAuIdInfo>) => (
  dispatch: AppDispatch
): ReturnType<typeof setAuthOpenAuIdInfoValue> => dispatch(setAuthOpenAuIdInfoValue(authOpenAuIdInfo));

/**
 * 所有端末ステート更新オペレータ
 * @param ownedTerminal - 所有端末情報
 * @returns
 */
export const setOwnedTerminal = (ownedTerminal: Partial<OwnedTerminal>) => (
  dispatch: AppDispatch
): ReturnType<typeof setOwnedTerminalValue> => dispatch(setOwnedTerminalValue(ownedTerminal));

/**
 * 見積ステート更新オペレータ
 * @param estimate - 見積情報
 * @returns
 */
export const setEstimate = (estimate: Partial<Estimate>) => (
  dispatch: AppDispatch
): ReturnType<typeof setEstimateValue> => dispatch(setEstimateValue(estimate));

/**
 * 契約ステート更新オペレータ
 * @param contract - 契約情報
 * @returns
 */
export const setContract = (contract: Contract) => (dispatch: AppDispatch): ReturnType<typeof setContractValue> =>
  dispatch(setContractValue(contract));

/**
 * 決済ステート更新オペレータ
 * @param payment - 決済情報
 * @returns
 */
export const setPayment = (payment: Payment) => (dispatch: AppDispatch): ReturnType<typeof setPaymentValue> =>
  dispatch(setPaymentValue(payment));

/**
 * 注文付加情報ステート更新オペレータ
 * @param orderAdditionInfo - 注文付加情報
 * @returns
 */
export const setOrderAdditionInfo = (orderAdditionInfo: OrderAdditionInfo) => (
  dispatch: AppDispatch
): ReturnType<typeof setOrderAdditionInfoValue> => dispatch(setOrderAdditionInfoValue(orderAdditionInfo));

/**
 * 見積ステート（無料同梱品カート情報）設定オペレータ
 * @param freeBundledGoodsCartInfo - 無料同梱品カート情報
 * @returns
 */
export const setFreeBundledGoodsCartInfo = (freeBundledGoodsCartInfo: CartInfo | undefined) => (
  dispatch: AppDispatch
): ReturnType<typeof setAccessoryValue> =>
  dispatch(
    setAccessoryValue({
      freeBundledGoodsCartInfo
    })
  );

/**
 * 見積ステート（オプション品カート情報リスト）設定オペレータ
 * @param optionalGoodsCartInfoList - オプション品カート情報リスト
 * @returns
 */
export const setOptionalGoodsCartInfoList = (optionalGoodsCartInfoList: Record<string, CartInfo>) => (
  dispatch: AppDispatch
): ReturnType<typeof setAccessoryValue> =>
  dispatch(
    setAccessoryValue({
      optionalGoodsCartInfoList
    })
  );

// MEMO 構成が変わることで利用画面担当者で当メソッドの必要可否含めて再検討いただきたく
/**
 * 見積ステート（オプション品カート情報リスト）カート情報追加オペレータ
 * @param cartInfo - 追加するカート情報
 * @returns
 */
export const addOptionalGoodsCartInfoOperation = (cartInfo: CartInfo) => (
  dispatch: AppDispatch
): ReturnType<typeof addOptionalGoodsCartInfo> => dispatch(addOptionalGoodsCartInfo(cartInfo));

// MEMO 構成が変わることで利用画面担当者で当メソッドの必要可否含めて再検討いただきたく
/**
 * 見積ステート（オプション品カート情報リスト）カート情報削除オペレータ
 * @param productStockCode - 削除するカート情報の商品コード
 * @returns
 */
export const deleteOptionalGoodsCartInfoOperation = (productStockCode: string) => (
  dispatch: AppDispatch
): ReturnType<typeof deleteOptionalGoodsCartInfo> => dispatch(deleteOptionalGoodsCartInfo(productStockCode));

// MEMO 構成が変わることで利用画面担当者で当メソッドの必要可否含めて再検討いただきたく
/**
 * 見積ステート（オプション品カート情報リスト）カート情報の購入個数変更オペレータ
 * @param productStockCode - 購入個数を変更するカート情報の商品コード
 * @param purchaseCount - 購入個数
 * @returns
 */
export const changeOptionalGoodsCartInfoPurchaseCountOperation = (productStockCode: string, purchaseCount: number) => (
  dispatch: AppDispatch
): ReturnType<typeof changeOptionalGoodsCartInfoPurchaseCount> =>
  dispatch(changeOptionalGoodsCartInfoPurchaseCount({ productStockCode, purchaseCount }));

// MEMO 構成が変わることで利用画面担当者で当メソッドの必要可否含めて再検討いただきたく
/**
 * 見積ステート（オプション品カート情報リスト）クリアオペレータ
 * @returns
 */
export const clearOptionalGoodsCartInfoList = () => (dispatch: AppDispatch): ReturnType<typeof setAccessoryValue> =>
  dispatch(
    setAccessoryValue({
      optionalGoodsCartInfoList: undefined
    })
  );

// /**
//  * 見積ステート（セクション：受取方法）設定オペレータ
//  * @param receivingPlace - 受取場所
//  * @param deliveryDate - お届け日
//  * @returns
//  */
// export const setReceivingMethod = (orderAdditionInfo: {
//   receivingPlace: ReceivingPlace | undefined;
//   deliveryDate: DeliveryDate | undefined;
// }) => (dispatch: AppDispatch): ReturnType<typeof setOrderValue> =>
//   dispatch(
//     setOrderValue({
//       orderAdditionInfo
//     })
//   );

// /**
//  * 見積ステート（受取店舗情報）クリアオペレータ
//  * @returns
//  */
// export const clearReceivingShopInformation = () => (dispatch: AppDispatch): ReturnType<typeof setOrderValue> =>
//   dispatch(
//     setOrderValue({
//       orderAdditionInfo: { receivingShopInformation: undefined }
//     })
//   );

/**
 * 契約書類郵送フラグ更新オペレータ
 * @param estimateState - 見積ステート
 * @returns
 */
export const setEnabledDeliveryDocumentFlag = (flag: boolean) => (
  dispatch: AppDispatch
): ReturnType<typeof setEnabledDeliveryDocument> => dispatch(setEnabledDeliveryDocument(flag));

/**
 * 重要事項説明情報更新オペレータ
 * @param importantMatterInfo - 重要事項説明情報
 * @returns
 */
export const setImportantMatter = (importantMatterInfo: Partial<ImportantMatterInfo>) => (
  dispatch: AppDispatch
): ReturnType<typeof setImportantMatterInfo> => dispatch(setImportantMatterInfo(importantMatterInfo));

/**
 * 同意事項(重要事項説明)フラグ更新オペレータ
 * @param flag - チェック状態
 * @returns
 */
export const setImportantMatterAgreeFlag = (flag: boolean) => (
  dispatch: AppDispatch
): ReturnType<typeof setImportantMatterAgree> => dispatch(setImportantMatterAgree(flag));

/**
 * 同意事項(信用情報機関への基礎特定信用制度の通知)フラグ更新オペレータ
 * @param flag - チェック状態
 * @returns
 */
export const setCreditFacilityNoticeAgreeFlag = (flag: boolean) => (
  dispatch: AppDispatch
): ReturnType<typeof setCreditFacilityNoticeAgree> => dispatch(setCreditFacilityNoticeAgree(flag));

/**
 * 同意事項(割賦販売法第4条の規定に基づく書面のEメール通知)フラグ更新オペレータ
 * @param flag - チェック状態
 * @returns
 */
export const setInstSaleLawMailSendingAgreeFlag = (flag: boolean) => (
  dispatch: AppDispatch
): ReturnType<typeof setInstSalelawMailSendingAgree> => dispatch(setInstSalelawMailSendingAgree(flag));

/**
 * 同意事項(強制開通の同意)フラグ更新オペレータ
 * @param flag - チェック状態
 * @returns
 */
export const setForcedOpeningAgreeFlag = (flag: boolean) => (
  dispatch: AppDispatch
): ReturnType<typeof setForcedOpeningAgree> => dispatch(setForcedOpeningAgree(flag));

/**
 * 購入端末ステート更新オペレータ
 * @param value - 更新情報
 * @returns
 */
export const setBuyTerminal = (value: Partial<BuyTerminal>) => (
  dispatch: AppDispatch
): ReturnType<typeof setBuyTerminalValue> => dispatch(setBuyTerminalValue(value));

/**
 * アクセサリーステート更新オペレータ
 * @param value - 更新情報
 * @returns
 */
export const setAccessory = (value: Partial<Accessory>) => (
  dispatch: AppDispatch
): ReturnType<typeof setAccessoryValue> => dispatch(setAccessoryValue(value));

/**
 * 回線契約ステート更新オペレータ
 * @param value - 更新情報
 * @returns
 */
export const setLineContract = (value: Partial<LineContract>) => (
  dispatch: AppDispatch
): ReturnType<typeof setLineContractValue> => dispatch(setLineContractValue(value));

/**
 * 回線契約値引ステート更新オペレータ
 * @param value - 更新情報
 * @returns
 */
export const setLineContractDiscount = (value: Partial<LineContractDiscount>) => (
  dispatch: AppDispatch
): ReturnType<typeof setLineContractDiscountValue> => dispatch(setLineContractDiscountValue(value));

/**
 * オプションステート更新オペレータ
 * @param value - 更新情報
 * @returns
 */
export const setOption = (value: Partial<Option>) => (dispatch: AppDispatch): ReturnType<typeof setOptionValue> =>
  dispatch(setOptionValue(value));

/**
 * 契約種別ステート更新オペレータ
 * @param value - 更新情報
 * @returns
 */
export const setContractTypeGroup = (value: Partial<ContractTypeGroup>) => (
  dispatch: AppDispatch
): ReturnType<typeof setContractTypeGroupValue> => dispatch(setContractTypeGroupValue(value));

/**
 * 保証サービスステート更新オペレータ
 * @param value - 更新情報
 * @returns
 */
export const setAfterCareService = (value: Partial<AfterCareService>) => (
  dispatch: AppDispatch
): ReturnType<typeof setAfterCareServiceValue> => dispatch(setAfterCareServiceValue(value));

/**
 * 端末購入方法更新オペレータ
 * @param value - 更新情報
 * @returns
 */
export const setTerminalBuyMethod = (value: Partial<TerminalBuyMethod>) => (
  dispatch: AppDispatch
): ReturnType<typeof setTerminalBuyMethodValue> => dispatch(setTerminalBuyMethodValue(value));

/**
 * アクセサリー購入方法更新オペレータ
 * @param value - 更新情報
 * @returns
 */
export const setAccessoryBuyMethod = (value: Partial<AccessoryBuyMethod>) => (
  dispatch: AppDispatch
): ReturnType<typeof setAccessoryBuyMethodValue> => dispatch(setAccessoryBuyMethodValue(value));

/**
 * 割引更新オペレータ
 * @param value - 更新情報
 * @returns
 */
export const setDiscount = (value: Partial<Discount>) => (dispatch: AppDispatch): ReturnType<typeof setDiscountValue> =>
  dispatch(setDiscountValue(value));

/**
 * 契約者情報更新オペレータ
 * @param value - 更新情報
 * @returns
 */
export const setContractorInfo = (value: Partial<ContractorInfo>) => (
  dispatch: AppDispatch
): ReturnType<typeof setContractorInfoValue> => dispatch(setContractorInfoValue(value));

/**
 * 回線契約情報更新オペレータ
 * @param value - 更新情報
 * @returns
 */
export const setLineContractInfo = (value: Partial<LineContractInfo>) => (
  dispatch: AppDispatch
): ReturnType<typeof setLineContractInfoValue> => dispatch(setLineContractInfoValue(value));

/**
 * 本人確認ステート更新オペレータ
 * @param value - 更新情報
 * @returns
 */
export const setIdentification = (value: Partial<Identification>) => (
  dispatch: AppDispatch
): ReturnType<typeof setIdentificationValue> => dispatch(setIdentificationValue(value));

/**
 * ekycリクエストパラメータ
 * @param value - 更新情報
 * @returns
 */
export const setEkycRequestParam = (value: Partial<EkycRequestParams>) => (
  dispatch: AppDispatch
): ReturnType<typeof setEkycRequestParamValue> => dispatch(setEkycRequestParamValue(value));

/**
 * 契約付加情報ステート更新オペレータ
 * @param value - 更新情報
 * @returns
 */
export const setContractAdditionInfo = (value: Partial<ContractAdditionInfo>) => (
  dispatch: AppDispatch
): ReturnType<typeof setContractAdditionInfoValue> => dispatch(setContractAdditionInfoValue(value));

/**
 * 受取店舗情報ステート更新オペレータ
 * @param value - 更新情報
 * @returns
 */
export const setReceivingShopInformation = (value: Partial<ReceivingShopInformation>) => (
  dispatch: AppDispatch
): ReturnType<typeof setReceivingShopInformationValue> => dispatch(setReceivingShopInformationValue(value));

/**
 * 見積ステート（本人確認画像リスト）設定オペレータ
 * @param uploadFileList - 本人確認画像リスト
 * @returns
 */
export const setIdentificationImageListOperation = (identificationImageList: Record<FileLabelType, string>) => (
  dispatch: AppDispatch
): ReturnType<typeof setIdentificationValue> =>
  dispatch(
    setIdentification({
      identificationImageList
    })
  );

/**
 * 見積ステート（本人確認画像リスト）本人確認画像削除オペレータ
 * @param fileLabel - 本人確認画像名
 * @param image - 本人確認画像オブジェクト
 * @returns
 */
export const deleteUploadFileOperation = (fileLabel: FileLabelType) => (
  dispatch: AppDispatch
): ReturnType<typeof deleteUploadFile> => dispatch(deleteUploadFile(fileLabel));

/**
 * 見積画面から完了画面前までに使用する一連のステートの初期化オペレータ
 * @returns
 */
export const resetOrderWork = () => (dispatch: AppDispatch): ReturnType<typeof resetOrderWorkValue> =>
  dispatch(resetOrderWorkValue());

/**
 * アクセサリーステート初期化オペレータ
 * @returns
 */
export const resetAccessory = () => (dispatch: AppDispatch): ReturnType<typeof setEstimateValue> =>
  dispatch(setEstimateValue({ accessory: undefined }));

/**
 * ポイントステート初期化オペレータ
 * @returns
 */
export const resetPoint = () => (dispatch: AppDispatch): ReturnType<typeof setDiscountValue> =>
  dispatch(
    setDiscountValue({
      usePoint: undefined
    })
  );

/**
 * クーポンステート初期化オペレータ
 * @returns
 */
export const resetCoupon = () => (dispatch: AppDispatch): ReturnType<typeof setDiscountValue> =>
  dispatch(
    setDiscountValue({
      useCoupon: undefined,
      useMyAuCoupon: undefined,
      wltcbMeasureNo: undefined,
      monthlyDiscountMeasureNo: undefined,
      couponMeasureNo: undefined
    })
  );
