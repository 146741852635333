/**
 * 他社請求書情報UL対象フラグデータ型
 */
export type OtherCompanyInvoiceInfo = undefined | '1';

/**
 * 他社請求書情報UL対象フラグデータ
 */
export const OtherCompanyInvoiceInformation = {
  NotTarget: undefined,
  OtherCompanyInvoiceInfo: '1'
} as const;
