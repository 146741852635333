import { order } from 'ols-order-webservice-typescript-client';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CategoryInfo } from '@/typings/CategoryInfo';

/**
 * アクセサリー情報
 */
export type AccessoryState = {
  /**
   * 商品情報
   * （無料同梱品、及び無料同梱品以外のアクセサリー）
   */
  goodsList: order.OptionGoodsListGetResponseV1[];
  /**
   * 無料同梱品ワーニング表示フラグ
   */
  freeBundledGoodsWarningDisplayFlag?: boolean;
  /**
   * カテゴリー一覧表示順リスト
   */
  orderedCategoryList: CategoryInfo[];
};

const initialState: AccessoryState = {
  goodsList: [],
  freeBundledGoodsWarningDisplayFlag: undefined,
  orderedCategoryList: []
};

const slice = createSlice({
  name: 'accessory',
  initialState,
  reducers: {
    setAccessory: (state: AccessoryState, action: PayloadAction<Partial<AccessoryState>>) => ({
      ...state,
      ...action.payload
    })
  }
});

export const { actions, reducer } = slice;
