/**
 * 申込区分
 */
export type ApplyType = '1' | '2';
export const ApplyTypes = {
  Other: '1', // その他
  MainApply: '2' // 本申込
} as const;
export const ApplyTypeLabels = {
  '1': 'Other',
  '2': 'MainApply'
} as const;
