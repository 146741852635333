import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';
// AxiosResponse
import { order } from 'ols-order-webservice-typescript-client';
import { IdentificationTargetType } from '@/typings/IdentificationTargetType';
import { IdentificationType } from '@/typings/IdentificationType';
import { IdentificationApiService } from '@/services/identificationApiService';
import { apiConfigProvider } from '@/services/http/apiConfig';
import { ErrorInfo } from '@/typings/ErrorInfo';
import { handleAxiosError } from '@/services/handleAxiosError';
import { AuxiliaryDocumentType } from '@/typings/AuxiliaryDocumentType';

/**
 * BFF-IDE-001: 本人確認画像登録API
 */
export const uploadIdentificationImages = createAsyncThunk<
  // Api正常時レスポンスの型
  order.IdentificationImagePostResponseV1,
  // Apiリクエストパラメータの型
  {
    identificationTargetType: IdentificationTargetType;
    imageNo: string;
    imageFile: unknown;
    imageFileName: string;
    identificationType: IdentificationType;
    auxiliaryDocumentType: AuxiliaryDocumentType | undefined;
  },
  // Api異常時レスポンスの型
  { rejectValue: ErrorInfo }
>(
  'IdentificationApiService/uploadIdentificationImages',
  async (
    { identificationTargetType, imageNo, imageFile, imageFileName, identificationType, auxiliaryDocumentType },
    { rejectWithValue }
  ) => {
    const config = apiConfigProvider();
    try {
      return await IdentificationApiService.getInstance(config).uploadIdentificationImages(
        identificationTargetType,
        imageNo,
        imageFile,
        imageFileName,
        identificationType,
        auxiliaryDocumentType
      );
    } catch (err) {
      const errInfo: AxiosError<order.ApiErrors> = err;
      return rejectWithValue(handleAxiosError(errInfo, 'BFF-IDE-001'));
    }
  }
);

/**
 * BFF-IDE-002: 本人確認画像登録API
 */
export const getIdentificationImage = createAsyncThunk<
  // Api正常時レスポンスの型
  AxiosResponse<ArrayBuffer>,
  // Apiリクエストパラメータの型
  {
    identificationTargetType: IdentificationTargetType;
    imageNo: string;
    identificationType: IdentificationType;
    auxiliaryDocumentType: AuxiliaryDocumentType;
  },
  // Api異常時レスポンスの型
  { rejectValue: ErrorInfo }
>(
  'IdentificationApiService/getIdentificationImage',
  async ({ identificationTargetType, imageNo, identificationType, auxiliaryDocumentType }, { rejectWithValue }) => {
    const config = apiConfigProvider();
    try {
      return await IdentificationApiService.getInstance(config).getIdentificationImage(
        identificationTargetType,
        imageNo,
        identificationType,
        auxiliaryDocumentType
      );
    } catch (err) {
      const errInfo: AxiosError<order.ApiErrors> = err;
      return rejectWithValue(handleAxiosError(errInfo, 'BFF-IDE-002'));
    }
  }
);

/**
 * BFF-IDE-003: eKYCを利用した本人確認手続き申請API
 */
export const createIdentification = createAsyncThunk<
  // Api正常時レスポンスの型
  order.IdentificationEKycPostResponseV1,
  // Apiリクエストパラメータの型
  {
    identificationEKycPostRequestV1?: order.IdentificationEKycPostRequestV1;
  },
  // Api異常時レスポンスの型
  { rejectValue: ErrorInfo }
>('IdentificationApiService/createIdentification', async ({ identificationEKycPostRequestV1 }, { rejectWithValue }) => {
  const config = apiConfigProvider();
  try {
    return await IdentificationApiService.getInstance(config).createIdentification(identificationEKycPostRequestV1);
  } catch (err) {
    const errInfo: AxiosError<order.ApiErrors> = err;
    return rejectWithValue(handleAxiosError(errInfo, 'BFF-IDE-003'));
  }
});

/**
 * BFF-IDE-004: OTP発行API
 */
export const createOneTimePassword = createAsyncThunk<
  // Api正常時レスポンスの型
  order.CreateOneTimePasswordPostResponseV1,
  // Apiリクエストパラメータの型
  void,
  // Api異常時レスポンスの型
  { rejectValue: ErrorInfo }
>('identificationService/createOneTimePassword', async (_, { rejectWithValue }) => {
  const config = apiConfigProvider();
  try {
    return await IdentificationApiService.getInstance(config).createOneTimePassword();
  } catch (err) {
    const errInfo: AxiosError<order.ApiErrors> = err;
    return rejectWithValue(handleAxiosError(errInfo, 'BFF-IDE-004'));
  }
});

/**
 * BFF-IDE-005: OTPチェックAPI
 */
export const validOneTimePassword = createAsyncThunk<
  // Api正常時レスポンスの型
  order.ValidOneTimePasswordGetResponseV1,
  // Apiリクエストパラメータの型
  {
    oneTimePassword: string;
  },
  // Api異常時レスポンスの型
  { rejectValue: ErrorInfo }
>('identificationService/validOneTimePassword', async ({ oneTimePassword }, { rejectWithValue }) => {
  const config = apiConfigProvider();
  try {
    return await IdentificationApiService.getInstance(config).validOneTimePassword(oneTimePassword);
  } catch (err) {
    const errInfo: AxiosError<order.ApiErrors> = err;
    return rejectWithValue(handleAxiosError(errInfo, 'BFF-IDE-005'));
  }
});
