import { CaseReducer, combineReducers, createSlice } from '@reduxjs/toolkit';
import { order } from 'ols-order-webservice-typescript-client';
import { ServiceState } from '@/typings/ServiceState';
import { getAccount, getPoint, getContract, getAccountComplements, getAuEasyPayments } from './thunk';

const initialState = {
  loaded: false,
  failed: false,
  response: null,
  error: null
};

/**
 * BFF-USR-001: アカウント取得API
 */
export const getAccountSlice = createSlice<ServiceState<order.AccountGetResponseV1>, Record<string, CaseReducer>>({
  name: 'userService/getAccount',
  initialState,
  reducers: {
    resetAccountsServiceState: (state) => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAccount.rejected, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: true,
          response: null,
          error: action.payload || null
        }
      }))
      .addCase(getAccount.fulfilled, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: false,
          response: action.payload,
          error: null
        }
      }));
  }
});

/**
 * BFF-USR-002: ポイント取得API
 */
export const getPointSlice = createSlice<ServiceState<order.PointsGetResponseV1>, Record<string, CaseReducer>>({
  name: 'userService/getPoint',
  initialState,
  reducers: {
    resetPointsServiceState: (state) => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPoint.rejected, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: true,
          response: null,
          error: action.payload || null
        }
      }))
      .addCase(getPoint.fulfilled, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: false,
          response: action.payload,
          error: null
        }
      }));
  }
});

/**
 * BFF-USR-003: 契約取得API
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getContractSlice = createSlice<ServiceState<order.ContractGetResponseV1>, Record<string, CaseReducer>>({
  name: 'userService/getContract',
  initialState,
  reducers: {
    resetContractsServiceState: (state) => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(getContract.rejected, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: true,
          response: null,
          error: action.payload || null
        }
      }))
      .addCase(getContract.fulfilled, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: false,
          response: action.payload,
          error: null
        }
      }));
  }
});

/**
 * BFF-USR-004: アカウント追加情報取得API
 */
export const getAccountComplementsSlice = createSlice<
  ServiceState<order.AccountComplementsGetResponseV1>,
  Record<string, CaseReducer>
>({
  name: 'userService/getAccountComplements',
  initialState,
  reducers: {
    resetAccountComplementsServiceState: (state) => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAccountComplements.rejected, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: true,
          response: null,
          error: action.payload || null
        }
      }))
      .addCase(getAccountComplements.fulfilled, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: false,
          response: action.payload,
          error: null
        }
      }));
  }
});

/**
 * BFF-USR-005: auかんたん決済残高情報取得API
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getAuEasyPaymentSlice = createSlice<
  ServiceState<order.AuPaySimplePaymentGetResponseV1>,
  Record<string, CaseReducer>
>({
  name: 'userService/getAuEasyPayments',
  initialState,
  reducers: {
    resetAuEasyPaymentsServiceState: (state) => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAuEasyPayments.rejected, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: true,
          response: null,
          error: action.payload || null
        }
      }))
      .addCase(getAuEasyPayments.fulfilled, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: false,
          response: action.payload,
          error: null
        }
      }));
  }
});

export const reducers = combineReducers({
  getAccount: getAccountSlice.reducer,
  getPoint: getPointSlice.reducer,
  getContract: getContractSlice.reducer,
  getAccountComplements: getAccountComplementsSlice.reducer,
  getAuEasyPayment: getAuEasyPaymentSlice.reducer
});

export const { actions } = getAccountSlice;
