import {
  createOrderSlice,
  createOrderConfirmationSlice,
  createPaymentSlice,
  createAuthenticatedPaymentSlice,
  createAccountTransferSlice,
  createPaymentMethodSlice,
  paymentAuEasyPaymentSlice,
  createPaymentStatusSlice,
  validAccountTransferSlice
} from './orderPaymentServiceSlice';

export {
  createPaymentStatus,
  createPayment,
  createAuthenticatedPayment,
  createOrder,
  createOrderConfirmation,
  createAccountTransfer,
  validAccountTransfer,
  createPaymentMethod,
  paymentAuEasyPayment
} from './thunk';

export const { resetCreatePayment } = createPaymentSlice.actions;
export const { resetCreatePaymentStatus } = createPaymentStatusSlice.actions;
export const { resetAuthenticatedPayment } = createAuthenticatedPaymentSlice.actions;
export const { resetCreateOrder } = createOrderSlice.actions;
export const { resetCreateOrderConfirmation } = createOrderConfirmationSlice.actions;
export const { resetValidAccountTransfer } = validAccountTransferSlice.actions;
export const { resetPaymentMethod } = createPaymentMethodSlice.actions;
export const { resetAccountTransfer } = createAccountTransferSlice.actions;
export const { resetPaymentAuEasyPayment } = paymentAuEasyPaymentSlice.actions;
