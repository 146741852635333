import { AppDispatch } from '@/store';
import { RequestParameters } from './requestParameterSlice';
import { setRequestParameter, resetRequestParameter } from './actions';

/**
 * リクエストパラメータ更新オペレータ
 * @param requestParameter - リクエストパラメータ
 * @returns
 */
export const setRequestParameters = (requestParameter: Partial<RequestParameters>) => (
  dispatch: AppDispatch
): ReturnType<typeof setRequestParameter> => dispatch(setRequestParameter(requestParameter));

/**
 * リクエストパラメータ初期化オペレータ
 * @returns
 */
export const reset = () => (dispatch: AppDispatch): ReturnType<typeof resetRequestParameter> =>
  dispatch(resetRequestParameter());
