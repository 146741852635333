import { actions } from './contractDetailScreenStatusesSlice';

export const {
  setScreenStatus,
  resetScreenStatus,
  setTrueToHideFlag,
  setFalseToHideFlag,
  setFalseToFirstTimeFlag,
  changeTwoScreenStatus
} = actions;
