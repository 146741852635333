import { AppDispatch } from '@/store';
import { EstimateSectionName } from '@/typings/SectionName';
import { SectionStatuses } from './sectionStatusSlice';
import {
  setSectionStatus,
  resetSectionStatus,
  changeSectionStatus,
  resetMessage as resetMessageValue,
  showErrorSectionStatus
} from './actions';

/**
 * セクション状態更新オペレータ
 * @param sectionStatus - セクション状態
 * @returns
 */
export const set = (sectionStatus: Partial<SectionStatuses>) => (
  dispatch: AppDispatch
): ReturnType<typeof setSectionStatus> => dispatch(setSectionStatus(sectionStatus));

/**
 * セクション状態初期化オペレータ
 * @returns
 */
export const reset = () => (dispatch: AppDispatch): ReturnType<typeof resetSectionStatus> =>
  dispatch(resetSectionStatus());

/**
 * セクション状態変更オペレータ（アコーディオン開閉）
 * @returns
 */
export const change = (sectionName: EstimateSectionName) => (
  dispatch: AppDispatch
): ReturnType<typeof changeSectionStatus> => dispatch(changeSectionStatus(sectionName));

/**
 * セクション状態（エラーメッセージ）初期化オペレータ
 * @returns
 */
export const resetMessage = (sectionName: EstimateSectionName) => (
  dispatch: AppDispatch
): ReturnType<typeof resetMessageValue> => dispatch(resetMessageValue(sectionName));

/**
 * エラー発生セクションを開きメッセージを渡すオペレータ
 * @param sectionName - セクション名
 * @param message - エラーメッセージ
 * @returns
 */
export const showError = (sectionName: EstimateSectionName, message: string) => (
  dispatch: AppDispatch
): ReturnType<typeof showErrorSectionStatus> =>
  dispatch(
    showErrorSectionStatus({
      name: sectionName,
      message
    })
  );
