/**
 * 個人法人種類
 */
export type PrivateCorporateType = '1' | '2' | '3' | '4';
export const PrivateCorporateTypes = {
  PRIVATE: '1',
  CORPORATE: '2',
  BUSINESS: '3',
  OWNER: '4'
} as const;
