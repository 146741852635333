import React, { useEffect } from 'react';
import { useLoading, Loading, useSnackbar, Snackbar, SnackbarHeader, SnackbarBody } from 'ols-order-components';

import { useDebouncedCallback } from 'use-debounce/lib';
import { shallowEqual } from 'react-redux';
import { useAppSelector, useAppDispatch } from '@/store/hooks';
import { setupAxiosInterceptors } from '@/services/http/axiosInterceptors';

import type { RootState } from '@/store';
import { useDetectTabId } from '@/hooks/useDetectTabId';
import { onProgress as onProgressSelector, getError } from './selectors';

const NETWORK_ERROR_SNACKBAR = 'NETWORK_ERROR_SNACKBAR';

export const Networking: React.FC = ({ children }) => {
  useDetectTabId();
  const onProgress = useAppSelector(onProgressSelector);
  const error = useAppSelector(getError);
  const { goodsStkNo, telNum } = useAppSelector(
    (i) => ({
      goodsStkNo: i.flow.requestParameter.goodsStkNo,
      telNum: i.flow.requestParameter.telNum
    }),
    shallowEqual
  );
  const dispatch = useAppDispatch();
  const { showSnackbar } = useSnackbar();

  const { showLoading, hideLoading } = useLoading();

  useEffect(() => {
    // FIXME 本来であれば `stateProvider` のシグネチャを変更し、適切な型を作成するべきだが、暫定的にキャスト（as RootState）で回避する
    setupAxiosInterceptors(() => ({ flow: { requestParameter: { goodsStkNo, telNum } } } as RootState), dispatch);
  }, [dispatch, goodsStkNo, telNum]);

  const hide = useDebouncedCallback(() => hideLoading(), 300);
  const snackbar = useDebouncedCallback(() => showSnackbar(NETWORK_ERROR_SNACKBAR), 100);

  useEffect(() => {
    if (onProgress) {
      showLoading(true);
    } else {
      hide();
    }
  }, [onProgress, hide, showLoading]);

  useEffect(() => {
    if (error) {
      snackbar();
    }
  }, [error, snackbar]);

  return (
    <>
      <Snackbar name={NETWORK_ERROR_SNACKBAR}>
        {({ close }) => (
          <>
            <SnackbarHeader onClose={close} />
            <SnackbarBody>ただいま混み合っております</SnackbarBody>
          </>
        )}
      </Snackbar>
      <Loading />
      {children}
    </>
  );
};
