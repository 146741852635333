/**
 * スマホトクするプログラムのラジオボタン値
 * 0: 申し込まない
 * 1: 申し込む
 */
export type ChangePrivilegeValue = '0' | '1';

export const ChangePrivilegeValues = {
  NotApply: '0',
  Apply: '1'
} as const;
export const ChangePrivilegeValueLabels = {
  '0': '申し込まない',
  '1': '申し込む'
} as const;
