import Encoding from 'encoding-japanese';

const HANKAKU = [
  '\u0020',
  '!',
  '"',
  '#',
  '$',
  '%',
  '&',
  "'",
  '(',
  ')',
  '*',
  '+',
  ',',
  '-',
  '.',
  '/',
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  ':',
  ';',
  '<',
  '=',
  '>',
  '?',
  '@',
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
  '[',
  '\\',
  ']',
  '^',
  '_',
  '`',
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
  '{',
  '|',
  '}',
  '~',
  '｡',
  '｢',
  '｣',
  '､',
  '･',
  'ｦ',
  'ｧ',
  'ｨ',
  'ｧ',
  'ｩ',
  'ｪ',
  'ｫ',
  'ｬ',
  'ｧ',
  'ｬ',
  'ｭ',
  'ｮ',
  'ｯ',
  'ｰ',
  'ｱ',
  'ｲ',
  'ｳ',
  'ｴ',
  'ｵ',
  'ｶ',
  'ｷ',
  'ｸ',
  'ｹ',
  'ｺ',
  'ｻ',
  'ｼ',
  'ｽ',
  'ｾ',
  'ｿ',
  'ﾀ',
  'ﾁ',
  'ﾂ',
  'ﾃ',
  'ﾄ',
  'ﾅ',
  'ﾆ',
  'ﾇ',
  'ﾈ',
  'ﾉ',
  'ﾊ',
  'ﾋ',
  'ﾌ',
  'ﾍ',
  'ﾎ',
  'ﾏ',
  'ﾐ',
  'ﾑ',
  'ﾒ',
  'ﾓ',
  'ﾔ',
  'ﾕ',
  'ﾖ',
  'ﾗ',
  'ﾘ',
  'ﾙ',
  'ﾚ',
  'ﾛ',
  'ﾜ',
  'ﾝ',
  'ﾞ',
  'ﾟ',
  'ｳﾞ',
  'ｶﾞ',
  'ｷﾞ',
  'ｸﾞ',
  'ｹﾞ',
  'ｺﾞ',
  'ｻﾞ',
  'ｼﾞ',
  'ｽﾞ',
  'ｾﾞ',
  'ｿﾞ',
  'ﾀﾞ',
  'ﾁﾞ',
  'ﾂﾞ',
  'ﾃﾞ',
  'ﾄﾞ',
  'ﾊﾞ',
  'ﾋﾞ',
  'ﾌﾞ',
  'ﾍﾞ',
  'ﾎﾞ',
  'ﾊﾟ',
  'ﾋﾟ',
  'ﾌﾟ',
  'ﾍﾟ',
  'ﾎﾟ',
  '\u005C',
  '\u00A5',
  '\u20E5',
  '\u2216',
  '\u2572',
  '\u29F5',
  '\uFE68',
  '\uFF3C',
  '\uFFE5'
];
const ZENKAKU = [
  '\u3000',
  '！',
  '”',
  '＃',
  '＄',
  '％',
  '＆',
  '’',
  '（',
  '）',
  '＊',
  '＋',
  '，',
  '－',
  '．',
  '／',
  '０',
  '１',
  '２',
  '３',
  '４',
  '５',
  '６',
  '７',
  '８',
  '９',
  '：',
  '；',
  '＜',
  '＝',
  '＞',
  '？',
  '＠',
  'Ａ',
  'Ｂ',
  'Ｃ',
  'Ｄ',
  'Ｅ',
  'Ｆ',
  'Ｇ',
  'Ｈ',
  'Ｉ',
  'Ｊ',
  'Ｋ',
  'Ｌ',
  'Ｍ',
  'Ｎ',
  'Ｏ',
  'Ｐ',
  'Ｑ',
  'Ｒ',
  'Ｓ',
  'Ｔ',
  'Ｕ',
  'Ｖ',
  'Ｗ',
  'Ｘ',
  'Ｙ',
  'Ｚ',
  '［',
  '￥',
  '］',
  '＾',
  '＿',
  '‘',
  'ａ',
  'ｂ',
  'ｃ',
  'ｄ',
  'ｅ',
  'ｆ',
  'ｇ',
  'ｈ',
  'ｉ',
  'ｊ',
  'ｋ',
  'ｌ',
  'ｍ',
  'ｎ',
  'ｏ',
  'ｐ',
  'ｑ',
  'ｒ',
  'ｓ',
  'ｔ',
  'ｕ',
  'ｖ',
  'ｗ',
  'ｘ',
  'ｙ',
  'ｚ',
  '｛',
  '｜',
  '｝',
  '￣',
  '。',
  '「',
  '」',
  '、',
  '・',
  'ヲ',
  'ァ',
  'ィ',
  'ァ',
  'ゥ',
  'ェ',
  'ォ',
  'ャ',
  'ァ',
  'ャ',
  'ュ',
  'ョ',
  'ッ',
  'ー',
  'ア',
  'イ',
  'ウ',
  'エ',
  'オ',
  'カ',
  'キ',
  'ク',
  'ケ',
  'コ',
  'サ',
  'シ',
  'ス',
  'セ',
  'ソ',
  'タ',
  'チ',
  'ツ',
  'テ',
  'ト',
  'ナ',
  'ニ',
  'ヌ',
  'ネ',
  'ノ',
  'ハ',
  'ヒ',
  'フ',
  'ヘ',
  'ホ',
  'マ',
  'ミ',
  'ム',
  'メ',
  'モ',
  'ヤ',
  'ユ',
  'ヨ',
  'ラ',
  'リ',
  'ル',
  'レ',
  'ロ',
  'ワ',
  'ン',
  '゛',
  '゜',
  'ヴ',
  'ガ',
  'ギ',
  'グ',
  'ゲ',
  'ゴ',
  'ザ',
  'ジ',
  'ズ',
  'ゼ',
  'ゾ',
  'ダ',
  'ヂ',
  'ヅ',
  'デ',
  'ド',
  'バ',
  'ビ',
  'ブ',
  'ベ',
  'ボ',
  'パ',
  'ピ',
  'プ',
  'ペ',
  'ポ',
  '￥',
  '￥',
  '￥',
  '￥',
  '￥',
  '￥',
  '￥',
  '￥',
  '￥'
];

const HANKAKU_KANA = [
  'ｦ',
  'ｧ',
  'ｨ',
  'ｧ',
  'ｩ',
  'ｪ',
  'ｫ',
  'ｬ',
  'ｧ',
  'ｬ',
  'ｭ',
  'ｮ',
  'ｯ',
  'ｰ',
  'ｱ',
  'ｲ',
  'ｳ',
  'ｴ',
  'ｵ',
  'ｶ',
  'ｷ',
  'ｸ',
  'ｹ',
  'ｺ',
  'ｻ',
  'ｼ',
  'ｽ',
  'ｾ',
  'ｿ',
  'ﾀ',
  'ﾁ',
  'ﾂ',
  'ﾃ',
  'ﾄ',
  'ﾅ',
  'ﾆ',
  'ﾇ',
  'ﾈ',
  'ﾉ',
  'ﾊ',
  'ﾋ',
  'ﾌ',
  'ﾍ',
  'ﾎ',
  'ﾏ',
  'ﾐ',
  'ﾑ',
  'ﾒ',
  'ﾓ',
  'ﾔ',
  'ﾕ',
  'ﾖ',
  'ﾗ',
  'ﾘ',
  'ﾙ',
  'ﾚ',
  'ﾛ',
  'ﾜ',
  'ﾝ',
  'ﾞ',
  'ﾟ',
  'ｳﾞ',
  'ｶﾞ',
  'ｷﾞ',
  'ｸﾞ',
  'ｹﾞ',
  'ｺﾞ',
  'ｻﾞ',
  'ｼﾞ',
  'ｽﾞ',
  'ｾﾞ',
  'ｿﾞ',
  'ﾀﾞ',
  'ﾁﾞ',
  'ﾂﾞ',
  'ﾃﾞ',
  'ﾄﾞ',
  'ﾊﾞ',
  'ﾋﾞ',
  'ﾌﾞ',
  'ﾍﾞ',
  'ﾎﾞ',
  'ﾊﾟ',
  'ﾋﾟ',
  'ﾌﾟ',
  'ﾍﾟ',
  'ﾎﾟ'
];
const ZENKAKU_KANA = [
  'ヲ',
  'ァ',
  'ィ',
  'ァ',
  'ゥ',
  'ェ',
  'ォ',
  'ャ',
  'ァ',
  'ャ',
  'ュ',
  'ョ',
  'ッ',
  'ー',
  'ア',
  'イ',
  'ウ',
  'エ',
  'オ',
  'カ',
  'キ',
  'ク',
  'ケ',
  'コ',
  'サ',
  'シ',
  'ス',
  'セ',
  'ソ',
  'タ',
  'チ',
  'ツ',
  'テ',
  'ト',
  'ナ',
  'ニ',
  'ヌ',
  'ネ',
  'ノ',
  'ハ',
  'ヒ',
  'フ',
  'ヘ',
  'ホ',
  'マ',
  'ミ',
  'ム',
  'メ',
  'モ',
  'ヤ',
  'ユ',
  'ヨ',
  'ラ',
  'リ',
  'ル',
  'レ',
  'ロ',
  'ワ',
  'ン',
  '゛',
  '゜',
  'ヴ',
  'ガ',
  'ギ',
  'グ',
  'ゲ',
  'ゴ',
  'ザ',
  'ジ',
  'ズ',
  'ゼ',
  'ゾ',
  'ダ',
  'ヂ',
  'ヅ',
  'デ',
  'ド',
  'バ',
  'ビ',
  'ブ',
  'ベ',
  'ボ',
  'パ',
  'ピ',
  'プ',
  'ペ',
  'ポ'
];

/**
 * 渡された2つのa,b配列からオブジェクト\{a[i]:b[i]\}を作る
 * @param a - 配列a
 * @param b - 配列b
 * @returns
 */
const zip = (a: string[], b: string[]) =>
  a.reduce(
    (acc, val, idx) => ({
      ...acc,
      [val]: b[idx]
    }),
    {}
  );

const ZenToHanMapper = zip(ZENKAKU, HANKAKU);
const HanToZenMapper = zip(HANKAKU, ZENKAKU);
// const ZenKanaToHanKanaMapper = zip(ZENKAKU_KANA, HANKAKU_KANA);
const HanKanaToZenKanaMapper = zip(HANKAKU_KANA, ZENKAKU_KANA);

const ZenKanaToDakuonMapper = {
  ウ: 'ヴ',
  カ: 'ガ',
  キ: 'ギ',
  ク: 'グ',
  ケ: 'ゲ',
  コ: 'ゴ',
  サ: 'ザ',
  シ: 'ジ',
  ス: 'ズ',
  セ: 'ゼ',
  ソ: 'ゾ',
  タ: 'ダ',
  チ: 'ヂ',
  ツ: 'ヅ',
  テ: 'デ',
  ト: 'ド',
  ハ: 'バ',
  ヒ: 'ビ',
  フ: 'ブ',
  ヘ: 'ベ',
  ホ: 'ボ'
};
const ZenKanaToHandakuonMapper = { ハ: 'パ', ヒ: 'ピ', フ: 'プ', ヘ: 'ペ', ホ: 'ポ' };

const canZenToHan = (s: string): s is keyof typeof ZenToHanMapper => s in ZenToHanMapper;
const canHanToZen = (s: string): s is keyof typeof HanToZenMapper => s in HanToZenMapper;
// const canZenKanaToHanKana = (s: string): s is keyof typeof ZenKanaToHanKanaMapper => s in ZenKanaToHanKanaMapper;
const canHanKanaToZenKana = (s: string): s is keyof typeof HanKanaToZenKanaMapper => s in HanKanaToZenKanaMapper;
const canChangeDakuonKana = (s: string): s is keyof typeof ZenKanaToDakuonMapper => s in ZenKanaToDakuonMapper;
const canChangeHandakuonKana = (s: string): s is keyof typeof ZenKanaToHandakuonMapper => s in ZenKanaToHandakuonMapper;

/**
 * 全角→半角へ変換(厳格）
 * 半角変換不可の文字列が含まれていた場合、falseを返却します。
 * @param src - 変換対象文字列
 * @returns  変換文字列
 * 全角カナの濁音・半濁音は1文字→2文字変換となります（例）パ→ﾊﾟ
 */
export const zenToHanExact = (src: string): string | boolean =>
  src.split('').reduce((acc: string | false, val: string) => {
    if (acc === false) return false;
    if (canZenToHan(val)) {
      return acc + ZenToHanMapper[val];
    }
    if (canHanToZen(val)) {
      return acc + val;
    }
    return false;
  }, '');

/**
 * 全角→半角へ変換
 * 半角変換可能文字列が含まれていた場合変換します。
 * @param src - 変換対象文字列
 * @returns 変換文字列
 */
export const zenToHan = (src: string): string =>
  src.split('').reduce((acc: string, val: string) => {
    if (canZenToHan(val)) {
      return acc + ZenToHanMapper[val];
    }
    return acc + val;
  }, '');

/**
 * 全角→半角か空文字へ変換
 * 半角変換可能な文字列を半角に変換し、半角変換不可な文字列を空文字に変換します。
 * @param src - 変換対象文字列
 * @returns 変換文字列
 */
export const zenToHanOrEmpty = (src: string): string =>
  src.split('').reduce((acc: string, val: string) => {
    if (canZenToHan(val)) {
      return acc + ZenToHanMapper[val];
    }
    return acc;
  }, '');

/**
 * 半角→全角へ変換(厳格）
 * 全角変換不可の文字列が含まれていた場合、falseを返却します。
 * @param src - 変換対象文字列
 * @returns 変換文字列
 * 半角カナの濁音・半濁音は2文字→1文字変換となります（例）ﾊﾟ→パ
 */
export const hanToZenExact = (src: string): string | boolean =>
  src.split('').reduce((acc: string | false, val: string) => {
    if (acc === false) return false;
    if (canHanKanaToZenKana(val)) {
      if (val === 'ﾞ') {
        const prevChar = acc.slice(-1);
        if (canChangeDakuonKana(prevChar)) {
          return acc.slice(0, acc.length - 1) + ZenKanaToDakuonMapper[prevChar];
        }
        return `${acc}゛`;
      }
      if (val === 'ﾟ') {
        const prevChar = acc.slice(-1);
        if (canChangeHandakuonKana(prevChar)) {
          return acc.slice(0, acc.length - 1) + ZenKanaToHandakuonMapper[prevChar];
        }
        return `${acc}゜`;
      }
      return acc + HanKanaToZenKanaMapper[val];
    }
    if (canHanToZen(val)) {
      return acc + HanToZenMapper[val];
    }
    if (canZenToHan(val)) {
      return acc + val;
    }
    return false;
  }, '');

/**
 * 半角→全角か空文字へ変換
 * 全角文字はそのまま返します。
 * @param src - 変換対象文字列
 * @returns 変換文字列
 * 半角カナの濁音・半濁音は2文字→1文字変換となります（例）ﾊﾟ→パ
 */
export const hanToZenOrEmpty = (src: string): string =>
  src.split('').reduce((acc: string, val: string) => {
    if (canHanKanaToZenKana(val)) {
      if (val === 'ﾞ') {
        const prevChar = acc.slice(-1);
        if (canChangeDakuonKana(prevChar)) {
          return acc.slice(0, acc.length - 1) + ZenKanaToDakuonMapper[prevChar];
        }
        return `${acc}゛`;
      }
      if (val === 'ﾟ') {
        const prevChar = acc.slice(-1);
        if (canChangeHandakuonKana(prevChar)) {
          return acc.slice(0, acc.length - 1) + ZenKanaToHandakuonMapper[prevChar];
        }
        return `${acc}゜`;
      }
      return acc + HanKanaToZenKanaMapper[val];
    }
    if (canHanToZen(val)) {
      return acc + HanToZenMapper[val];
    }
    if (canZenToHan(val)) {
      return acc + val;
    }
    //  全角文字はそのままreturn
    if (!val.match(/[\x20-\x7E\uFF65-\uFF9F]/)) {
      return acc + val;
    }
    return acc;
  }, '');

/**
 * 半角→全角へ変換
 * 全角変換可能文字列が含まれていた場合変換します。
 * @param src - 変換対象文字列
 * @returns 変換文字列
 */
export const hanToZen = (src: string): string =>
  src.split('').reduce((acc: string, val: string) => {
    if (canHanKanaToZenKana(val)) {
      if (val === 'ﾞ') {
        const prevChar = acc.slice(-1);
        if (canChangeDakuonKana(prevChar)) {
          return acc.slice(0, acc.length - 1) + ZenKanaToDakuonMapper[prevChar];
        }
        return `${acc}゛`;
      }
      if (val === 'ﾟ') {
        const prevChar = acc.slice(-1);
        if (canChangeHandakuonKana(prevChar)) {
          return acc.slice(0, acc.length - 1) + ZenKanaToHandakuonMapper[prevChar];
        }
        return `${acc}゜`;
      }
      return acc + HanKanaToZenKanaMapper[val];
    }
    if (canHanToZen(val)) {
      return acc + HanToZenMapper[val];
    }
    return acc + val;
  }, '');

/**
 * 小文字アルファベットを大文字アルファベットに変換する関数
 * @param src - 変換対象文字列
 * @returns 変換文字列
 */
export const lowerToUpper = (src: string): string => src.toUpperCase();

/**
 * カタカナ文字（大文字・小文字）が含まれているか
 * @param str - チェック対象文字列
 * @returns 含まれていればTrue
 */
export const hasKana = (str: string): boolean => !!str.match(/[ァ-ヶーｦ-ﾟ]/);

export const convertShiftJis = (str?: string): string => {
  if (str) {
    const detected = Encoding.detect(str);
    if (detected) {
      const strArray = [];
      for (let i = 0; i < str.length; i += 1) {
        strArray.push(str.charCodeAt(i));
      }
      const encodeArray = Encoding.convert(strArray, { to: 'SJIS', from: detected });
      return Encoding.codeToString(encodeArray);
    }
  }
  return '';
};
