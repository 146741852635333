import type { RootState } from '@/store';
/**
 * 画面状態取得
 * @param state - ステート
 * @returns 画面状態（ScreenStatuses）
 * @example
 */
export const get = (state: RootState): RootState['flow']['contractDetailScreenStatus'] =>
  state.flow.contractDetailScreenStatus;

/**
 * 非表示フラグ取得
 * @param state - ステート
 * @returns 画面状態（ScreenStatuses）
 * @example
 */

export const getRatePlanCategoryHideFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['ratePlanCategoryScreen']['hideFlag'] =>
  state.flow.contractDetailScreenStatus.ratePlanCategoryScreen.hideFlag;
export const getRatePlanHideFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['ratePlanScreen']['hideFlag'] =>
  state.flow.contractDetailScreenStatus.ratePlanScreen.hideFlag;
export const getIncreasedOptionHideFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['increasedOptionScreen']['hideFlag'] =>
  state.flow.contractDetailScreenStatus.increasedOptionScreen.hideFlag;
export const getPhoneNumberForTabletSharePlanHideFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['phoneNumberForTabletSharePlanScreen']['hideFlag'] =>
  state.flow.contractDetailScreenStatus.phoneNumberForTabletSharePlanScreen.hideFlag;
export const getCallOptionHideFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['callOptionScreen']['hideFlag'] =>
  state.flow.contractDetailScreenStatus.callOptionScreen.hideFlag;
export const getAdditionalServiceHideFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['additionalServiceScreen']['hideFlag'] =>
  state.flow.contractDetailScreenStatus.additionalServiceScreen.hideFlag;
export const getAdditionalServicePointHideFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['additionalServicePointScreen']['hideFlag'] =>
  state.flow.contractDetailScreenStatus.additionalServicePointScreen.hideFlag;
export const getAdditionalServiceRefundHideFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['additionalServiceRefundScreen']['hideFlag'] =>
  state.flow.contractDetailScreenStatus.additionalServiceRefundScreen.hideFlag;
export const getContractTwoYearHideFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['contractTwoYearScreen']['hideFlag'] =>
  state.flow.contractDetailScreenStatus.contractTwoYearScreen.hideFlag;
export const getAuPayCardDiscountHideFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['auPayCardDiscountScreen']['hideFlag'] =>
  state.flow.contractDetailScreenStatus.auPayCardDiscountScreen.hideFlag;
export const getFinancialDiscountHideFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['financialDiscountScreen']['hideFlag'] =>
  state.flow.contractDetailScreenStatus.financialDiscountScreen.hideFlag;
export const getFamilyDiscountPlusHideFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['familyDiscountPlusScreen']['hideFlag'] =>
  state.flow.contractDetailScreenStatus.familyDiscountPlusScreen.hideFlag;
export const getAuSmartValueHideFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['auSmartValueScreen']['hideFlag'] =>
  state.flow.contractDetailScreenStatus.auSmartValueScreen.hideFlag;
export const getAuSmartValueMineHideFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['auSmartValueMineScreen']['hideFlag'] =>
  state.flow.contractDetailScreenStatus.auSmartValueMineScreen.hideFlag;
export const getInternetConnectionServiceHideFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['internetConnectionServiceScreen']['hideFlag'] =>
  state.flow.contractDetailScreenStatus.internetConnectionServiceScreen.hideFlag;
export const getSubscribedOptionListHideFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['subscribedOptionListScreen']['hideFlag'] =>
  state.flow.contractDetailScreenStatus.subscribedOptionListScreen.hideFlag;
export const getAuSmartPassHideFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['auSmartPassScreen']['hideFlag'] =>
  state.flow.contractDetailScreenStatus.auSmartPassScreen.hideFlag;

export const getAuWellnessHideFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['auWellnessScreen']['hideFlag'] =>
  state.flow.contractDetailScreenStatus.auWellnessScreen.hideFlag;

export const getAppleMusicHideFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['appleMusicScreen']['hideFlag'] =>
  state.flow.contractDetailScreenStatus.appleMusicScreen.hideFlag;
export const getYouTubePremiumHideFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['youTubePremiumScreen']['hideFlag'] =>
  state.flow.contractDetailScreenStatus.youTubePremiumScreen.hideFlag;
export const getAuBicycleSupportHideFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['auBicycleSupportScreen']['hideFlag'] =>
  state.flow.contractDetailScreenStatus.auBicycleSupportScreen.hideFlag;
export const getAfterCareSaleServiceCodeHideFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['afterCareSaleServiceCodeScreen']['hideFlag'] =>
  state.flow.contractDetailScreenStatus.afterCareSaleServiceCodeScreen.hideFlag;
export const getAfterCareSaleServiceCodeForAppleWatchHideFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['afterCareSaleServiceCodeForAppleWatchScreen']['hideFlag'] =>
  state.flow.contractDetailScreenStatus.afterCareSaleServiceCodeForAppleWatchScreen.hideFlag;
export const getContractAppleCarePlusHideFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['contractAppleCarePlusScreen']['hideFlag'] =>
  state.flow.contractDetailScreenStatus.contractAppleCarePlusScreen.hideFlag;
export const getWarrantySupportHideFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['warrantySupportScreen']['hideFlag'] =>
  state.flow.contractDetailScreenStatus.warrantySupportScreen.hideFlag;
export const getCampaignDiscountHideFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['campaignDiscountScreen']['hideFlag'] =>
  state.flow.contractDetailScreenStatus.campaignDiscountScreen.hideFlag;
export const getMonthlyDiscountCampaignHideFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['monthlyDiscountCampaignScreen']['hideFlag'] =>
  state.flow.contractDetailScreenStatus.monthlyDiscountCampaignScreen.hideFlag;
export const getAfterDayCampaignHideFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['afterDayCampaignScreen']['hideFlag'] =>
  state.flow.contractDetailScreenStatus.afterDayCampaignScreen.hideFlag;
export const getProgramPrivilegeUseHideFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['programPrivilegeUseScreen']['hideFlag'] =>
  state.flow.contractDetailScreenStatus.programPrivilegeUseScreen.hideFlag;
export const getRecoverKitScreenHideFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['recoverKitScreen']['hideFlag'] =>
  state.flow.contractDetailScreenStatus.recoverKitScreen.hideFlag;
export const getChangePrivilegeHideFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['changePrivilegeScreen']['hideFlag'] =>
  state.flow.contractDetailScreenStatus.changePrivilegeScreen.hideFlag;
export const getTradeInProgramHideFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['tradeInProgramScreen']['hideFlag'] =>
  state.flow.contractDetailScreenStatus.tradeInProgramScreen.hideFlag;
export const getSimOnlyWarrantyServiceHideFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['simOnlyWarrantyServiceScreen']['hideFlag'] =>
  state.flow.contractDetailScreenStatus.simOnlyWarrantyServiceScreen.hideFlag;
export const getModelPricePaymentMethodsHideFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['modelPricePaymentMethodsScreen']['hideFlag'] =>
  state.flow.contractDetailScreenStatus.modelPricePaymentMethodsScreen.hideFlag;
export const getAccessoryPaymentMethodsHideFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['accessoryPaymentMethodsScreen']['hideFlag'] =>
  state.flow.contractDetailScreenStatus.accessoryPaymentMethodsScreen.hideFlag;
export const getAccessoryPaymentMethodsBulkHideFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['accessoryPaymentMethodsBulkScreen']['hideFlag'] =>
  state.flow.contractDetailScreenStatus.accessoryPaymentMethodsBulkScreen.hideFlag;
export const getUseSimHideFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['useSimScreen']['hideFlag'] =>
  state.flow.contractDetailScreenStatus.useSimScreen.hideFlag;
export const getSimLockFreeHideFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['simLockFreeScreen']['hideFlag'] =>
  state.flow.contractDetailScreenStatus.simLockFreeScreen.hideFlag;

/**
 * 復帰フラグ取得
 * @param state - ステート
 * @returns 画面状態（ScreenStatuses）
 * @example
 */

export const getRatePlanCategoryReturnValueFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['ratePlanCategoryScreen']['returnValueFlag'] =>
  state.flow.contractDetailScreenStatus.ratePlanCategoryScreen.returnValueFlag;
export const getRatePlanReturnValueFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['ratePlanScreen']['returnValueFlag'] =>
  state.flow.contractDetailScreenStatus.ratePlanScreen.returnValueFlag;
export const getIncreasedOptionReturnValueFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['increasedOptionScreen']['returnValueFlag'] =>
  state.flow.contractDetailScreenStatus.increasedOptionScreen.returnValueFlag;
export const getPhoneNumberForTabletSharePlanReturnValueFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['phoneNumberForTabletSharePlanScreen']['returnValueFlag'] =>
  state.flow.contractDetailScreenStatus.phoneNumberForTabletSharePlanScreen.returnValueFlag;
export const getCallOptionReturnValueFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['callOptionScreen']['returnValueFlag'] =>
  state.flow.contractDetailScreenStatus.callOptionScreen.returnValueFlag;
export const getAdditionalServiceReturnValueFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['additionalServiceScreen']['returnValueFlag'] =>
  state.flow.contractDetailScreenStatus.additionalServiceScreen.returnValueFlag;
export const getAdditionalServicePointReturnValueFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['additionalServicePointScreen']['returnValueFlag'] =>
  state.flow.contractDetailScreenStatus.additionalServicePointScreen.returnValueFlag;
export const getAdditionalServiceRefundReturnValueFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['additionalServiceRefundScreen']['returnValueFlag'] =>
  state.flow.contractDetailScreenStatus.additionalServiceRefundScreen.returnValueFlag;
export const getFamilyDiscountReturnValueFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['familyDiscountPlusScreen']['returnValueFlag'] =>
  state.flow.contractDetailScreenStatus.familyDiscountPlusScreen.returnValueFlag;
export const getAuSmartValueScreenReturnValueFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['auSmartValueScreen']['returnValueFlag'] =>
  state.flow.contractDetailScreenStatus.auSmartValueScreen.returnValueFlag;
export const getAuSmartValueMineScreenReturnValueFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['auSmartValueMineScreen']['returnValueFlag'] =>
  state.flow.contractDetailScreenStatus.auSmartValueMineScreen.returnValueFlag;
export const getContractTwoYearReturnValueFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['contractTwoYearScreen']['returnValueFlag'] =>
  state.flow.contractDetailScreenStatus.contractTwoYearScreen.returnValueFlag;
export const getAuPayCardDiscountReturnValueFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['auPayCardDiscountScreen']['returnValueFlag'] =>
  state.flow.contractDetailScreenStatus.auPayCardDiscountScreen.returnValueFlag;
export const getFinancialDiscountReturnValueFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['financialDiscountScreen']['returnValueFlag'] =>
  state.flow.contractDetailScreenStatus.financialDiscountScreen.returnValueFlag;
export const getInternetConnectionServiceReturnValueFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['internetConnectionServiceScreen']['returnValueFlag'] =>
  state.flow.contractDetailScreenStatus.internetConnectionServiceScreen.returnValueFlag;
export const getSubscribedOptionListReturnValueFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['subscribedOptionListScreen']['returnValueFlag'] =>
  state.flow.contractDetailScreenStatus.subscribedOptionListScreen.returnValueFlag;
export const getAuSmartPassReturnValueFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['auSmartPassScreen']['returnValueFlag'] =>
  state.flow.contractDetailScreenStatus.auSmartPassScreen.returnValueFlag;

export const getAuWellnessReturnValueFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['auWellnessScreen']['returnValueFlag'] =>
  state.flow.contractDetailScreenStatus.auWellnessScreen.returnValueFlag;

export const getAppleMusicReturnValueFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['appleMusicScreen']['returnValueFlag'] =>
  state.flow.contractDetailScreenStatus.appleMusicScreen.returnValueFlag;
export const getYouTubePremiumReturnValueFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['youTubePremiumScreen']['returnValueFlag'] =>
  state.flow.contractDetailScreenStatus.youTubePremiumScreen.returnValueFlag;
export const getAuBicycleSupportReturnValueFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['auBicycleSupportScreen']['returnValueFlag'] =>
  state.flow.contractDetailScreenStatus.auBicycleSupportScreen.returnValueFlag;
export const getAfterCareSaleServiceCodeReturnValueFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['afterCareSaleServiceCodeScreen']['returnValueFlag'] =>
  state.flow.contractDetailScreenStatus.afterCareSaleServiceCodeScreen.returnValueFlag;
export const getAfterCareSaleServiceCodeForAppleWatchReturnValueFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['afterCareSaleServiceCodeForAppleWatchScreen']['returnValueFlag'] =>
  state.flow.contractDetailScreenStatus.afterCareSaleServiceCodeForAppleWatchScreen.returnValueFlag;
export const getContractAppleCarePlusReturnValueFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['contractAppleCarePlusScreen']['returnValueFlag'] =>
  state.flow.contractDetailScreenStatus.contractAppleCarePlusScreen.returnValueFlag;
export const getWarrantySupportReturnValueFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['warrantySupportScreen']['returnValueFlag'] =>
  state.flow.contractDetailScreenStatus.warrantySupportScreen.returnValueFlag;
export const getCampaignDiscountReturnValueFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['campaignDiscountScreen']['returnValueFlag'] =>
  state.flow.contractDetailScreenStatus.campaignDiscountScreen.returnValueFlag;
export const getMonthlyDiscountCampaignReturnValueFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['monthlyDiscountCampaignScreen']['returnValueFlag'] =>
  state.flow.contractDetailScreenStatus.monthlyDiscountCampaignScreen.returnValueFlag;
export const getProgramPrivilegeUseReturnValueFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['programPrivilegeUseScreen']['returnValueFlag'] =>
  state.flow.contractDetailScreenStatus.programPrivilegeUseScreen.returnValueFlag;
export const getRecoverKitReturnValueFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['recoverKitScreen']['returnValueFlag'] =>
  state.flow.contractDetailScreenStatus.recoverKitScreen.returnValueFlag;
export const getChangePrivilegeReturnValueFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['changePrivilegeScreen']['returnValueFlag'] =>
  state.flow.contractDetailScreenStatus.changePrivilegeScreen.returnValueFlag;
export const getTradeInProgramReturnValueFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['tradeInProgramScreen']['returnValueFlag'] =>
  state.flow.contractDetailScreenStatus.tradeInProgramScreen.returnValueFlag;
export const getSimOnlyWarrantyServiceScreenReturnValueFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['simOnlyWarrantyServiceScreen']['returnValueFlag'] =>
  state.flow.contractDetailScreenStatus.simOnlyWarrantyServiceScreen.returnValueFlag;
export const getModelPricePaymentMethodsReturnValueFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['modelPricePaymentMethodsScreen']['returnValueFlag'] =>
  state.flow.contractDetailScreenStatus.modelPricePaymentMethodsScreen.returnValueFlag;
export const getAccessoryPaymentMethodsReturnValueFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['accessoryPaymentMethodsScreen']['returnValueFlag'] =>
  state.flow.contractDetailScreenStatus.accessoryPaymentMethodsScreen.returnValueFlag;
export const getAccessoryPaymentMethodsBulkReturnValueFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['accessoryPaymentMethodsBulkScreen']['returnValueFlag'] =>
  state.flow.contractDetailScreenStatus.accessoryPaymentMethodsBulkScreen.returnValueFlag;
export const getUseSimReturnValueFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['useSimScreen']['returnValueFlag'] =>
  state.flow.contractDetailScreenStatus.useSimScreen.returnValueFlag;
export const getSimLockFreeReturnValueFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['simLockFreeScreen']['returnValueFlag'] =>
  state.flow.contractDetailScreenStatus.simLockFreeScreen.returnValueFlag;

/**
 * 復帰フラグ取得
 * @param state - ステート
 * @returns 画面状態（ScreenStatuses）
 * @example
 */

export const getRatePlanCategoryFirstTimeFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['ratePlanCategoryScreen']['firstTimeFlag'] =>
  state.flow.contractDetailScreenStatus.ratePlanCategoryScreen.firstTimeFlag;
export const getRatePlanFirstTimeFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['ratePlanScreen']['firstTimeFlag'] =>
  state.flow.contractDetailScreenStatus.ratePlanScreen.firstTimeFlag;
export const getIncreasedOptionFirstTimeFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['increasedOptionScreen']['firstTimeFlag'] =>
  state.flow.contractDetailScreenStatus.increasedOptionScreen.firstTimeFlag;
export const getPhoneNumberForTabletSharePlanFirstTimeFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['phoneNumberForTabletSharePlanScreen']['firstTimeFlag'] =>
  state.flow.contractDetailScreenStatus.phoneNumberForTabletSharePlanScreen.firstTimeFlag;
export const getCallOptionFirstTimeFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['callOptionScreen']['firstTimeFlag'] =>
  state.flow.contractDetailScreenStatus.callOptionScreen.firstTimeFlag;
export const getAdditionalServiceFirstTimeFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['additionalServiceScreen']['firstTimeFlag'] =>
  state.flow.contractDetailScreenStatus.additionalServiceScreen.firstTimeFlag;
export const getContractTwoYearFirstTimeFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['contractTwoYearScreen']['firstTimeFlag'] =>
  state.flow.contractDetailScreenStatus.contractTwoYearScreen.firstTimeFlag;
export const getAuPayCardDiscountFirstTimeFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['auPayCardDiscountScreen']['firstTimeFlag'] =>
  state.flow.contractDetailScreenStatus.auPayCardDiscountScreen.firstTimeFlag;
export const getFinancialDiscountFirstTimeFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['financialDiscountScreen']['firstTimeFlag'] =>
  state.flow.contractDetailScreenStatus.financialDiscountScreen.firstTimeFlag;
export const getFamilyDiscountFirstTimeFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['familyDiscountPlusScreen']['firstTimeFlag'] =>
  state.flow.contractDetailScreenStatus.familyDiscountPlusScreen.firstTimeFlag;
export const getAuSmartValueFirstTimeFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['auSmartValueScreen']['firstTimeFlag'] =>
  state.flow.contractDetailScreenStatus.auSmartValueScreen.firstTimeFlag;
export const getAuSmartValueMineFirstTimeFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['auSmartValueMineScreen']['firstTimeFlag'] =>
  state.flow.contractDetailScreenStatus.auSmartValueMineScreen.firstTimeFlag;
export const getInternetConnectionServiceFirstTimeFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['internetConnectionServiceScreen']['firstTimeFlag'] =>
  state.flow.contractDetailScreenStatus.internetConnectionServiceScreen.firstTimeFlag;
export const getSubscribedOptionListFirstTimeFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['subscribedOptionListScreen']['firstTimeFlag'] =>
  state.flow.contractDetailScreenStatus.subscribedOptionListScreen.firstTimeFlag;
export const getAuSmartPassFirstTimeFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['auSmartPassScreen']['firstTimeFlag'] =>
  state.flow.contractDetailScreenStatus.auSmartPassScreen.firstTimeFlag;

export const getAuWellnessFirstTimeFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['auWellnessScreen']['firstTimeFlag'] =>
  state.flow.contractDetailScreenStatus.auWellnessScreen.firstTimeFlag;

export const getAppleMusicFirstTimeFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['appleMusicScreen']['firstTimeFlag'] =>
  state.flow.contractDetailScreenStatus.appleMusicScreen.firstTimeFlag;
export const getYouTubePremiumFirstTimeFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['youTubePremiumScreen']['firstTimeFlag'] =>
  state.flow.contractDetailScreenStatus.youTubePremiumScreen.firstTimeFlag;
export const getAuBicycleSupportFirstTimeFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['auBicycleSupportScreen']['firstTimeFlag'] =>
  state.flow.contractDetailScreenStatus.auBicycleSupportScreen.firstTimeFlag;
export const getAfterCareSaleServiceCodeFirstTimeFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['afterCareSaleServiceCodeScreen']['firstTimeFlag'] =>
  state.flow.contractDetailScreenStatus.afterCareSaleServiceCodeScreen.firstTimeFlag;
export const getAfterCareSaleServiceCodeForAppleWatchFirstTimeFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['afterCareSaleServiceCodeForAppleWatchScreen']['firstTimeFlag'] =>
  state.flow.contractDetailScreenStatus.afterCareSaleServiceCodeForAppleWatchScreen.firstTimeFlag;
export const getContractAppleCarePlusFirstTimeFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['contractAppleCarePlusScreen']['firstTimeFlag'] =>
  state.flow.contractDetailScreenStatus.contractAppleCarePlusScreen.firstTimeFlag;
export const getWarrantySupportFirstTimeFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['warrantySupportScreen']['firstTimeFlag'] =>
  state.flow.contractDetailScreenStatus.warrantySupportScreen.firstTimeFlag;
export const getCampaignDiscountFirstTimeFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['campaignDiscountScreen']['firstTimeFlag'] =>
  state.flow.contractDetailScreenStatus.campaignDiscountScreen.firstTimeFlag;
export const getMonthlyDiscountCampaignFirstTimeFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['monthlyDiscountCampaignScreen']['firstTimeFlag'] =>
  state.flow.contractDetailScreenStatus.monthlyDiscountCampaignScreen.firstTimeFlag;
export const getProgramPrivilegeUseFirstTimeFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['programPrivilegeUseScreen']['firstTimeFlag'] =>
  state.flow.contractDetailScreenStatus.programPrivilegeUseScreen.firstTimeFlag;
export const getRecoverKitFirstTimeFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['recoverKitScreen']['firstTimeFlag'] =>
  state.flow.contractDetailScreenStatus.recoverKitScreen.firstTimeFlag;
export const getChangePrivilegeFirstTimeFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['changePrivilegeScreen']['firstTimeFlag'] =>
  state.flow.contractDetailScreenStatus.changePrivilegeScreen.firstTimeFlag;
export const getTradeInProgramFirstTimeFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['tradeInProgramScreen']['firstTimeFlag'] =>
  state.flow.contractDetailScreenStatus.tradeInProgramScreen.firstTimeFlag;
export const getSimOnlyWarrantyServiceFirstTimeFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['simOnlyWarrantyServiceScreen']['firstTimeFlag'] =>
  state.flow.contractDetailScreenStatus.simOnlyWarrantyServiceScreen.firstTimeFlag;
export const getModelPricePaymentMethodsFirstTimeFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['modelPricePaymentMethodsScreen']['firstTimeFlag'] =>
  state.flow.contractDetailScreenStatus.modelPricePaymentMethodsScreen.firstTimeFlag;
export const getAccessoryPaymentMethodsFirstTimeFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['accessoryPaymentMethodsScreen']['firstTimeFlag'] =>
  state.flow.contractDetailScreenStatus.accessoryPaymentMethodsScreen.firstTimeFlag;
export const getAccessoryPaymentMethodsBulkFirstTimeFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['accessoryPaymentMethodsBulkScreen']['firstTimeFlag'] =>
  state.flow.contractDetailScreenStatus.accessoryPaymentMethodsBulkScreen.firstTimeFlag;
export const getUseSimFirstTimeFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['useSimScreen']['firstTimeFlag'] =>
  state.flow.contractDetailScreenStatus.useSimScreen.firstTimeFlag;
export const getSimLockFreeFirstTimeFlag = (
  state: RootState
): RootState['flow']['contractDetailScreenStatus']['simLockFreeScreen']['firstTimeFlag'] =>
  state.flow.contractDetailScreenStatus.simLockFreeScreen.firstTimeFlag;
