import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { order } from 'ols-order-webservice-typescript-client';

/**
 * 店舗選択画面状態
 */
export type ShopSelectionScreenStatuses = {
  /**
   * 「エリア」選択肢
   */
  areasChoices: order.AreaInfoGetResponseV1[];
  /**
   * 「エリア」選択値
   */
  areasSelectedValue: string;
  /**
   * 「都道府県」選択肢
   */
  prefecturesChoices: order.PrefectureInfoGetResponseV1[];
  /**
   * 「都道府県」選択値
   */
  prefecturesSelectedValue: string;
  /**
   * 「市区町村」選択肢
   */
  districtsChoices: order.DistrictInfoGetResponseV1[];
  /**
   * 「市区町村」選択値
   */
  districtsSelectedValue: string;
  /**
   * 「店舗検索結果」選択肢
   */
  agenciesChoices: order.InstorePickupAgencyInfoGetResponseV1[];
  /**
   * 「店舗検索結果」選択値
   */
  agenciesSelectedValue: string;
};

const initialState: ShopSelectionScreenStatuses = {
  areasChoices: [],
  areasSelectedValue: '',
  prefecturesChoices: [],
  prefecturesSelectedValue: '',
  districtsChoices: [],
  districtsSelectedValue: '',
  agenciesChoices: [],
  agenciesSelectedValue: ''
};

const slice = createSlice({
  name: 'shopSelectionScreenStatus',
  initialState,
  reducers: {
    setShopSelectionScreenStatus: (
      state: ShopSelectionScreenStatuses,
      action: PayloadAction<Partial<ShopSelectionScreenStatuses>>
    ) => ({
      ...state,
      ...action.payload
    }),
    resetShopSelectionScreenStatus: (state: ShopSelectionScreenStatuses) => ({
      ...state,
      ...initialState
    })
  }
});

export const { actions, reducer } = slice;
