import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SectionStatus } from '@/typings/SectionStatus';
import { EstimateSectionName } from '@/typings/SectionName';

export type SectionStatDetail = { status: SectionStatus; valid: boolean; message?: string };

/**
 * セクション状態
 */
export type SectionStatuses = Record<EstimateSectionName, SectionStatDetail>;

export type ErrorSection = { name: EstimateSectionName; message?: string };

export const initialState: SectionStatuses = {
  sectionContractType: { status: 'init', valid: false },
  sectionContractDetail: { status: 'init', valid: false },
  sectionPointCouponUse: { status: 'init', valid: false },
  sectionContractorInformation: { status: 'init', valid: false },
  sectionIdentification: { status: 'init', valid: false },
  sectionReceivingMethod: { status: 'init', valid: false },
  sectionQuickChange: { status: 'init', valid: false },
  sectionPasswordForOrderContentConfirmation: { status: 'init', valid: false }
};

const slice = createSlice({
  name: 'sectionStatus',
  initialState,
  reducers: {
    setSectionStatus: (state: SectionStatuses, action: PayloadAction<Partial<SectionStatuses>>) => ({
      ...state,
      ...action.payload
    }),
    resetSectionStatus: (state: SectionStatuses) => ({
      ...state,
      ...initialState
    }),
    changeSectionStatus: (state: SectionStatuses, action: PayloadAction<EstimateSectionName>) => {
      let newSectionStatuses = { ...state };
      // 一旦 edit のステータスをfixに変更する。(init は変更しない)
      Object.entries(newSectionStatuses).forEach((entry) => {
        const [sectionName, sectionStatus] = entry;
        if (sectionStatus.status === 'edit') {
          newSectionStatuses = {
            ...newSectionStatuses,
            [sectionName]: {
              status: 'fix',
              valid: sectionStatus.valid
            }
          };
        }
      });
      // 引数で渡されたセクションのステータスを edit に変更する
      newSectionStatuses = {
        ...newSectionStatuses,
        [action.payload]: {
          status: 'edit',
          valid: state[action.payload].valid
        }
      };
      return {
        ...state,
        ...newSectionStatuses
      };
    },
    resetMessage: (state: SectionStatuses, action: PayloadAction<EstimateSectionName>) => ({
      ...state,
      [action.payload]: { ...state[action.payload], message: undefined }
    }),
    showErrorSectionStatus: (state: SectionStatuses, action: PayloadAction<ErrorSection>) => {
      let newSectionStatuses = { ...state };
      Object.entries(newSectionStatuses).forEach((entry) => {
        const [sectionName, sectionStatus] = entry;
        if (sectionName === action.payload.name) {
          newSectionStatuses = {
            ...newSectionStatuses,
            [action.payload.name]: {
              status: 'edit',
              valid: state[action.payload.name].valid,
              message: action.payload.message
            }
          };
        } else if (sectionStatus.status === 'edit') {
          newSectionStatuses = {
            ...newSectionStatuses,
            [sectionName]: {
              status: 'fix',
              valid: sectionStatus.valid
            }
          };
        }
      });
      return {
        ...state,
        ...newSectionStatuses
      };
    }
  }
});

export const { actions, reducer } = slice;
