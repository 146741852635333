import { CaseReducer, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { order } from 'ols-order-webservice-typescript-client';
import { AxiosError } from 'axios';
import { apiConfigProvider } from '@/services/http/apiConfig';
import { RootState } from '@/store';
import { ServiceState } from '@/typings/ServiceState';
import { ErrorInfo } from '@/typings/ErrorInfo';
import { handleAxiosError } from '@/services/handleAxiosError';
import { NetworkType } from '@/typings/NetworkType';
import { PlanServiceAvailables } from '@/services/planServiceAvailablesApi';

const initialState: ServiceState<order.PlanServiceAvailableGetResponseV1> = {
  loaded: false,
  failed: false,
  response: null,
  error: null
};

/**
 * BFF-CHP-007: 料金プランサービス適用可否判定API
 */
export const getPlanServiceAvailables = createAsyncThunk<
  // Api正常時レスポンスの型
  order.PlanServiceAvailableGetResponseV1,
  // Apiリクエストパラメータの型
  {
    auContractId: string;
    dataPlanCode: string;
    callOptionCode: string;
    networkType: NetworkType;
  },
  // Api異常時レスポンスの型
  { rejectValue: ErrorInfo }
>(
  'planServiceAvailables/getPlanServiceAvailablesV1',
  async ({ auContractId, dataPlanCode, callOptionCode, networkType }, { rejectWithValue }) => {
    const config = apiConfigProvider();
    try {
      return await PlanServiceAvailables.getInstance(config).getPlanServiceAvailables(
        auContractId,
        dataPlanCode,
        callOptionCode,
        networkType
      );
    } catch (err) {
      const errInfo: AxiosError<order.ApiErrors> = err;
      return rejectWithValue(handleAxiosError(errInfo, 'BFF-CHP-007'));
    }
  }
);

export const getPlanServiceAvailableSlice = createSlice<
  ServiceState<order.PlanServiceAvailableGetResponseV1>,
  Record<string, CaseReducer>
>({
  name: 'planServiceAvailables',
  initialState,
  reducers: {
    resetGetPlanServiceAvailableState: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPlanServiceAvailables.pending, () => initialState)
      .addCase(getPlanServiceAvailables.rejected, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: true,
          response: null,
          error: action.payload || null
        }
      }))
      .addCase(getPlanServiceAvailables.fulfilled, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: false,
          response: action.payload,
          error: null
        }
      }));
  }
});

export const getPlanServiceAvailableV1Result = (state: RootState): RootState['entity']['planServiceAvailablesApi'] =>
  state.entity.planServiceAvailablesApi;

export const { actions, reducer } = getPlanServiceAvailableSlice;
