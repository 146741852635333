import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CouponNoSpecifyType } from '@/typings/CouponNoSpecifyType';

/**
 * 見積画面（ポイント・クーポン）セクションステート
 */
export type SectionPointCouponUseState = {
  /**
   * クーポン番号指定方法
   */
  couponNoSpecifyType: undefined | CouponNoSpecifyType;
  /**
   * セクションデータチェック処理実施要否
   */
  isSectionDataCheckRequired: boolean;
};

const initialState: SectionPointCouponUseState = {
  couponNoSpecifyType: undefined,
  isSectionDataCheckRequired: false
};

const slice = createSlice({
  name: 'sectionPointCouponUse',
  initialState,
  reducers: {
    setSectionPointCouponUse: (
      state: SectionPointCouponUseState,
      action: PayloadAction<Partial<SectionPointCouponUseState>>
    ) => ({
      ...state,
      ...action.payload
    })
  }
});

export const { actions, reducer } = slice;
