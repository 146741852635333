import { order } from 'ols-order-webservice-typescript-client';
import type { RootState } from '@/store';
import { ServiceState } from '@/typings/ServiceState';

export const getCouponListResult = (couponNo: string) => (
  state: RootState
): ServiceState<order.CandidateCouponsGetResponseV1> => state.entity.couponService.getCouponList[couponNo];

export const getCouponListAllResult = (state: RootState): RootState['entity']['couponService'] =>
  state.entity.couponService;
