import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { ulid } from 'ulid';
import * as Cookies from 'js-cookie';
import { REACT_APP_ENV, REACT_APP_BRAND_ID, REACT_APP_REVISION } from '@/config/env';
import { requestParameterSelectors } from '@/store/flow/requestParameter';
import type { AppDispatch, RootState } from '@/store';
import { CookieNames, HeaderNames } from '@/config/constant';
import { networkActions } from '@/store/network';

export type RequestInterceptor = (request: AxiosRequestConfig) => AxiosRequestConfig;

/**
 * 複数のインターセプターを合成する関数。
 *
 * `andThen` のイメージで配列インデックスの昇順に適用されるインターセプターに合成します。
 *
 * @param interceptors - 合成するインターセプターの配列。
 * @returns 合成されたリクエストインターセプター
 */
export const chain: (interceptors: RequestInterceptor[]) => RequestInterceptor = (interceptors) =>
  interceptors.reduce(
    (a, b) => (req) => b(a(req)),
    (req) => req
  );

export type ApiRequestHeaderInjectorParams = {
  /**
   * トレースID生成関数
   */
  traceIdGenerator: () => string;

  /**
   * ビューID生成関数
   */
  viewIdGenerator: () => string;

  /**
   * X-XSRF-TOKEN 生成関数
   */
  xsrfTokenGenerator: () => string | undefined;
};

/**
 * APIリクエストに必要なリクエストヘッダーを設定するインターセプターを生成するファクトリー
 * @param params - トレースID生成関数
 * @returns Httpリクエストインターセプター
 */
export const apiRequestHeaderInjector: (params: ApiRequestHeaderInjectorParams) => RequestInterceptor = (params) => (
  request
) => {
  const additionalHeaders: Record<string, string> = {};
  additionalHeaders[HeaderNames.VIEW_ID_HEADER_KEY] = params.viewIdGenerator();
  additionalHeaders[HeaderNames.TRACE_ID_HEADER_KEY] = params.traceIdGenerator();
  additionalHeaders[HeaderNames.BRAND_HEADER_KEY] = REACT_APP_BRAND_ID;
  additionalHeaders[HeaderNames.REVISION_HEADER_KEY] = REACT_APP_REVISION || 'n/a';
  additionalHeaders[HeaderNames.TAB_ID_HEADER_KEY] = window.tabId;
  const xsrfToken = params.xsrfTokenGenerator();

  if (xsrfToken) {
    additionalHeaders[HeaderNames.XSRF_TOKEN_HEADER_KEY] = xsrfToken;
  }

  request.headers = {
    ...request.headers,
    ...additionalHeaders
  };

  return request;
};

export const testRequestHeaderInjector: (stateProvider: () => RootState) => RequestInterceptor = (stateProvider) => (
  request
) => {
  // Stateからパラメータを取得
  const { goodsStkNo, telNum } = requestParameterSelectors.get(stateProvider());

  if (goodsStkNo) {
    request.headers[HeaderNames.PRODUCT_STOCK_CODE_HEADER_KEY] = goodsStkNo;
  }

  if (telNum) {
    request.headers[HeaderNames.TEL_HEADER_KEY] = telNum;
  }

  return request;
};

/**
 * ulid を利用したTraceIdGenerator実装
 * @returns traceId
 */
export const ulidTraceIdGenerator: () => string = () => ulid();

/**
 * `XSRF-TOKEN` クッキーの値に基づいてXsrfTokenを生成する XsrfTokenGenerator実装
 * @returns xsrfToken - `XSRF-TOKEN` クッキーが存在しない場合は `undefined` を返却します。
 */
export const cookieXsrfTokenGenerator: () => string | undefined = () => Cookies.get(CookieNames.XSRF_TOKEN);

/**
 * window.location の値に基づいてViewIdを生成する ViewIdGenerator実装
 * @returns viewId - `location.pathname` と `location.search` を結合した文字列を ViewIdとして返却
 */
export const locationBasedViewIdGenerator: () => string = () => window.location.pathname + window.location.search;

export const start: (dispatch: AppDispatch) => RequestInterceptor = (dispatch) => (request) => {
  dispatch(networkActions.start());

  return request;
};

export const finish: (dispatch: AppDispatch) => () => void = (dispatch) => () => {
  dispatch(networkActions.finish());
};

let axiosInterceptor: number | null = null;

/**
 * [Axiosのインターセプター](https://axios-http.com/docs/interceptors/)を設定します。
 * @param stateProvider - RootState を提供する関数
 * @param dispatch - AppDispatch
 */
export const setupAxiosInterceptors: (stateProvider: () => RootState, dispatch: AppDispatch) => void = (
  stateProvider,
  dispatch
) => {
  if (!axiosInterceptor && axiosInterceptor === 0) {
    axios.interceptors.request.eject(axiosInterceptor);
  }

  const headerInjector = apiRequestHeaderInjector({
    traceIdGenerator: ulidTraceIdGenerator,
    viewIdGenerator: locationBasedViewIdGenerator,
    xsrfTokenGenerator: cookieXsrfTokenGenerator
  });

  const requestInterceptors: RequestInterceptor[] = [
    headerInjector,
    ...(REACT_APP_ENV !== 'prod' ? [testRequestHeaderInjector(stateProvider)] : []),
    start(dispatch)
  ];

  axiosInterceptor = axios.interceptors.request.use(chain(requestInterceptors));

  const onFinish = finish(dispatch);

  axios.interceptors.response.use(
    (v) => {
      onFinish();
      return v;
    },
    (e: AxiosError<unknown>) => {
      let dispatchError = false;

      if (!e.response) {
        if (REACT_APP_ENV !== 'prod') {
          // eslint-disable-next-line no-console
          console.warn('no response');
        }
        dispatchError = true;
      }

      if (e.code === 'ERR_CONNECTION_REFUSED' || e.code === 'ECONNABORTED') {
        if (REACT_APP_ENV !== 'prod') {
          // eslint-disable-next-line no-console
          console.warn('Illegal XHR state code.', e.code, e.response);
        }
        dispatchError = true;
      }

      if (dispatchError) {
        dispatch(networkActions.errorOccurred(e.code || 'UNKNOWN'));
      }

      onFinish();
      return Promise.reject(e);
    }
  );
};
