import { order } from 'ols-order-webservice-typescript-client';
import type { RootState } from '@/store';
import { viewCommonSelectors } from '@/store/flow/viewCommon';
import { orderSelectors } from '@/store/order';
import { AuIdTelNumUmuKbnTypes } from '@/typings/AuIdTelNumUmuKbnType';
import { UqUserStatusTypes } from '@/typings/UqStatus';
import { PrivateCorporateTypes } from '@/typings/PrivateCorporateType';
import { ServiceStatuses } from '@/typings/ServiceStatus';
import { getSystemYmd } from '@/store/api/system/selectors';
import { get } from '@/store/flow/system/selectors';
import { calcAge } from '@/utils/calcAge';

/**
 * BFF-USR-001: アカウント取得API
 * @param state - ステート
 * @returns
 */
export const getAccountsResult = (state: RootState): RootState['entity']['userService']['getAccount'] =>
  state.entity.userService.getAccount;

/**
 * BFF-USR-002: ポイント取得API
 * @param state - ステート
 * @returns
 */
export const getPointsResult = (state: RootState): RootState['entity']['userService']['getPoint'] =>
  state.entity.userService.getPoint;

/**
 * BFF-USR-003: 契約取得API
 * @param state - ステート
 * @returns
 */
export const getContractsResult = (state: RootState): RootState['entity']['userService']['getContract'] =>
  state.entity.userService.getContract;

/**
 * BFF-USR-004: アカウント追加情報取得API
 * @param state - ステート
 * @returns
 */
export const getAccountComplementsResult = (
  state: RootState
): RootState['entity']['userService']['getAccountComplements'] => state.entity.userService.getAccountComplements;

/**
 * BFF-USR-005: auかんたん決済残高情報取得API
 * @param state - ステート
 * @returns
 */
export const getAuEasyPaymentResult = (state: RootState): RootState['entity']['userService']['getAuEasyPayment'] =>
  state.entity.userService.getAuEasyPayment;

/**
 * 回線ありオンラインショップ加入者情報返却
 * @param state - ステート
 * @returns
 */
export const getSelectOnlineShopSubscriberList = (
  state: RootState
): order.OnlineShopSubscriberGetResponseV1 | undefined => {
  const telephoneNumber = state.order.login?.telephoneNumber;
  return state.entity.userService.getAccount.response?.user?.onlineShopSubscriberList.find(
    (element) => element.auPhoneNo === telephoneNumber
  );
};

/**
 * 旧UQ判定
 * @param state - ステート
 * @returns
 */
export const isOldUqUser = (state: RootState): boolean => {
  const onlineShopSubscriberList = getSelectOnlineShopSubscriberList(state);
  return onlineShopSubscriberList?.uqUserStatus === UqUserStatusTypes.oldUq;
};
/**
 * OCTユーザ判定区分返却
 * @param state - ステート
 * @returns
 */
export const getOctUserType = (state: RootState): string | undefined =>
  getSelectOnlineShopSubscriberList(state)?.octUserType;

/**
 * MyAuクーポンリスト取得
 * @param state - ステート
 * @returns
 */
export const getMyAuCouponList = (state: RootState): order.MyAuCouponGetResponseV1[] | undefined =>
  state.entity.userService.getAccountComplements.response?.myAuCouponList;

/**
 * ポイント情報取得
 * @param state - ステート
 * @returns
 */
export const getPointInfo = (
  state: RootState
): { pointAvailability: boolean; useLimitedPoint: number; availablePoint: number } => {
  const auIdTelNumUmuKbn = viewCommonSelectors.getAuIdTelNumUmuKbn(state);
  const isContractTypeNewOrMnp = orderSelectors.isContractTypeNewOrMnp(state);
  const isContractTypeChange = orderSelectors.isContractTypeChange(state);
  const isContractTypeNewOpen = orderSelectors.isContractTypeNewOpen(state);
  const isNumberMigration = orderSelectors.isNumberMigration(state);
  const isContractTypeAccessoryDevice = orderSelectors.isContractTypeAccessoryDevice(state);

  // 回線有無の状態に応じて、ポイントの取得元を制御する。
  // 【回線あり】BFF-USR-002: ポイント取得API
  //   ・機種変更
  //   ・新規、MNPのau IDログイン済み（回線あり）
  // 【回線なし】BFF-USR-004: アカウント追加情報取得API
  //   ・新規（オープン）、番号移行
  //   ・新規、MNPのau IDログイン済み（回線なし）
  // ※上記以外の場合は、ポイントを表示しない。
  const pointInfo = {
    pointAvailability: false,
    useLimitedPoint: 0,
    availablePoint: 0
  };
  if (isContractTypeChange || (isContractTypeNewOrMnp && auIdTelNumUmuKbn === AuIdTelNumUmuKbnTypes.AuIdYes)) {
    const pointApiInfo = getPointsResult(state).response?.point;
    pointInfo.pointAvailability = pointApiInfo?.pointAvailability || false;
    pointInfo.useLimitedPoint = pointApiInfo?.useLimitedPoint || 0;
    pointInfo.availablePoint = pointApiInfo?.availablePoint || 0;
  } else if (
    isContractTypeNewOpen ||
    isContractTypeAccessoryDevice ||
    isNumberMigration ||
    (isContractTypeNewOrMnp && auIdTelNumUmuKbn === AuIdTelNumUmuKbnTypes.AuIdOpen)
  ) {
    const pointCouponInfo = getAccountComplementsResult(state).response?.point;
    pointInfo.pointAvailability = pointCouponInfo?.pointAvailability || false;
    pointInfo.useLimitedPoint = pointCouponInfo?.useLimitedPoint || 0;
    pointInfo.availablePoint = pointCouponInfo?.availablePoint || 0;
  }
  return pointInfo;
};

/**
 * 回線なし、またはpovo1.0かつ個人のオンラインショップ加入者情報返却(povo1.0加入者)
 * @param state - ステート
 * @returns
 */
export const getOnlineShopSubscriberWithPovoV1 = (
  state: RootState
): Array<order.OnlineShopSubscriberGetResponseV1> | undefined =>
  state.entity.userService.getAccount.response?.user?.onlineShopSubscriberList.filter(
    (element) =>
      (element.povoPlan || !element.auPhoneNo || element.serviceStatus !== ServiceStatuses.Servicing) &&
      element.privateCorporate === PrivateCorporateTypes.PRIVATE
  );

/**
 * BFF-USR-003: 契約取得APIよりACROSSにてAppleCareが加入中かを判定します。
 * @param state - ステート
 * @returns
 */
export const applyAppleCare = (state: RootState): boolean => {
  const latestAppleCareContractInfo =
    state.entity.userService.getContract?.response?.auDeviceSupportService?.latestAppleCareContractInfo;
  return (
    latestAppleCareContractInfo?.appleCareApply === order.AppleCareApplyBffEnum.APPLY &&
    [order.AppleCareContractStatusBffEnum.ACCEPTED, order.AppleCareContractStatusBffEnum.IN_SERVICE].includes(
      latestAppleCareContractInfo?.appleCareContractStatus
    )
  );
};

/**
 * システム日付と未成年年齢定義から未成年かどうかのチェック
 * @param state - ステート
 * @returns
 */
export const isMinorAge = (state: RootState): boolean => {
  const isNumberMigration = orderSelectors.isNumberMigration(state);
  const isChangeContract = orderSelectors.isContractTypeChange(state);
  const isNewMnpContract = orderSelectors.isContractTypeNewOrMnp(state);
  const accountInfo = getAccountsResult(state).response;
  const selectTelephoneNumber = orderSelectors.getLogin(state)?.telephoneNumber;
  const systemDate = getSystemYmd(state);
  const { minorAge } = get(state);
  const lineContractInfo = orderSelectors.getLineContractInfo(state);
  const usePersonBirthday = lineContractInfo
    ? lineContractInfo.usePersonBirthday
    : (accountInfo?.user?.birthday as string);

  let ageFlag = false;
  if ((isChangeContract || isNumberMigration) && accountInfo) {
    const auUqUserBirthday = accountInfo.user?.onlineShopSubscriberList.find(
      (element) => element.auPhoneNo === selectTelephoneNumber
    )?.userBirthDate;
    const userBirthday = auUqUserBirthday ?? accountInfo?.uqUser?.user?.userBirthday ?? '0';
    const age = calcAge(systemDate, userBirthday);
    if (age < minorAge) {
      ageFlag = true;
    }
  }
  if (isNewMnpContract && usePersonBirthday) {
    const age = calcAge(systemDate, usePersonBirthday);
    if (age < minorAge) {
      ageFlag = true;
    }
  }

  return ageFlag;
};
