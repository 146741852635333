import { createSlice } from '@reduxjs/toolkit';
import { ErrorInfo } from '@/typings/ErrorInfo';
import { getIncludes } from './thunk';

/**
 * 各インクルードのステート
 */
export type IncludeState = {
  /**
   * 読込済みフラグ
   */
  loaded: boolean;

  /**
   * 読込中フラグ
   */
  loading: boolean;

  /**
   * 読込結果
   */
  failed: boolean;

  /**
   * 正常時レスポンス（コンテンツ）
   */
  content?: string;

  /**
   * 異常時レスポンス
   */
  error?: ErrorInfo;
};

/**
 * ステート
 */
export type State = {
  [id: string]: IncludeState;
};

/**
 * 初期ステート
 */
const initialState: State = {};

/**
 * インクルードスライス
 */
const includeSlice = createSlice({
  name: 'include',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // 読込中
    builder.addCase(getIncludes.pending, (state, action) =>
      action.meta.arg.includeIDs.reduce(
        (acc, includeId) => ({
          ...acc,
          [includeId]: {
            loaded: false,
            loading: true,
            failed: false
          }
        }),
        state
      )
    );

    // 読込完了
    builder.addCase(getIncludes.fulfilled, (state, action) =>
      action.payload.reduce(
        (acc, includeData, idx) => ({
          ...acc,
          [action.meta.arg.includeIDs[idx]]: {
            loaded: true,
            loading: false,
            failed: false,
            content: includeData.content
          }
        }),
        state
      )
    );

    // 読込エラー
    builder.addCase(getIncludes.rejected, (state, action) =>
      action.meta.arg.includeIDs.reduce(
        (acc, includeId) => ({
          ...acc,
          [includeId]: {
            loaded: true,
            loading: false,
            failed: true,
            error: action.payload
          }
        }),
        state
      )
    );
  }
});

export const { actions, reducer } = includeSlice;
