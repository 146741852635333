import { order } from 'ols-order-webservice-typescript-client';
import { IdentificationType } from '@/typings/IdentificationType';
import { AppDispatch } from '@/store';
import { IdentificationTargetType } from '@/typings/IdentificationTargetType';
import { AuxiliaryDocumentType } from '@/typings/AuxiliaryDocumentType';
import {
  createIdentification as createIdentificationAction,
  uploadIdentificationImages as uploadIdentificationImagesAction,
  getIdentificationImage as getIdentificationImageAction,
  resetUploadIdentificationImages,
  createOneTimePassword as createOneTimePasswordAction,
  validOneTimePassword as validOneTimePasswordAction,
  resetValidOneTimePasswords
} from './actions';

/**
 * BFF-IDE-001: 本人確認画像登録API
 * @returns
 */
export const uploadIdentificationImage = (
  identificationTargetType: IdentificationTargetType,
  imageNo: string,
  imageFile: unknown,
  imageFileName: string,
  identificationType: IdentificationType,
  auxiliaryDocumentType: AuxiliaryDocumentType | undefined
) => (dispatch: AppDispatch): ReturnType<ReturnType<typeof uploadIdentificationImagesAction>> =>
  dispatch(
    uploadIdentificationImagesAction({
      identificationTargetType,
      imageNo,
      imageFile,
      imageFileName,
      identificationType,
      auxiliaryDocumentType
    })
  );

/**
 * BFF-IDE-001: 本人確認画像登録API 初期化
 * @returns
 */
export const resetUploadIdentificationImage = () => (
  dispatch: AppDispatch
): ReturnType<typeof resetUploadIdentificationImages> => dispatch(resetUploadIdentificationImages());

/**
 * BFF-IDE-002: 本人確認取得登録API
 * @returns
 */
export const getIdentificationImage = (
  identificationTargetType: IdentificationTargetType,
  imageNo: string,
  identificationType: IdentificationType,
  auxiliaryDocumentType: AuxiliaryDocumentType
) => (dispatch: AppDispatch): ReturnType<ReturnType<typeof getIdentificationImageAction>> =>
  dispatch(
    getIdentificationImageAction({
      identificationTargetType,
      imageNo,
      identificationType,
      auxiliaryDocumentType
    })
  );

/**
 * BFF-IDE-003: eKYCを利用した本人確認手続き申請API
 * @param orderNo - 注文番号
 * @returns
 */
export const createIdentification = (identificationEKycPostRequestV1?: order.IdentificationEKycPostRequestV1) => (
  dispatch: AppDispatch
): ReturnType<ReturnType<typeof createIdentificationAction>> =>
  dispatch(createIdentificationAction({ identificationEKycPostRequestV1 }));

/**
 * BFF-IDE-004: OTP発行API
 * @returns
 */
export const createOneTimePassword = () => (
  dispatch: AppDispatch
): ReturnType<ReturnType<typeof createOneTimePasswordAction>> => dispatch(createOneTimePasswordAction());

/**
 * BFF-IDE-005: OTPチェックAPI
 * @returns
 */
export const validOneTimePassword = (oneTimePassword: string) => (
  dispatch: AppDispatch
): ReturnType<ReturnType<typeof validOneTimePasswordAction>> =>
  dispatch(validOneTimePasswordAction({ oneTimePassword }));

/**
 * BFF-IDE-005: OTPチェックAPI 初期化
 * @returns
 */
export const resetValidOneTimePassword = () => (dispatch: AppDispatch): ReturnType<typeof resetValidOneTimePasswords> =>
  dispatch(resetValidOneTimePasswords());
