import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { order } from 'ols-order-webservice-typescript-client';
import { SystemApiService } from '@/services/systemApiService';
import { apiConfigProvider } from '@/services/http/apiConfig';
import { ErrorInfo } from '@/typings/ErrorInfo';
import { handleAxiosError } from '@/services/handleAxiosError';

/**
 * BFF-SYS-002: ログ登録API
 */
export const createLog = createAsyncThunk<
  // Api正常時レスポンスの型
  void,
  // Apiリクエストパラメータの型
  { logPostRequestV1: order.LogPostRequestV1 },
  // Api異常時レスポンスの型
  { rejectValue: ErrorInfo }
>('systemService/createLog', async ({ logPostRequestV1 }, { rejectWithValue }) => {
  const config = apiConfigProvider();
  try {
    return await SystemApiService.getInstance(config).createLog(logPostRequestV1);
  } catch (err) {
    const errInfo: AxiosError<order.ApiErrors> = err;
    return rejectWithValue(handleAxiosError(errInfo, 'BFF-SYS-002'));
  }
});

/**
 * BFF-SYS-003: マスク対象取得API
 */
export const getUnavailableServiceStatuses = createAsyncThunk<
  // Api正常時レスポンスの型
  order.UnavailableServiceStatusGroupV1[],
  // Apiリクエストパラメータの型
  void,
  // Api異常時レスポンスの型
  { rejectValue: ErrorInfo }
>('systemService/getUnavailableServiceStatuses', async (_, { rejectWithValue }) => {
  const config = apiConfigProvider();
  try {
    return await SystemApiService.getInstance(config).getUnavailableServiceStatuses();
  } catch (err) {
    const errInfo: AxiosError<order.ApiErrors> = err;
    return rejectWithValue(handleAxiosError(errInfo, 'BFF-SYS-003'));
  }
});

/**
 * BFF-SYS-004: 見積もり選択可能オプション取得API
 */
export const getAvailableEstimateOptions = createAsyncThunk<
  // Api正常時レスポンスの型
  order.AvailableEstimateOptionsGetResponseV1,
  // Apiリクエストパラメータの型
  {
    auSmartpassState: ' ' | '1' | '2' | 'E';
    applemusicState: ' ' | 'Y' | 'E';
    youtubePremiumState: ' ' | 'Y' | 'E';
    auBicycleSupportState: ' ' | 'Y' | 'E';
    auWellnessState: ' ' | 'Y' | 'E';
    allstarPack1Available: '0' | '1';
  },
  // Api異常時レスポンスの型
  { rejectValue: ErrorInfo }
>(
  'systemService/getAvailableEstimateOptions',
  async (
    {
      auSmartpassState,
      applemusicState,
      youtubePremiumState,
      auBicycleSupportState,
      auWellnessState,
      allstarPack1Available
    },
    { rejectWithValue }
  ) => {
    const config = apiConfigProvider();
    try {
      return await SystemApiService.getInstance(config).getAvailableEstimateOptions(
        auSmartpassState,
        applemusicState,
        youtubePremiumState,
        auBicycleSupportState,
        auWellnessState,
        allstarPack1Available
      );
    } catch (err) {
      const errInfo: AxiosError<order.ApiErrors> = err;
      return rejectWithValue(handleAxiosError(errInfo, 'BFF-SYS-004'));
    }
  }
);

/**
 * BFF-SYS-005: システム設定情報取得API
 */
export const getSystemSettings = createAsyncThunk<
  // Api正常時レスポンスの型
  order.SystemSettingsGetResponseV1,
  // Apiリクエストパラメータの型
  void,
  // Api異常時レスポンスの型
  { rejectValue: ErrorInfo }
>('systemService/getSystemSettings', async (_, { rejectWithValue }) => {
  const config = apiConfigProvider();
  try {
    return await SystemApiService.getInstance(config).getSystemSettings();
  } catch (err) {
    const errInfo: AxiosError<order.ApiErrors> = err;
    return rejectWithValue(handleAxiosError(errInfo, 'BFF-SYS-005'));
  }
});

/**
 * BFF-SYS-006: MNP予約番号有効性確認API
 */
export const getMnpEffectiveness = createAsyncThunk<
  // Api正常時レスポンスの型
  order.MnpEffectivenessConformationGetResponseV1,
  // Apiリクエストパラメータの型
  {
    mnpReservationNo: string;
    mnpLimitDate: string;
  },
  // Api異常時レスポンスの型
  { rejectValue: ErrorInfo }
>('systemService/getMnpEffectiveness', async ({ mnpReservationNo, mnpLimitDate }, { rejectWithValue }) => {
  const config = apiConfigProvider();
  try {
    return await SystemApiService.getInstance(config).getMnpEffectiveness(mnpReservationNo, mnpLimitDate);
  } catch (err) {
    const errInfo: AxiosError<order.ApiErrors> = err;
    return rejectWithValue(handleAxiosError(errInfo, 'BFF-SYS-006'));
  }
});

/**
 * BFF-SYS-007: 利用不可文字確認API
 */
export const getUnavailableCharacters = createAsyncThunk<
  // Api正常時レスポンスの型
  order.UnavailableCharactersPostResponseV1,
  // Apiリクエストパラメータの型
  {
    unavailableCharactersPostRequestV1: order.UnavailableCharactersPostRequestV1;
  },
  // Api異常時レスポンスの型
  { rejectValue: ErrorInfo }
>('systemService/getUnavailableCharacters', async ({ unavailableCharactersPostRequestV1 }, { rejectWithValue }) => {
  const config = apiConfigProvider();
  try {
    return await SystemApiService.getInstance(config).validUnavailableCharacters(unavailableCharactersPostRequestV1);
  } catch (err) {
    const errInfo: AxiosError<order.ApiErrors> = err;
    return rejectWithValue(handleAxiosError(errInfo, 'BFF-SYS-007'));
  }
});
