import { useEffect } from 'react';

export const useDetectTabId = (): null => {
  useEffect(() => {
    const handleBeforeUnload = () => {
      if (window.tabId) {
        sessionStorage.setItem('tabId', window.tabId);
      }
      return null;
    };

    const handleLoad = () => {
      const tabId = sessionStorage.getItem('tabId');
      if (tabId) {
        window.tabId = tabId;
        sessionStorage.removeItem('tabId');
        return null;
      }
      window.tabId = Math.floor(Math.random() * 1000000);
      return null;
    };

    handleLoad();
    window.addEventListener('pagehide', handleBeforeUnload);
  }, []);

  return null;
};
