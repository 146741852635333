import { order } from 'ols-order-webservice-typescript-client';
import { ActionCreatorWithoutPayload } from '@reduxjs/toolkit';
import { AppDispatch } from '@/store';
import {
  getEstimatingOrder as getEstimatingOrderAction,
  createEstimatingOrder as createEstimatingOrderAction,
  getPendingReservationsInfo as getPendingReservationsInfoAction,
  getEstimateSaveMailByEstimateId as getEstimateSaveMailByEstimateIdAction,
  createEstimateSaveMailByEstimateId as createEstimateSaveMailByEstimateIdAction,
  postEstimatedAmounts as postEstimatedAmountsAction,
  getEstimateSessionClear as getEstimateSessionClearAction,
  resetCreateEstimatingOrder as resetCreateEstimatingOrderAction,
  resetPendingReservationsInfo as resetPendingReservationsInfoAction,
  resetEstimateSaveMailByEstimateId,
  resetPostEstimatedAmounts as resetPostEstimatedAmountsAction,
  resetEstimateSimulationResult,
  getSimulationInfo,
  resetPostEstimatedTemporaryAmounts,
  postEstimatedTemporaryAmounts
} from './actions';

const resetEstimatingOrder = resetCreateEstimatingOrderAction as ActionCreatorWithoutPayload<string>;
const resetPendingReservations = resetPendingReservationsInfoAction as ActionCreatorWithoutPayload<string>;
const resetEstimateSaveMail = resetEstimateSaveMailByEstimateId as ActionCreatorWithoutPayload<string>;
const resetEstimatedAmounts = resetPostEstimatedAmountsAction as ActionCreatorWithoutPayload<string>;

/**
 * BFF-EST-001: 注文見積もり情報取得API
 * @param void -引数なし
 * @returns
 */
export const getEstimatingOrder = () => (
  dispatch: AppDispatch
): ReturnType<ReturnType<typeof getEstimatingOrderAction>> => dispatch(getEstimatingOrderAction());

/**
 * BFF-EST-002: 注文見積もり情報登録API
 * @param estimatingOrderPostRequestV1 -注文見積もり情報のリクエストモデル
 * @returns
 */
export const createEstimatingOrder = (estimatingOrderPostRequestV1?: order.EstimatingOrderPostRequestV1) => (
  dispatch: AppDispatch
): ReturnType<ReturnType<typeof createEstimatingOrderAction>> =>
  dispatch(createEstimatingOrderAction({ estimatingOrderPostRequestV1 }));

/**
 * BFF-EST-002: 注文見積もり情報登録API 初期化
 * @returns
 */
export const resetCreateEstimatingOrder = () => (dispatch: AppDispatch): ReturnType<typeof resetEstimatingOrder> =>
  dispatch(resetEstimatingOrder());

/**
 * BFF-EST-003: 本申込情報取得API
 * @param orderNo - 注文番号
 * @returns
 */
export const getPendingReservationsInfo = (orderNo: string) => (
  dispatch: AppDispatch
): ReturnType<ReturnType<typeof getPendingReservationsInfoAction>> =>
  dispatch(getPendingReservationsInfoAction({ orderNo }));

/**
 * BFF-EST-003: 本申込情報取得API 初期化
 * @returns
 */
export const resetPendingReservationsInfo = () => (
  dispatch: AppDispatch
): ReturnType<typeof resetPendingReservationsInfoAction> => dispatch(resetPendingReservations());

/**
 * BFF-EST-004: 見積保存メール情報取得API
 * @param hashId - ハッシュ値
 * @returns
 */
export const getEstimateSaveMailByEstimateId = (hashId: string) => (
  dispatch: AppDispatch
): ReturnType<ReturnType<typeof getEstimateSaveMailByEstimateIdAction>> =>
  dispatch(getEstimateSaveMailByEstimateIdAction({ hashId }));

/**
 * BFF-EST-005: 見積保存メール情報登録API
 * @param estimateSaveMailByEstimateId - 見積保存メール情報登録のリクエストモデル
 * @returns
 */
export const createEstimateSaveMailByEstimateId = (
  estimateSaveMailByEstimateId?: order.EstimateSaveMailByEstimateId
) => (dispatch: AppDispatch): ReturnType<ReturnType<typeof createEstimateSaveMailByEstimateIdAction>> =>
  dispatch(createEstimateSaveMailByEstimateIdAction({ estimateSaveMailByEstimateId }));

/**
 * BFF-EST-005: 見積保存メール情報登録API初期化
 * @returns
 */
export const resetCreateEstimateSaveMail = () => (dispatch: AppDispatch): ReturnType<typeof resetEstimateSaveMail> =>
  dispatch(resetEstimateSaveMail());

/**
 * BFF-EST-006: 料金費用内訳計算API
 * @param estimateAmountsPostRequestV1 - 見積保存メール情報登録のリクエストモデル
 * @returns
 */
export const postEstimatedAmounts = (estimateAmountsPostRequestV1?: order.EstimateAmountsPostRequestV1) => (
  dispatch: AppDispatch
): ReturnType<ReturnType<typeof postEstimatedAmountsAction>> =>
  dispatch(postEstimatedAmountsAction({ estimateAmountsPostRequestV1 }));

/**
 * BFF-EST-006: 料金費用内訳計算API 初期化
 * @returns
 */
export const resetPostEstimatedAmounts = () => (dispatch: AppDispatch): ReturnType<typeof resetEstimatedAmounts> =>
  dispatch(resetEstimatedAmounts());

/**
 * BFF-EST-007: 割引施策セッション初期化API
 * @param void -引数なし
 * @returns
 */
export const getEstimateSessionClear = () => (
  dispatch: AppDispatch
): ReturnType<ReturnType<typeof getEstimateSessionClearAction>> => dispatch(getEstimateSessionClearAction());

/**
 * BFF-EST-009: シミュレーション情報取得API
 * @param offerId - オファーID
 * @returns
 */
export const getEstimateSimulationInfo = (offerId: string, ottId: string | undefined) => (
  dispatch: AppDispatch
): ReturnType<ReturnType<typeof getSimulationInfo>> => dispatch(getSimulationInfo({ offerId, ottId }));

/**
 * BFF-EST-009: シミュレーション情報取得API 初期化
 * @returns
 */
export const resetSimulationInfo = () => (dispatch: AppDispatch): ReturnType<typeof resetEstimateSimulationResult> =>
  dispatch(resetEstimateSimulationResult());

/**
 * BFF-EST-010: 暫定料金費用内訳計算API
 * @param estimateAmountsPostRequestV1 - 見積保存メール情報登録のリクエストモデル
 * @returns
 */
export const postEstimatedTemporaryAmount = (
  estimateAmountsPostRequestV1?: order.EstimateTemporaryAmountsPostRequestV1
) => (dispatch: AppDispatch): ReturnType<ReturnType<typeof postEstimatedAmountsAction>> =>
  dispatch(postEstimatedTemporaryAmounts({ estimateAmountsPostRequestV1 }));

/**
 * BFF-EST-010: 暫定料金費用内訳計算API
 * @returns
 */
export const resetPostEstimatedTemporaryAmount = () => (
  dispatch: AppDispatch
): ReturnType<typeof resetEstimatedAmounts> => dispatch(resetPostEstimatedTemporaryAmounts());
