import { order } from 'ols-order-webservice-typescript-client';
import { consoleLog } from '@/utils/functions';

const { AgencyApi } = order;

export class AgencyApiService {
  private static instance: AgencyApiService;

  private api: order.AgencyApi;

  private constructor(config: order.Configuration) {
    this.api = new AgencyApi(config);
  }

  public static getInstance(config: order.Configuration): AgencyApiService {
    if (!this.instance) {
      this.instance = new AgencyApiService(config);
    }

    return this.instance;
  }

  /**
   * BFF-AGC-001: エリア情報取得API
   * @returns
   */
  getAreas(): Promise<order.AreasGetResponseV1> {
    consoleLog('BFF-AGC-001: エリア情報取得API call');
    return this.api.getAreasV1().then((res) => res.data);
  }

  /**
   * BFF-AGC-002: 都道府県情報取得API
   * @param areaCode - エリアコード
   * @returns
   */
  getPrefectures(areaCode: string): Promise<order.PrefecturesGetResponseV1> {
    consoleLog('BFF-AGC-002: 都道府県情報取得API call', areaCode);
    return this.api
      .getPrefecturesV1(areaCode as 'D' | 'T' | 'E' | 'N' | 'H' | 'K' | 'C' | 'S' | 'Q' | 'O')
      .then((res) => res.data);
  }

  /**
   * BFF-AGC-003: 市区町村情報取得API
   * @param prefectureCode - 都道府県コード
   * @returns
   */
  getDistricts(prefectureCode: string): Promise<order.DistrictsGetResponseV1> {
    consoleLog('BFF-AGC-003: 市区町村情報取得API call', prefectureCode);
    return this.api.getDistrictsV1(prefectureCode).then((res) => res.data);
  }

  /**
   * BFF-AGC-004: 受取可能店舗情報取得API
   * @param districtCode - 市区町村（市区郡）コード
   * @returns
   */
  getInstorePickupAgencies(districtCode: string): Promise<order.InstorePickupAgenciesGetResponseV1> {
    consoleLog('BFF-AGC-004: 受取可能店舗情報取得API call', districtCode);
    return this.api.getInstorePickupAgenciesV1(districtCode).then((res) => res.data);
  }
}
