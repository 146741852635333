/**
 * 契約種別
 */
export type ContractType = '1' | '2' | '3' | '4' | '5' | '7' | '8' | '9';
export type ContractTypeKey =
  | 'New'
  | 'Mnp'
  | 'Change'
  | 'NewOpen'
  | 'NumberMigration'
  | 'ChangePlan'
  | 'WatchNumber'
  | 'AccessoryDevice';
export const ContractTypes = {
  New: '1',
  Change: '2',
  Mnp: '3',
  NewOpen: '4',
  NumberMigration: '5',
  ChangePlan: '7',
  WatchNumber: '8',
  AccessoryDevice: '9'
} as const;

export const contractTypeLabel = {
  '1': '新規ご契約',
  '2': '機種変更',
  '3': '他社から乗りかえ（MNP）',
  '4': '機種のみ購入',
  '5': 'UQ mobileから乗りかえ',
  '7': 'プラン変更',
  '8': '新規ご契約（ウォッチナンバー申込用SIM）',
  '9': 'au +1 collectionの購入'
} as const;
