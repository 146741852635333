import { actions } from './orderSlice';

export const {
  setOrderValue,
  setLogin,
  setMnpOnestopInfo,
  setAuthOpenAuIdInfo,
  setOwnedTerminal,
  setEstimate,
  setContract,
  setPayment,
  setOrderAdditionInfo,
  setContractTypeGroup,
  setBuyTerminal,
  setAccessory,
  setLineContract,
  setLineContractDiscount,
  setOption,
  setAfterCareService,
  setTerminalBuyMethod,
  setAccessoryBuyMethod,
  setDiscount,
  setContractorInfo,
  setLineContractInfo,
  setIdentification,
  setEkycRequestParams,
  setContractAdditionInfo,
  setReceivingShopInformation,
  setEnabledDeliveryDocument,
  setImportantMatter,
  setImportantMatterAgree,
  setCreditFacilityNoticeAgree,
  setInstSalelawMailSendingAgree,
  setForcedOpeningAgree,
  resetOrderWork,
  addOptionalGoodsCartInfo,
  deleteOptionalGoodsCartInfo,
  changeOptionalGoodsCartInfoPurchaseCount,
  deleteUploadFile
} = actions;
