/**
 * ご利用SIMタイプ
 */
export type UseSimType = '1' | '2' | '3' | ' ' | '*';
export const UseSimTypes = {
  SimIcCard: '1',
  ESim: '2',
  Both: '3',
  NotApplicable: ' ',
  Other: '*'
} as const;
export const UseSimTypeLabels = {
  '1': 'SimIcCard',
  '2': 'ESim',
  '3': 'Both',
  ' ': 'NotApplicable',
  '*': 'Other'
} as const;
