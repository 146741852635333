import { AppDispatch } from '@/store';
import {
  getAreas as getAreasAction,
  getPrefectures as getPrefecturesAction,
  getDistricts as getDistrictsAction,
  getInstorePickupAgencies as getInstorePickupAgenciesAction
} from './actions';

/**
 * BFF-AGC-001: エリア情報取得API
 * @returns
 */
export const getAreas = () => (dispatch: AppDispatch): ReturnType<ReturnType<typeof getAreasAction>> =>
  dispatch(getAreasAction());

/**
 * BFF-AGC-002: 都道府県情報取得API
 * @returns
 */
export const getPrefectures = (areaCode: string) => (
  dispatch: AppDispatch
): ReturnType<ReturnType<typeof getPrefecturesAction>> => dispatch(getPrefecturesAction({ areaCode }));

/**
 * BFF-AGC-003: 市区町村情報取得API
 * @param prefectureCode - 都道府県コード
 * @returns
 */
export const getDistricts = (prefectureCode: string) => (
  dispatch: AppDispatch
): ReturnType<ReturnType<typeof getDistrictsAction>> => dispatch(getDistrictsAction({ prefectureCode }));

/**
 * BFF-AGC-004: 受取可能店舗情報取得API
 * @param districtCode - 市区町村（市区郡）コード
 * @returns
 */
export const getInstorePickupAgencies = (districtCode: string) => (
  dispatch: AppDispatch
): ReturnType<ReturnType<typeof getInstorePickupAgenciesAction>> =>
  dispatch(getInstorePickupAgenciesAction({ districtCode }));
