import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { format } from 'date-fns';
/**
 * システム情報
 */
export type SystemState = {
  /**
   * システム日付
   */
  systemDate: string;
  /**
   * システム日時
   */
  systemDateTime: string;
  /**
   * 未成年者年齢
   */
  minorAge: number;
  /**
   * アクセサリーの同時購入上限数
   */
  accessorySimultaneousPurchaseMaxNumber: number;
  /**
   * アクセサリーの同時購入種類上限数
   */
  accessorySimultaneousPurchaseTypeMaxNumber: number;
  /**
   * アクセサリー割賦払い上限金額
   */
  accessoryInstallmentPaymentMaxAmount: number;
  /**
   * 決済リトライ回数
   */
  paymentRetryCount: number;
  /**
   * 3D認証エラーメッセージ
   */
  auth3dErrorMessage?: string;
};

const initialState: SystemState = {
  systemDate: format(new Date(), 'yyyyMMdd'),
  systemDateTime: format(new Date(), 'yyyyMMddHHmmss'),
  minorAge: 0,
  accessorySimultaneousPurchaseMaxNumber: 0,
  accessorySimultaneousPurchaseTypeMaxNumber: 0,
  accessoryInstallmentPaymentMaxAmount: 0,
  paymentRetryCount: 0
};

const systemSlice = createSlice({
  name: 'system',
  initialState,
  reducers: {
    setSystem: (state: SystemState, action: PayloadAction<Partial<SystemState>>) => ({
      ...state,
      ...action.payload
    }),
    resetSystem: (state: SystemState) => ({
      ...state,
      ...initialState
    }),

    //  決済リトライ回数カウントアップ
    incrementPaymentRetryCountAction: (state: SystemState) => {
      const retryCount = state.paymentRetryCount;
      return {
        ...state,
        paymentRetryCount: retryCount + 1
      };
    },

    //  決済リトライ回数リセット
    resetPaymentRetryCountAction: (state: SystemState) => ({
      ...state,
      paymentRetryCount: 0
    })
  }
});

export const { actions, reducer } = systemSlice;
