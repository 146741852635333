/**
 * Httpヘッダー名称
 *
 * アプリケーションが利用するHttpヘッダーの名称を定義します。
 */
export const HeaderNames = {
  BRAND_HEADER_KEY: 'X-Ols-Brand-Id',
  TRACE_ID_HEADER_KEY: 'X-Ols-Trace-Id',
  VIEW_ID_HEADER_KEY: 'X-Ols-View-Id',
  PRODUCT_STOCK_CODE_HEADER_KEY: 'X-Ols-Product-Stock-Code',
  TEL_HEADER_KEY: 'X-Ols-Tel',
  XSRF_TOKEN_HEADER_KEY: 'X-XSRF-TOKEN',
  REVISION_HEADER_KEY: 'X-Ols-Client-Rev',
  TAB_ID_HEADER_KEY: 'X-Ols-Tab-Id'
};

/**
 * クッキー名称
 *
 * アプリケーションが利用するクッキーの名称を定義します。
 */
export const CookieNames = {
  /**
   * CSRF防止用トークンクッキー
   */
  XSRF_TOKEN: 'XSRF-TOKEN',

  /**
   * auID認証クッキー
   */
  AU_ID_AUTH: 'CCAAUTKT',

  /**
   * AF_PAR1クッキー
   */
  AF_PAR1: 'AF_PAR1',

  /**
   * AF_PAR2クッキー
   */
  AF_PAR2: 'AF_PAR2',

  /**
   * tos_acc_shop_cdクッキー
   */
  tos_acc_shop_cd: 'tos_acc_shop_cd',

  /**
   * tos_user_idクッキー
   */
  tos_user_id: 'tos_user_id',

  /**
   * tos_shopnmクッキー
   */
  tos_shopnm: 'tos_shopnm',

  /**
   * AG_CDクッキー
   */
  AG_CD: 'AG_CD'
};
