import {
  GetCouponListParameter,
  GetAfterDayCampaignParameter,
  GetDiscountServiceParameter,
  GetAdditionalServiceParameter,
  GetMonthlyDiscountServiceParameter,
  GetFinancialDiscountParameter
} from '@/services/discountCampaignApiService';
import { AppDispatch } from '@/store';
import { AreaCode } from '@/typings/AreaCode';
import { ContractBranchCode } from '@/typings/ContractBranchCode';
import { ContractType } from '@/typings/ContractType';
import { NetworkType } from '@/typings/NetworkType';
import { PaymentTimes } from '@/typings/PaymentTimes';
import {
  getPenaltyImmediatePoints as getPenaltyImmediatePointsAction,
  getCouponList as getCouponListAction,
  resetGetCouponListState as resetGetCouponListStateAction,
  getAfterDayCampaigns as getAfterDayCampaignsAction,
  getDiscountServices as getDiscountServicesAction,
  getAdditionalServices as getAdditionalServicesAction,
  getMonthlyDiscountService as getMonthlyDiscountServiceAction,
  getFinancialDiscounts as getFinancialDiscountsAction,
  resetGetFinancialDiscountState as resetGetFinancialDiscountStateAction
} from './actions';

/**
 * BFF-DCP-001: 適用可能違約金即時ポイント一覧取得API
 * @param contractType -契約種別
 * @param mobileDeviceCode -移動機単品商品コード
 * @param contractorBirthday -契約者生年月日
 * @param networkType -ネットワーク区分
 * @param dataPlanCode -データプランコード
 * @param annualContractCode -年契約コード
 * @param callOptionCode -通話オプションコード
 * @param contractBranchCode -au契約ID
 * @param areaCodeCode -契約都道府県
 * @param agencyCode -代理店コード
 * @param auContractId -au契約ID
 * @param userBirthday -利用者生年月日
 * @param mnpReservationNo -MNP予約番号
 * @param mnpMnoNo - MNP移転元事業者コード
 * @param kddioct - KDDI/OCT区分
 * @param mnpPhoneNo - 現在利用中の他社携帯電話番号
 * @param mvnoCode -MVNO事業者識別コード
 * @param internetConnectServiceCode -インターネット接続サービスのコード
 * @param filteringService -故障紛失サポートコード
 * @param appleCareForAppleWatchApplyStatus -ユーザーが選択した故障紛失サポート(Apple Watch)コード
 * @param assuranceSupportService -フィルタリングサービスコード
 * @returns
 */
export const getPenaltyImmediatePoints = (
  contractType: ContractType,
  mobileDeviceCode: string,
  networkType: NetworkType | undefined,
  paymentTimes: PaymentTimes,
  deferredPaymentInstallmentFlag: '0' | '1',
  contractBranchCode: ContractBranchCode | undefined,
  contractorBirthday?: string,
  callOptionCode?: string,
  annualContractCode?: string,
  dataPlanCode?: string,
  areaCodeCode?: AreaCode,
  agencyCode?: string,
  auContractId?: string,
  userBirthday?: string,
  mnpReservationNo?: string,
  mnpMnoNo?: string,
  kddioct?: string,
  mnpPhoneNo?: string,
  mvnoCode?: string,
  internetConnectServiceCode?: string,
  afterCareSaleServiceCode?: string,
  appleCareForAppleWatchApplyStatus?: string,
  filteringServiceCode?: string
) => (dispatch: AppDispatch): ReturnType<ReturnType<typeof getPenaltyImmediatePointsAction>> =>
  dispatch(
    getPenaltyImmediatePointsAction({
      contractType,
      mobileDeviceCode,
      networkType,
      paymentTimes,
      deferredPaymentInstallmentFlag,
      contractBranchCode,
      contractorBirthday,
      callOptionCode,
      annualContractCode,
      dataPlanCode,
      areaCodeCode,
      agencyCode,
      auContractId,
      userBirthday,
      mnpReservationNo,
      mnpMnoNo,
      kddioct,
      mnpPhoneNo,
      mvnoCode,
      internetConnectServiceCode,
      afterCareSaleServiceCode,
      appleCareForAppleWatchApplyStatus,
      filteringServiceCode
    })
  );

/**
 * BFF-DCP-002: 割引施策一覧取得API
 * @param getCouponListParameter - BFF-DCP-002: 割引施策一覧取得API引数
 * @returns
 */
export const getCouponList = (getCouponListParameter: GetCouponListParameter) => (
  dispatch: AppDispatch
): ReturnType<ReturnType<typeof getCouponListAction>> => dispatch(getCouponListAction(getCouponListParameter));

/**
 * BFF-DCP-002: 割引施策一覧取得APIステートリセット関数
 * @returns
 */
export const resetGetCouponListState = () => (
  dispatch: AppDispatch
): ReturnType<typeof resetGetCouponListStateAction> => dispatch(resetGetCouponListStateAction());

/**
 * BFF-DCP-003: 割引サービス情報取得API取得API
 * @returns
 */
export const getDiscountService = (getDiscountServiceParameter: GetDiscountServiceParameter) => (
  dispatch: AppDispatch
): ReturnType<ReturnType<typeof getDiscountServicesAction>> =>
  dispatch(getDiscountServicesAction(getDiscountServiceParameter));

/**
 * BFF-DCP-004: 後日キャンペーン適用情報取得API
 * @returns
 */
export const getAfterDayCampaign = (getAfterDayCampaignParameter: GetAfterDayCampaignParameter) => (
  dispatch: AppDispatch
): ReturnType<ReturnType<typeof getAfterDayCampaignsAction>> =>
  dispatch(getAfterDayCampaignsAction(getAfterDayCampaignParameter));

/**
 * BFF-DCP-005: 付加サービス情報取得API
 * @returns
 */
export const getAdditionalService = (getAdditionalServiceParameter: GetAdditionalServiceParameter) => (
  dispatch: AppDispatch
): ReturnType<ReturnType<typeof getAdditionalServicesAction>> =>
  dispatch(getAdditionalServicesAction(getAdditionalServiceParameter));

/**
 * BFF-DCP-006: 毎月割情報取得API
 * @returns
 */
export const getMonthlyDiscountCampaign = (getMonthlyDiscountServiceParameter: GetMonthlyDiscountServiceParameter) => (
  dispatch: AppDispatch
): ReturnType<ReturnType<typeof getMonthlyDiscountServiceAction>> =>
  dispatch(getMonthlyDiscountServiceAction(getMonthlyDiscountServiceParameter));

/**
 * BFF-DCP-007: 金融割情報取得API
 * @returns
 */
export const getFinancialDiscountInformation = (getFinancialDiscountParameter: GetFinancialDiscountParameter) => (
  dispatch: AppDispatch
): ReturnType<ReturnType<typeof getFinancialDiscountsAction>> =>
  dispatch(getFinancialDiscountsAction(getFinancialDiscountParameter));

/**
 * BFF-DCP-007: 金融割情報取得APIステートリセット関数
 * @returns
 */
export const resetGetFinancialDiscount = () => (
  dispatch: AppDispatch
): ReturnType<typeof resetGetFinancialDiscountStateAction> => dispatch(resetGetFinancialDiscountStateAction());
