// Httpsステータスコード:業務エラー
import { AxiosError } from 'axios';
import { order } from 'ols-order-webservice-typescript-client';
import { ErrorInfo } from '@/typings/ErrorInfo';
import { BffIdType } from '@/typings/BffId';

/**
 * Axiosエラーハンドリング
 * @param error - AxiosErrorオブジェクト
 * @param bffId - BFF-ID
 * @returns Axiosエラーのハンドリングを実施し、ErrorInfoオブジェクトを返します。
 */
export const handleAxiosError = (error: AxiosError<order.ApiErrors>, bffId: BffIdType): ErrorInfo => ({
  statusCode: error.response?.status,
  statusText: error.response?.statusText,
  data: error.response?.data,
  timeout: error.code === 'ERR_CONNECTION_REFUSED' || error.code === 'ECONNABORTED' || !error.response,
  bffId
});
