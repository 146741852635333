import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { consoleLog } from '@/utils/functions';

export const ScrollToTop: React.FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    consoleLog('url move', pathname);
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};
