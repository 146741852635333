import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'document.contains/auto';
import '@/polyfills';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import { Loading, LoadingProvider, DialogProvider, SnackbarProvider } from 'ols-order-components';
import { store } from '@/store';
import App from '@/App';
import { setupDompurify } from '@/utils/setupDompurify';

// FIXME 全スタイルの読込を止める
import '@/styles/default.scss';
import 'ols-order-components/build/style.css';
import reportWebVitals from './reportWebVitals';
import { REACT_APP_GTM_ID } from './config/env';
import { Networking } from './store/network/Context';

setupDompurify();

const gtmParams = {
  id: REACT_APP_GTM_ID,
  // dataLayer.pushする場合は下記でデータレイヤー変数と値を設定
  dataLayer: {
    'dataLayer-value': 'value'
  }
};

const persistor = persistStore(store);

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <GTMProvider state={gtmParams}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <LoadingProvider>
              <DialogProvider>
                <SnackbarProvider>
                  <Networking>
                    <App />
                    <Loading />
                  </Networking>
                </SnackbarProvider>
              </DialogProvider>
            </LoadingProvider>
          </PersistGate>
        </Provider>
      </GTMProvider>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
