import { order } from 'ols-order-webservice-typescript-client';
import { format, isValid, parse } from 'date-fns';
import type { RootState } from '@/store';

/**
 * BFF-SYS-002: ログ登録API
 * @param state - ステート
 * @returns
 */
export const getCreateLogResult = (state: RootState): RootState['entity']['systemService']['createLog'] =>
  state.entity.systemService.createLog;

/**
 * BFF-SYS-003: マスク対象取得API
 * @param state - ステート
 * @returns
 */
export const getUnavailableServiceStatusesResult = (
  state: RootState
): RootState['entity']['systemService']['getUnavailableServiceStatuses'] =>
  state.entity.systemService.getUnavailableServiceStatuses;

/**
 * BFF-SYS-004: 見積もり選択可能オプション取得API
 * @param state - ステート
 * @returns
 */
export const getAvailableEstimateOptionsResult = (
  state: RootState
): RootState['entity']['systemService']['getAvailableEstimateOptions'] =>
  state.entity.systemService.getAvailableEstimateOptions;

/**
 * BFF-SYS-005: システム設定情報取得API
 * @param state - ステート
 * @returns
 */
export const getSystemSettingsResult = (state: RootState): RootState['entity']['systemService']['getSystemSettings'] =>
  state.entity.systemService.getSystemSettings;

/**
 * BFF-SYS-006: MNP予約番号有効性確認API
 * @param state - ステート
 * @returns
 */
export const getMnpEffectivenessResult = (
  state: RootState
): RootState['entity']['systemService']['getMnpEffectiveness'] => state.entity.systemService.getMnpEffectiveness;

/**
 * BFF-SYS-007: 利用不可文字確認API
 * @param state - ステート
 * @returns
 */
export const getUnavailableCharactersResult = (
  state: RootState
): RootState['entity']['systemService']['getUnavailableCharacters'] =>
  state.entity.systemService.getUnavailableCharacters;

/**
 * マスク状態取得
 * @param groupCode - マスクグループコード
 * @param id - マスクキー
 * @returns trueならマスクされている
 */
export const isMasked = (groupCode: string, id: string) => (state: RootState): boolean => {
  const list = state.entity.systemService.getUnavailableServiceStatuses.response;
  const maskGroup = list?.find((rec) => rec.unavailableServiceGroupCode === groupCode);
  if (maskGroup) {
    const eda = maskGroup.unavailableServiceStatusList.find((rec) => rec.unavailableServiceKey === id);
    if (eda) {
      return true;
    }
  }
  return false;
};

/**
 * システム日付（yyyyMMdd）取得
 * @param state  - ステート
 * @returns yyyyMMddの日付を返却する
 */
export const getSystemYmd = (state: RootState): string => {
  const ymd10 = state.entity.systemService.getSystemSettings.response?.systemDate;
  if (!ymd10) return '';
  const ymdDate = parse(ymd10, 'yyyy-MM-dd', new Date());
  const ymd8 = isValid(ymdDate) ? format(ymdDate, 'yyyyMMdd') : '';

  return ymd8;
};

/**
 * システム日付（yyyyMM）取得
 * @param state  - ステート
 * @returns yyyyMMの年月を返却する
 */
export const getSystemYm = (state: RootState): string => {
  const ymd10 = state.entity.systemService.getSystemSettings.response?.systemDate;
  if (!ymd10) return '';
  const ymdDate = parse(ymd10, 'yyyy-MM-dd', new Date());
  const ym6 = isValid(ymdDate) ? format(ymdDate, 'yyyyMM') : '';
  return ym6;
};

/**
 * 都道府県 POD021300(都道府県コード)取得
 * @param state  - ステート
 * @returns
 */
export const getPrefectureGroup = (state: RootState): Array<order.PrefectureListGetResponseV1> | undefined =>
  state.entity.systemService.getSystemSettings.response?.prefecture;

/**
 * 契約支社 POD001600(契約支社)取得
 * @param state  - ステート
 * @returns
 */
export const getContractBranchGroup = (state: RootState): Array<order.ContractBranchListGetResponseV1> | undefined =>
  state.entity.systemService.getSystemSettings.response?.contractBranch;

/**
 * 未成年年齢取得
 * @param state  - ステート
 * @returns 未成年年齢を返却する
 */
export const getMinorAge = (state: RootState): number | undefined =>
  state.entity.systemService.getSystemSettings.response?.minorAge;

/**
 * MNPワンストップ事業者 POD036500(MNPワンストップ事業者)取得
 * @param state  - ステート
 * @returns
 */
export const getMnpOnestopOperator = (state: RootState): Array<order.MnpOnestopOperatorGetResponseV1> | undefined =>
  state.entity.systemService.getSystemSettings.response?.mnpOnestopOperator;
