import { actions } from './viewCommonSlice';

export const {
  setViewCommon,
  resetViewCommon,
  resetLiquidClaimReview,
  resetInputBirthdayConfirmFlag,
  resetPhotoButtonFlag,
  setTrueEkycFlag,
  resetExternalLastScreenId
} = actions;
