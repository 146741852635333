import { AppDispatch } from '@/store';
import { CouponNoSpecifyType } from '@/typings/CouponNoSpecifyType';
import { setSectionPointCouponUse } from './actions';

/**
 * クーポン番号指定方法設定オペレータ
 * @param couponNoSpecifyType - クーポン番号指定方法
 * @returns
 */
export const setCouponNoSpecifyType = (couponNoSpecifyType: undefined | CouponNoSpecifyType) => (
  dispatch: AppDispatch
): ReturnType<typeof setSectionPointCouponUse> => dispatch(setSectionPointCouponUse({ couponNoSpecifyType }));

/**
 * セクションデータチェック処理実施要否設定オペレータ
 * @param executeCouponChecks - クーポンチェック実施要否
 * @returns
 */
export const setExecuteCouponChecks = (executeCouponChecks: boolean) => (
  dispatch: AppDispatch
): ReturnType<typeof setSectionPointCouponUse> =>
  dispatch(setSectionPointCouponUse({ isSectionDataCheckRequired: executeCouponChecks }));

/**
 * クーポン番号指定方法初期化オペレータ
 * @returns
 */
export const resetCouponNoSpecifyType = () => (dispatch: AppDispatch): ReturnType<typeof setSectionPointCouponUse> =>
  dispatch(setSectionPointCouponUse({ couponNoSpecifyType: undefined }));
