import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ScreenIdValue } from '@/typings/ScreenId';
import { PointCouponTitleCode } from '@/typings/PointCouponTitleCode';

/**
 * 住所情報
 */
export type AddressInfo = {
  /**
   * 郵便番号（ハイフンなし）
   */
  postalCode?: string;
};
/**
 * 画面共通情報
 */
export type ViewCommonState = {
  /**
   * 遷移元画面ID(新)
   */
  lastScreenId: ScreenIdValue | '';
  /**
   * 〇〇を選び直すURL
   */
  reselectButtonUrl: string;
  /**
   * auID・電話番号有無区分
   */
  auIdTelNumUmuKbn: string;
  /**
   * かんたん決済会員規約加入状態
   */
  auEasyPaymentContStatus: string;
  /*
   * 接続元端末種別
   */
  accessDeviceType: string;

  /**
   * LIQUID審査申請API初回正常フラグ
   */
  liquidClaimReviewInfoFirstNormalFlg: boolean;

  /**
   * LIQUID審査申請API正常フラグ(ブラウザバック判定用)
   */
  liquidClaimReviewTransferNormalFlg: boolean;

  /**
   * eKYCが一度でも選択されCVボタンが押下されたことを判定する用のフラグ
   */
  ekycSelectedFlg: boolean;

  /**
   * 撮影に進むor撮影をやり直す押下フラグ
   */
  photoButtonFlag?: string;

  /**
   * ポイントクーポンのTitle用コード
   * '1': ポイント・クーポン利用に進む
   * '2': クーポンの利用に進む
   * '3': ポイントの利用に進む
   */
  pointCouponTitleCode: PointCouponTitleCode;

  /**
   * 契約者の住所情報
   */
  ContractAddressInfo: AddressInfo | undefined;

  /**
   * 請求書送付先の住所情報
   */
  BillingAddressInfo: AddressInfo | undefined;

  /**
   * セクション④で入力した住所情報
   */
  InputAddressInfo: AddressInfo | undefined;

  /**
   * 生年月日モーダル正常完了判定フラグ
   */
  inputBirthdayConfirmFlag?: string;

  /**
   * 遷移元画面ID(外部経由)
   */
  externalLastScreenId: ScreenIdValue | undefined;

  /**
   * スクロール先の要素のID
   */
  scrollTargetElementId?: string;
};

const initialState: ViewCommonState = {
  lastScreenId: '',
  reselectButtonUrl: '',
  auIdTelNumUmuKbn: '',
  auEasyPaymentContStatus: '',
  accessDeviceType: '',
  liquidClaimReviewInfoFirstNormalFlg: false,
  liquidClaimReviewTransferNormalFlg: false,
  ekycSelectedFlg: false,
  photoButtonFlag: undefined,
  pointCouponTitleCode: '1',
  ContractAddressInfo: undefined,
  BillingAddressInfo: undefined,
  InputAddressInfo: undefined,
  inputBirthdayConfirmFlag: undefined,
  externalLastScreenId: undefined,
  scrollTargetElementId: undefined
};

const liquidClaimReviewInit = {
  liquidClaimReviewInfoFirstNormalFlg: false,
  liquidClaimReviewTransferNormalFlg: false
};

const slice = createSlice({
  name: 'viewCommon',
  initialState,
  reducers: {
    setViewCommon: (state: ViewCommonState, action: PayloadAction<Partial<ViewCommonState>>) => ({
      ...state,
      ...action.payload
    }),
    resetViewCommon: (state: ViewCommonState) => ({
      ...state,
      ...initialState
    }),
    resetLiquidClaimReview: (state: ViewCommonState) => ({
      ...state,
      ...liquidClaimReviewInit
    }),
    setTrueEkycFlag: (state: ViewCommonState) => ({
      ...state,
      ...{ ekycSelectedFlg: true }
    }),
    resetInputBirthdayConfirmFlag: (state: ViewCommonState) => ({
      ...state,
      ...{ inputBirthdayConfirmFlag: undefined }
    }),
    resetPhotoButtonFlag: (state: ViewCommonState) => ({
      ...state,
      ...{ photoButtonFlag: undefined }
    }),
    resetExternalLastScreenId: (state: ViewCommonState) => ({
      ...state,
      ...{ externalLastScreenId: undefined }
    })
  }
});

export const { actions, reducer } = slice;
