import { CaseReducer, combineReducers, createSlice } from '@reduxjs/toolkit';
import { order } from 'ols-order-webservice-typescript-client';
import { ServiceState } from '@/typings/ServiceState';
import {
  getSalesProduct,
  getCandidateAfterSaleServices,
  getInstallmentPriceByProductStockCode,
  getOptionalProducts,
  getOptionalProductDetail,
  getDecisionDeviceChangeIncrease,
  getCandidateAfterSaleServicesForAppleWatch,
  getDeliveryAvailableDate
} from './thunk';

const initialState = {
  loaded: false,
  failed: false,
  response: null,
  error: null
};

/**
 * BFF-PRD-001: 販売商品情報取得API
 */
export const getSalesProductSlice = createSlice<ServiceState<order.SalesProductGetResponseV1>, Record<string, never>>({
  name: 'productService/getSalesProduct',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSalesProduct.pending, () => initialState)
      .addCase(getSalesProduct.rejected, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: true,
          response: null,
          error: action.payload || null
        }
      }))
      .addCase(getSalesProduct.fulfilled, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: false,
          response: action.payload,
          error: null
        }
      }));
  }
});

/**
 * BFF-PRD-002: 保証・サポートサービス取得API
 */
export const getCandidateAfterSaleServicesSlice = createSlice<
  ServiceState<order.CandidateAfterSaleServicesGetResponseV1>,
  Record<string, never>
>({
  name: 'productService/getCandidateAfterSaleServices',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCandidateAfterSaleServices.pending, () => initialState)
      .addCase(getCandidateAfterSaleServices.rejected, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: true,
          response: null,
          error: action.payload || null
        }
      }))
      .addCase(getCandidateAfterSaleServices.fulfilled, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: false,
          response: action.payload,
          error: null
        }
      }));
  }
});

/**
 * BFF-PRD-003: 端末割賦代金マスタAPI
 */
export const getInstallmentPriceByProductStockCodeSlice = createSlice<
  ServiceState<order.DeviceInstallmentPriceByProductStockCodeGetResponseV1>,
  Record<string, never>
>({
  name: 'productService/getInstallmentPriceByProductStockCode',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getInstallmentPriceByProductStockCode.pending, () => initialState)
      .addCase(getInstallmentPriceByProductStockCode.rejected, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: true,
          response: null,
          error: action.payload || null
        }
      }))
      .addCase(getInstallmentPriceByProductStockCode.fulfilled, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: false,
          response: action.payload,
          error: null
        }
      }));
  }
});

/**
 * BFF-PRD-004: アクセサリー一覧取得API
 */
export const getOptionalProductsSlice = createSlice<
  ServiceState<order.AccessoriesGetResponseV1>,
  Record<string, CaseReducer>
>({
  name: 'productService/getOptionalProducts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOptionalProducts.pending, () => initialState)
      .addCase(getOptionalProducts.rejected, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: true,
          response: null,
          error: action.payload || null
        }
      }))
      .addCase(getOptionalProducts.fulfilled, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: false,
          response: action.payload,
          error: null
        }
      }));
  }
});

/**
 * BFF-PRD-005: アクセサリー詳細情報取得API
 */
export const getOptionalProductDetailSlice = createSlice<
  ServiceState<order.AccessoryDetailGetResponseV1>,
  Record<string, CaseReducer>
>({
  name: 'productService/getOptionalProductDetail',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOptionalProductDetail.pending, () => initialState)
      .addCase(getOptionalProductDetail.rejected, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: true,
          response: null,
          error: action.payload || null
        }
      }))
      .addCase(getOptionalProductDetail.fulfilled, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: false,
          response: action.payload,
          error: null
        }
      }));
  }
});

/**
 * BFF-PRD-006: 機種変更区分取得API
 */
export const getDecisionDeviceChangeIncreaseSlice = createSlice<
  ServiceState<order.DeviceChangeTypeGetResponseV1>,
  Record<string, CaseReducer>
>({
  name: 'productService/getDecisionDeviceChangeIncrease',
  initialState,
  reducers: {
    resetDecisionDeviceChangeIncreaseServiceState: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDecisionDeviceChangeIncrease.pending, () => initialState)
      .addCase(getDecisionDeviceChangeIncrease.rejected, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: true,
          response: null,
          error: action.payload || null
        }
      }))
      .addCase(getDecisionDeviceChangeIncrease.fulfilled, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: false,
          response: action.payload,
          error: null
        }
      }));
  }
});

/**
 * BFF-PRD-007: AppleWatch用保証・サポートサービス取得API
 */
export const getCandidateAfterSaleServicesForAppleWatchSlice = createSlice<
  ServiceState<order.CandidateAfterSaleServicesForAppleWatchGetResponseV1>,
  Record<string, CaseReducer>
>({
  name: 'productService/getCandidateAfterSaleServicesForAppleWatch',
  initialState,
  reducers: {
    resetCandidateAfterSaleServicesForAppleWatchServiceState: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCandidateAfterSaleServicesForAppleWatch.pending, () => initialState)
      .addCase(getCandidateAfterSaleServicesForAppleWatch.rejected, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: true,
          response: null,
          error: action.payload || null
        }
      }))
      .addCase(getCandidateAfterSaleServicesForAppleWatch.fulfilled, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: false,
          response: action.payload,
          error: null
        }
      }));
  }
});

/**
 * BFF-PRD-008: お届け可能日取得API
 */
export const getDeliveryAvailableDateSlice = createSlice<
  ServiceState<order.DeliveryAvailableDateListGetResponseV1>,
  Record<string, CaseReducer>
>({
  name: 'productService/getDeliveryAvailableDate',
  initialState,
  reducers: {
    resetDeliveryAvailableDateServiceState: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDeliveryAvailableDate.pending, () => initialState)
      .addCase(getDeliveryAvailableDate.rejected, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: true,
          response: null,
          error: action.payload || null
        }
      }))
      .addCase(getDeliveryAvailableDate.fulfilled, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: false,
          response: action.payload,
          error: null
        }
      }));
  }
});

export const reducers = combineReducers({
  getSalesProduct: getSalesProductSlice.reducer,
  getCandidateAfterSaleServices: getCandidateAfterSaleServicesSlice.reducer,
  getInstallmentPriceByProductStockCode: getInstallmentPriceByProductStockCodeSlice.reducer,
  getOptionalProducts: getOptionalProductsSlice.reducer,
  getOptionalProductDetail: getOptionalProductDetailSlice.reducer,
  getDecisionDeviceChangeIncrease: getDecisionDeviceChangeIncreaseSlice.reducer,
  getCandidateAfterSaleServicesForAppleWatch: getCandidateAfterSaleServicesForAppleWatchSlice.reducer,
  getDeliveryAvailableDate: getDeliveryAvailableDateSlice.reducer
});
