import { order } from 'ols-order-webservice-typescript-client';
import { CaseReducer, combineReducers, createSlice } from '@reduxjs/toolkit';
import { ServiceState } from '@/typings/ServiceState';
import {
  getEstimatingOrder,
  createEstimatingOrder,
  getPendingReservationsInfo,
  getEstimateSaveMailByEstimateId,
  createEstimateSaveMailByEstimateId,
  postEstimatedAmounts,
  getEstimateSessionClear,
  getSimulationInfo,
  postEstimatedTemporaryAmounts
} from './thunk';

const initialState = {
  loaded: false,
  failed: false,
  response: null,
  error: null
};

/**
 * BFF-EST-001: 注文見積もり情報取得API
 */
export const getEstimatingOrderSlice = createSlice<ServiceState<void>, Record<string, never>>({
  name: 'estimateService/getEstimatingOrder',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getEstimatingOrder.pending, () => initialState)
      .addCase(getEstimatingOrder.rejected, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: true,
          response: null,
          error: action.payload || null
        }
      }))
      .addCase(getEstimatingOrder.fulfilled, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: false,
          response: action.payload,
          error: null
        }
      }));
  }
});

/**
 * BFF-EST-002: 注文見積もり情報登録API
 */
export const createEstimatingOrderSlice = createSlice<
  ServiceState<order.EstimatingOrderPostResponseV1>,
  Record<string, CaseReducer>
>({
  name: 'estimateService/createEstimatingOrder',
  initialState,
  reducers: {
    resetCreateEstimatingOrder: (state) => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(createEstimatingOrder.pending, () => initialState)
      .addCase(createEstimatingOrder.rejected, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: true,
          response: null,
          error: action.payload || null
        }
      }))
      .addCase(createEstimatingOrder.fulfilled, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: false,
          response: action.payload,
          error: null
        }
      }));
  }
});

/**
 * BFF-EST-003: 本申込情報取得API
 */
export const getPendingReservationsInfoSlice = createSlice<
  ServiceState<order.PendingReservationsInfoGetResponseV1>,
  Record<string, CaseReducer>
>({
  name: 'estimateService/getPendingReservationsInfo',
  initialState,
  reducers: {
    resetPendingReservationsInfo: (state) => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPendingReservationsInfo.rejected, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: true,
          response: null,
          error: action.payload || null
        }
      }))
      .addCase(getPendingReservationsInfo.fulfilled, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: false,
          response: action.payload,
          error: null
        }
      }));
  }
});

/**
 * BFF-EST-004: 見積保存メール情報取得API
 */
export const getEstimateSaveMailByEstimateIdSlice = createSlice<
  ServiceState<order.EstimateSaveMailByEstimateIdGetResponseV1>,
  Record<string, never>
>({
  name: 'estimateService/getEstimateSaveMailByEstimateId',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getEstimateSaveMailByEstimateId.pending, () => initialState)
      .addCase(getEstimateSaveMailByEstimateId.rejected, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: true,
          response: null,
          error: action.payload || null
        }
      }))
      .addCase(getEstimateSaveMailByEstimateId.fulfilled, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: false,
          response: action.payload,
          error: null
        }
      }));
  }
});

/**
 * BFF-EST-005: 見積保存メール情報登録API
 */
export const createEstimateSaveMailByEstimateIdSlice = createSlice<ServiceState<void>, Record<string, CaseReducer>>({
  name: 'estimateService/createEstimateSaveMailByEstimateId',
  initialState,
  reducers: {
    resetEstimateSaveMailByEstimateId: (state) => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(createEstimateSaveMailByEstimateId.pending, () => initialState)
      .addCase(createEstimateSaveMailByEstimateId.rejected, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: true,
          response: null,
          error: action.payload || null
        }
      }))
      .addCase(createEstimateSaveMailByEstimateId.fulfilled, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: false,
          response: action.payload,
          error: null
        }
      }));
  }
});

/**
 * BFF-EST-006: 料金費用内訳計算API
 */
export const postEstimatedAmountsSlice = createSlice<
  ServiceState<order.EstimateAmountsPostResponseV1>,
  Record<string, CaseReducer>
>({
  name: 'estimateService/postEstimatedAmounts',
  initialState,
  reducers: {
    resetPostEstimatedAmounts: (state) => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(postEstimatedAmounts.rejected, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: true,
          response: null,
          error: action.payload || null
        }
      }))
      .addCase(postEstimatedAmounts.fulfilled, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: false,
          response: action.payload,
          error: null
        }
      }));
  }
});

/**
 * BFF-EST-007: 割引施策セッション初期化API
 */
export const getEstimateSessionClearSlice = createSlice<ServiceState<void>, Record<string, never>>({
  name: 'estimateService/getEstimateSessionClear',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getEstimateSessionClear.pending, () => initialState)
      .addCase(getEstimateSessionClear.rejected, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: true,
          response: null,
          error: action.payload || null
        }
      }))
      .addCase(getEstimateSessionClear.fulfilled, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: false,
          response: action.payload,
          error: null
        }
      }));
  }
});

/**
 * BFF-EST-009: シミュレーション情報取得API
 */
export const getSimulationInfoSlice = createSlice<
  ServiceState<order.SimulationInfoGetResponseV1>,
  Record<string, CaseReducer>
>({
  name: 'estimateService/getSimulationInfo',
  initialState,
  reducers: {
    resetEstimateSimulationResult: (state) => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSimulationInfo.rejected, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: true,
          response: null,
          error: action.payload || null
        }
      }))
      .addCase(getSimulationInfo.fulfilled, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: false,
          response: action.payload,
          error: null
        }
      }));
  }
});

/**
 * BFF-EST-010: 暫定料金費用内訳計算API
 */
export const postEstimatedTemporaryAmountsSlice = createSlice<
  ServiceState<order.EstimateTemporaryAmountsPostResponseV1>,
  Record<string, CaseReducer>
>({
  name: 'estimateService/postEstimatedTemporaryAmounts',
  initialState,
  reducers: {
    resetPostEstimatedTemporaryAmounts: (state) => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(postEstimatedTemporaryAmounts.pending, () => initialState)
      .addCase(postEstimatedTemporaryAmounts.rejected, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: true,
          response: null,
          error: action.payload || null
        }
      }))
      .addCase(postEstimatedTemporaryAmounts.fulfilled, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: false,
          response: action.payload,
          error: null
        }
      }));
  }
});

export const reducers = combineReducers({
  getEstimatingOrder: getEstimatingOrderSlice.reducer,
  createEstimatingOrder: createEstimatingOrderSlice.reducer,
  getPendingReservationsInfo: getPendingReservationsInfoSlice.reducer,
  getEstimateSaveMailByEstimateId: getEstimateSaveMailByEstimateIdSlice.reducer,
  createEstimateSaveMailByEstimateId: createEstimateSaveMailByEstimateIdSlice.reducer,
  postEstimatedAmounts: postEstimatedAmountsSlice.reducer,
  getEstimateSessionClear: getEstimateSessionClearSlice.reducer,
  getSimulationInfo: getSimulationInfoSlice.reducer,
  postEstimatedTemporaryAmounts: postEstimatedTemporaryAmountsSlice.reducer
});
