import {
  actions,
  getAdditionalServiceSlice,
  getAfterDayCampaignSlice,
  getDiscountServiceSlice,
  getMonthlyDiscountSlice,
  getFinancialDiscountSlice
} from './discountCampaignServiceSlice';

export {
  getPenaltyImmediatePoints,
  getCouponList,
  getAfterDayCampaigns,
  getDiscountServices,
  getAdditionalServices,
  getMonthlyDiscountService,
  getFinancialDiscounts
} from './thunk';
export const { resetGetCouponListState } = actions;
export const { resetGetDiscountServiceState } = getDiscountServiceSlice.actions;
export const { resetGetAfterDayCampaignState } = getAfterDayCampaignSlice.actions;
export const { resetGetAdditionalServiceState } = getAdditionalServiceSlice.actions;
export const { resetGetMonthlyDiscountServiceState } = getMonthlyDiscountSlice.actions;
export const { resetGetFinancialDiscountState } = getFinancialDiscountSlice.actions;
