import { order } from 'ols-order-webservice-typescript-client';
import { consoleLog } from '@/utils/functions';

const { AddressApi } = order;

export class AddressApiService {
  private static instance: AddressApiService;

  private api: order.AddressApi;

  private constructor(config: order.Configuration) {
    this.api = new AddressApi(config);
  }

  public static getInstance(config: order.Configuration): AddressApiService {
    if (!this.instance) {
      this.instance = new AddressApiService(config);
    }

    return this.instance;
  }

  /**
   * BFF-ADD-001: 住所情報取得API
   * @param postalCode - 取得したい住所の郵便番号。
   * @returns
   */
  getAddressV1(postalCode: string): Promise<order.AddressInfoGetResponseV1> {
    consoleLog('BFF-ADD-001: 住所情報取得API call', postalCode);
    return this.api.getAddressV1(postalCode).then((res) => res.data);
  }
}
