import { order } from 'ols-order-webservice-typescript-client';
import { consoleLog } from '@/utils/functions';

const { OrderPaymentApi } = order;

export class OrderPaymentApiService {
  private static instance: OrderPaymentApiService;

  private api: order.OrderPaymentApi;

  private constructor(config: order.Configuration) {
    this.api = new OrderPaymentApi(config);
  }

  public static getInstance(config: order.Configuration): OrderPaymentApiService {
    if (!this.instance) {
      this.instance = new OrderPaymentApiService(config);
    }

    return this.instance;
  }

  /**
   * BFF-ORD-001: 決済ステータス情報登録API
   * @param void -引数なし
   * @returns
   */
  createPaymentStatus(): Promise<void> {
    consoleLog('BFF-ORD-001: 決済ステータス情報登録API call');
    return this.api.createPaymentStatusV1().then((res) => res.data);
  }

  /**
   * BFF-ORD-002: 決済実行API
   * @param paymentPostRequestV1 -決済実行のリクエストモデル
   * @returns
   */
  createPayment(paymentPostRequestV1?: order.PaymentPostRequestV1): Promise<order.PaymentPostResponseV1> {
    consoleLog('BFF-ORD-002: 決済実行API call', paymentPostRequestV1);
    return this.api.createPaymentV1(paymentPostRequestV1).then((res) => res.data);
  }

  /**
   * BFF-ORD-003: 認証後決済実行API
   * @param authenticatedPaymentPostRequestV1 -認証後決済実行のリクエストモデル
   * @returns
   */
  createAuthenticatedPayment(
    authenticatedPaymentPostRequestV1?: order.AuthenticatedPaymentPostRequestV1
  ): Promise<void> {
    consoleLog('BFF-ORD-003: 認証後決済実行API call', authenticatedPaymentPostRequestV1);
    return this.api.createAuthenticatedPaymentV1(authenticatedPaymentPostRequestV1).then((res) => res.data);
  }

  /**
   * BFF-ORD-004: 注文情報登録API
   * @param orderPostRequestV1 -注文登録のリクエストモデル
   * @returns
   */
  createOrder(orderPostRequestV1?: order.OrderPostRequestV1): Promise<order.OrderPostResponseV1> {
    consoleLog('BFF-ORD-004: 注文情報登録API call', orderPostRequestV1);
    return this.api.createOrderV1(orderPostRequestV1).then((res) => res.data);
  }

  /**
   * BFF-ORD-005: 注文時確認事項登録API
   * @param orderConformationPostRequestV1 -注文時確認事項登録のリクエストモデル
   * @returns
   */
  createOrderConfirmation(orderConformationPostRequestV1?: order.OrderConformationPostRequestV1): Promise<void> {
    consoleLog('BFF-ORD-005: 注文時確認事項登録API call', orderConformationPostRequestV1);
    return this.api.createOrderConfirmationV1(orderConformationPostRequestV1).then((res) => res.data);
  }

  /**
   * BFF-ORD-008: お支払い方法登録API
   * @param orderConformationPostRequestV1 -お支払い方法登録のリクエストモデル
   * @returns
   */
  createPaymentMethod(
    createPaymentMethodPostRequestV1?: order.CreatePaymentMethodPostRequestV1
  ): Promise<order.CreatePaymentMethodPostResponseV1> {
    consoleLog('BFF-ORD-008: お支払い方法登録API call', createPaymentMethodPostRequestV1);
    return this.api.createPaymentMethodV1(createPaymentMethodPostRequestV1).then((res) => res.data);
    //
  }

  /**
   * BFF-ORD-009: 口振GW受取文字列チェックAPI
   * @param validPaymentMethodPostRequestV1 -口振GW受取文字列チェックのリクエストモデル
   * @returns
   */
  validAccountTransfer(validPaymentMethodPostRequestV1: string): Promise<void> {
    consoleLog('BFF-ORD-009: 口振GW受取文字列チェックAPI call', validPaymentMethodPostRequestV1);
    return this.api.validAccountTransferV1(validPaymentMethodPostRequestV1).then((res) => res.data);
  }

  /**
   * BFF-ORD-010: 口振GW受取文字列登録API
   * @returns
   */
  createAccountTransfer(): Promise<void> {
    consoleLog('BFF-ORD-010: 口振GW受取文字列チェックAPI call');
    return this.api.createAccountTransferV1().then((res) => res.data);
  }

  /**
   * BFF-ORD-011: auかんたん決済実行API
   * @param paymentAuEasyPaymentPostRequestV1 -auかんたん決済実行APIのリクエストモデル
   * @returns
   */
  paymentAuEasyPayment(
    paymentAuEasyPaymentPostRequestV1?: order.PaymentAuEasyPaymentPostRequestV1
  ): Promise<order.PaymentAuEasyPaymentPostResponseV1> {
    consoleLog('BFF-ORD-011: auかんたん決済実行API call', paymentAuEasyPaymentPostRequestV1);
    return this.api.paymentAuEasyPaymentV1(paymentAuEasyPaymentPostRequestV1).then((res) => res.data);
  }
}
