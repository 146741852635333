/**
 * アクセサリーの支払い方法
 */
export type PaymentMethodType = '1' | '12' | '24' | '36' | '48';
export const PaymentMethodsTypes = {
  Installment12Times: '12',
  Installment24Times: '24',
  Installment36Times: '36',
  Installment48Times: '48',
  LumpSumPayment: '1'
} as const;
export const PaymentMethodsLabels = {
  '12': '12回払い',
  '24': '24回払い',
  '36': '36回払い',
  '48': '48回払い',
  '1': '一括払い'
} as const;

export const PaymentMethodsNames = {
  LumpSumPay: '一括払い',
  Twelve: '割賦払い 12回',
  TwentyFour: '割賦払い24回',
  ThirtySix: '割賦払い 36回',
  FortyEight: '割賦払い 48回'
} as const;

export const PaymentMethodsNames2 = {
  一括払い: '1',
  '12回払い': '12',
  '24回払い': '24',
  '36回払い': '36',
  '48回払い': '48'
} as const;
