import { createSlice, PayloadAction } from '@reduxjs/toolkit';

/**
 * エラー情報
 * 汎用エラー画面に表示する内容とログ登録APIで送信する内容を保持します
 * 遷移元（エラー発生元）にて設定後に汎用エラー画面を呼び出すこと
 */
export type ErrorState = {
  /**
   * エラー種別
   * systemError: システムエラー
   * businessError: 業務エラー
   */
  kind: 'systemError' | 'businessError' | '';
  /**
   * エラーメッセージID（フロント）
   */
  messageId: string;
  /**
   * エラーメッセージ詳細（フロント）
   * 例外発生時のエラー詳細内容（stackTraceやcomponentStack）を設定
   */
  messageDetail: string;
  /**
   * エラーメッセージID（BFF）
   * BFF呼出しに関するエラーの場合に設定
   */
  bffMessageId?: string;
};

const initialState: ErrorState = {
  kind: '',
  messageId: '',
  messageDetail: ''
};

const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    setError: (state: ErrorState, action: PayloadAction<Partial<ErrorState>>) => ({
      ...state,
      ...action.payload
    }),
    resetError: (state: ErrorState) => ({
      ...state,
      ...initialState
    })
  }
});

export const { actions, reducer } = errorSlice;
