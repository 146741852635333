import { CaseReducer, combineReducers, createSlice } from '@reduxjs/toolkit';
import { order } from 'ols-order-webservice-typescript-client';
import { ServiceState } from '@/typings/ServiceState';
import {
  createPaymentStatus,
  createPayment,
  createAuthenticatedPayment,
  createOrder,
  createOrderConfirmation,
  createPaymentMethod,
  validAccountTransfer,
  createAccountTransfer,
  paymentAuEasyPayment
} from './thunk';

const initialState = {
  loaded: false,
  failed: false,
  response: null,
  error: null
};

/**
 * BFF-ORD-001: 決済ステータス情報登録API
 */
export const createPaymentStatusSlice = createSlice<ServiceState<void>, Record<string, CaseReducer>>({
  name: 'orderPaymentService/createPaymentStatus',
  initialState,
  reducers: {
    resetCreatePaymentStatus: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(createPaymentStatus.pending, () => initialState)
      .addCase(createPaymentStatus.rejected, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: true,
          response: null,
          error: action.payload || null
        }
      }))
      .addCase(createPaymentStatus.fulfilled, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: false,
          response: null,
          error: null
        }
      }));
  }
});

/**
 * BFF-ORD-002: 決済実行API
 */
export const createPaymentSlice = createSlice<ServiceState<order.PaymentPostResponseV1>, Record<string, CaseReducer>>({
  name: 'orderPaymentService/createPayment',
  initialState,
  reducers: {
    resetCreatePayment: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(createPayment.pending, () => initialState)
      .addCase(createPayment.rejected, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: true,
          response: null,
          error: action.payload || null
        }
      }))
      .addCase(createPayment.fulfilled, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: false,
          response: action.payload,
          error: null
        }
      }));
  }
});

/**
 * BFF-ORD-003: 認証後決済実行API
 */
export const createAuthenticatedPaymentSlice = createSlice<ServiceState<void>, Record<string, CaseReducer>>({
  name: 'orderPaymentService/createAuthenticatedPayment',
  initialState,
  reducers: {
    resetAuthenticatedPayment: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(createAuthenticatedPayment.pending, () => initialState)
      .addCase(createAuthenticatedPayment.rejected, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: true,
          response: null,
          error: action.payload || null
        }
      }))
      .addCase(createAuthenticatedPayment.fulfilled, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: false,
          response: null,
          error: null
        }
      }));
  }
});

/**
 * BFF-ORD-004: 注文情報登録API
 */
export const createOrderSlice = createSlice<ServiceState<order.OrderPostResponseV1>, Record<string, CaseReducer>>({
  name: 'orderPaymentService/createOrder',
  initialState,
  reducers: {
    resetCreateOrder: (state) => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(createOrder.pending, () => initialState)
      .addCase(createOrder.rejected, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: true,
          response: null,
          error: action.payload || null
        }
      }))
      .addCase(createOrder.fulfilled, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: false,
          response: action.payload,
          error: null
        }
      }));
  }
});

/**
 * BFF-ORD-005: 注文時確認事項登録API
 */
export const createOrderConfirmationSlice = createSlice<ServiceState<void>, Record<string, CaseReducer>>({
  name: 'orderPaymentService/createOrderConfirmation',
  initialState,
  reducers: {
    resetCreateOrderConfirmation: (state) => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(createOrderConfirmation.pending, () => initialState)
      .addCase(createOrderConfirmation.rejected, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: true,
          response: null,
          error: action.payload || null
        }
      }))
      .addCase(createOrderConfirmation.fulfilled, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: false,
          response: action.payload,
          error: null
        }
      }));
  }
});

/**
 * BFF-ORD-008: お支払い方法登録API
 */
export const createPaymentMethodSlice = createSlice<
  ServiceState<order.CreatePaymentMethodPostResponseV1>,
  Record<string, CaseReducer>
>({
  name: 'orderPaymentService/createPaymentMethod',
  initialState,
  reducers: { resetPaymentMethod: (state) => initialState },
  extraReducers: (builder) => {
    builder
      .addCase(createPaymentMethod.pending, () => initialState)
      .addCase(createPaymentMethod.rejected, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: true,
          response: null,
          error: action.payload || null
        }
      }))
      .addCase(createPaymentMethod.fulfilled, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: false,
          response: action.payload,
          error: null
        }
      }));
  }
});

/**
 * BFF-ORD-009: 口振GW連携文字列生成API
 */
export const validAccountTransferSlice = createSlice<ServiceState<void>, Record<string, CaseReducer>>({
  name: 'orderPaymentService/validAccountTransfer',
  initialState,
  reducers: {
    resetValidAccountTransfer: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(validAccountTransfer.pending, () => initialState)
      .addCase(validAccountTransfer.rejected, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: true,
          response: null,
          error: action.payload || null
        }
      }))
      .addCase(validAccountTransfer.fulfilled, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: false,
          response: action.payload,
          error: null
        }
      }));
  }
});

/**
 * BFF-ORD-010: 口振GW受取文字列登録API
 */
export const createAccountTransferSlice = createSlice<ServiceState<void>, Record<string, CaseReducer>>({
  name: 'orderPaymentService/createAccountTransfer',
  initialState,
  reducers: {
    resetAccountTransfer: (state) => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(createAccountTransfer.pending, () => initialState)
      .addCase(createAccountTransfer.rejected, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: true,
          response: null,
          error: action.payload || null
        }
      }))
      .addCase(createAccountTransfer.fulfilled, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: false,
          response: action.payload,
          error: null
        }
      }));
  }
});

/**
 * BFF-ORD-011: auかんたん決済実行API
 */
export const paymentAuEasyPaymentSlice = createSlice<
  ServiceState<order.PaymentAuEasyPaymentPostResponseV1>,
  Record<string, CaseReducer>
>({
  name: 'orderPaymentService/paymentAuEasyPayment',
  initialState,
  reducers: { resetPaymentAuEasyPayment: (state) => initialState },
  extraReducers: (builder) => {
    builder
      .addCase(paymentAuEasyPayment.pending, () => initialState)
      .addCase(paymentAuEasyPayment.rejected, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: true,
          response: null,
          error: action.payload || null
        }
      }))
      .addCase(paymentAuEasyPayment.fulfilled, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: false,
          response: action.payload,
          error: null
        }
      }));
  }
});

export const reducers = combineReducers({
  createPaymentStatus: createPaymentStatusSlice.reducer,
  createPayment: createPaymentSlice.reducer,
  createAuthenticatedPayment: createAuthenticatedPaymentSlice.reducer,
  createOrder: createOrderSlice.reducer,
  createOrderConfirmation: createOrderConfirmationSlice.reducer,
  createPaymentMethod: createPaymentMethodSlice.reducer,
  validAccountTransfer: validAccountTransferSlice.reducer,
  createAccountTransfer: createAccountTransferSlice.reducer,
  paymentAuEasyPayment: paymentAuEasyPaymentSlice.reducer
});
