import { AppDispatch } from '@/store';
import {
  setOnetimePasswordStatus,
  resetOnetimePasswordStatus,
  incrementIssueCount,
  incrementErrorCount,
  resetErrorCounts
} from './actions';
import { OnetimePasswordStatusState } from './onetimePasswordStatusSlice';

/**
 * ワンタイムパスワード状態更新オペレータ
 * @param state - ステート
 * @returns
 */
export const set = (state: Partial<OnetimePasswordStatusState>) => (
  dispatch: AppDispatch
): ReturnType<typeof setOnetimePasswordStatus> => dispatch(setOnetimePasswordStatus(state));

/**
 * ワンタイムパスワード状態初期化オペレータ
 * @returns
 */
export const reset = () => (dispatch: AppDispatch): ReturnType<typeof resetOnetimePasswordStatus> =>
  dispatch(resetOnetimePasswordStatus());

/**
 * 発行回数カウントアップオペレータ
 * @param state - ステート
 * @returns
 */
export const setIncrementIssueCount = () => (dispatch: AppDispatch): ReturnType<typeof incrementIssueCount> =>
  dispatch(incrementIssueCount());

/**
 * エラー回数カウントアップオペレータ
 * @param state - ステート
 */
export const setIncrementErrorCount = () => (dispatch: AppDispatch): ReturnType<typeof incrementErrorCount> =>
  dispatch(incrementErrorCount());

/**
 * エラー回数リセットオペレータ
 * @param state - ステート
 */
export const resetErrorCount = () => (dispatch: AppDispatch): ReturnType<typeof resetErrorCounts> =>
  dispatch(resetErrorCounts());
