/**
 * 商品のお支払い方法を表すコード値
 */

export type ProductPaymentMethod = '0' | '1' | '2' | '3' | '4';

/**
 * 商品のお支払い方法Enum
 */
export const ProductPaymentMethods = {
  CREDIT_CARD: '0',
  CASH_ON_DELIVERY: '1',
  AU_PAY_BALANCE: '2',
  AU_PAY_CARD: '3',
  EASY_PAYMENT: '4'
} as const;

/**
 * 商品のお支払い方法ラベル
 */
export const ProductPaymentMethodLabels = {
  '0': 'クレジットカード',
  '1': '代金引換',
  '2': 'au PAY残高支払い',
  '3': 'au PAYカード支払い',
  '4': 'auかんたん決済（通信料金合算支払い）'
} as const;
