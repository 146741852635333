import React, { useState, useRef, useEffect } from 'react';
import DOMPurify from 'dompurify';
import cn from 'classnames';

import {
  Dialog,
  DialogBody,
  DialogContainer,
  DialogFooter,
  DialogHeader,
  DialogPortal,
  ImportantHeading,
  useDialog,
  Button,
  InlineLoading,
  ScrollArea
} from 'ols-order-components';

import { consoleLog, NOP } from '@/utils/functions';
import style from './style.module.scss';
import { IncludeContainer } from './IncludeContainer';

const parents: (e: HTMLElement, selector: string) => HTMLElement | null = (e, selector) => {
  if (e.matches(selector)) {
    return e;
  }

  if (e.parentElement === null) {
    return null;
  }

  return parents(e.parentElement, selector);
};

type Props = {
  id: string;
  required: boolean;
  loaded: boolean;
  showLoading: boolean;
  loading: boolean;
  failed?: boolean;
  loadHandler: () => void;
  content?: string;
};

type ModalProps = {
  id: string;
  type: string | null;
  title?: string | null;
  showCloseButton: boolean;
  buttonLabel?: string | null;
};

const Modal: React.FC<ModalProps> = ({ id, type, title, showCloseButton, buttonLabel }) => {
  if (type === 'modal') {
    return (
      <DialogPortal name={id}>
        {({ closeMain, closeSub }) => {
          const handleBackdrop = showCloseButton ? closeSub : NOP;
          const handleClose = showCloseButton ? closeMain : NOP;
          return (
            <DialogContainer onClickBackdrop={handleBackdrop}>
              <Dialog>
                {showCloseButton && <DialogHeader onClose={handleClose} />}
                <DialogBody>
                  <ScrollArea autoHide maxHeight="calc(100vh - 240px)">
                    <IncludeContainer id={id} />
                  </ScrollArea>
                </DialogBody>
                <DialogFooter>
                  <Button variant="secondary" onClick={closeMain}>
                    {buttonLabel || '閉じる'}
                  </Button>
                </DialogFooter>
              </Dialog>
            </DialogContainer>
          );
        }}
      </DialogPortal>
    );
  }

  if (type === 'notes') {
    return (
      <DialogPortal name={id}>
        {({ closeMain, closeSub }) => {
          const handleClose = showCloseButton ? closeSub : NOP;
          return (
            <DialogContainer onClickBackdrop={handleClose}>
              <Dialog variant={showCloseButton ? 'important' : 'importantNoClose'}>
                {showCloseButton && <DialogHeader onClose={closeSub} />}
                <ImportantHeading>{title || 'ご注意ください'}</ImportantHeading>
                <DialogBody>
                  <ScrollArea autoHide maxHeight="calc(100vh - 240px)">
                    <IncludeContainer id={id} />
                  </ScrollArea>
                </DialogBody>
                <DialogFooter>
                  <Button variant="alert" onClick={closeMain}>
                    {buttonLabel || 'OK'}
                  </Button>
                </DialogFooter>
              </Dialog>
            </DialogContainer>
          );
        }}
      </DialogPortal>
    );
  }

  if (type === 'important_info') {
    const includePdfId = `${id}_pdf`;
    return (
      <DialogPortal name={id}>
        {({ closeMain, closeSub }) => {
          const handleClose = showCloseButton ? closeSub : NOP;
          return (
            <DialogContainer onClickBackdrop={handleClose}>
              <Dialog variant={showCloseButton ? 'important' : 'importantNoClose'}>
                {showCloseButton && <DialogHeader onClose={closeSub} />}
                <ImportantHeading>{title || '重要事項を確認する'}</ImportantHeading>
                <DialogBody>
                  <ScrollArea autoHide maxHeight="calc(100vh - 280px)">
                    <IncludeContainer id={id} />
                  </ScrollArea>
                </DialogBody>
                <DialogFooter>
                  <IncludeContainer id={includePdfId} />
                  <Button variant="alert" onClick={closeMain}>
                    {buttonLabel || 'この記載内容に同意する'}
                  </Button>
                </DialogFooter>
              </Dialog>
            </DialogContainer>
          );
        }}
      </DialogPortal>
    );
  }

  return null;
};

/**
 * Includeコンポーネント
 * @param id - インクルードID
 * @param required - 必須フラグ
 * @param loaded - 読込済みフラフ
 * @param loading - 読込中フラグ
 * @param content - 読込コンテンツ
 * @param children - 読込中表示内容
 * @param loadHandler - 読込指示ハンドラ
 * @param showLoading - ローディング表示有無
 * @returns Includeコンポーネント
 */
const IncludeComponent: React.FC<Props> = ({
  id,
  required,
  loaded,
  showLoading,
  loading,
  failed,
  loadHandler,
  content
}) => {
  let body: React.ReactElement | undefined;
  const bodyRef = useRef<HTMLDivElement>(null);

  const [modalId, setModalId] = useState<string | null>();
  const [modalType, setModalType] = useState<string | null>();
  const [modalClose, setModalClose] = useState<string | null>();
  const [modalTitle, setModalTitle] = useState<string | null>();
  const [modalButton, setModalButton] = useState<string | null>();

  const { showDialog } = useDialog();

  const classnames = [style.include];

  if (loading) {
    classnames.push(style.includeLoading);
    body = (
      <div data-testid="loading" className={style.loading}>
        {showLoading && <InlineLoading />}
      </div>
    );
  }

  if (required && failed) {
    classnames.push(style.includeError);
    body = (
      <button type="button" className={style.reloadButton} onClick={loadHandler} data-testid="reloadButton">
        再読み込み
      </button>
    );
  }

  if (loaded && content) {
    body = (
      <div
        data-testid="body"
        className={style.includeBody}
        ref={bodyRef}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content || '') }}
      />
    );
  }

  useEffect(() => {
    const listener = async (e: MouseEvent) => {
      const target = parents(e.target as HTMLElement, '[data-modal-include-id]');

      if (!target) {
        return;
      }

      const modalIncludeId = target.getAttribute('data-modal-include-id');
      const modalIncludeType = target.getAttribute('data-modal-include-type');
      const modalIncludeClose = target.getAttribute('data-modal-include-close') || 'X';
      const modalIncludeTitle = target.getAttribute('data-modal-include-title');
      const modalIncludeButton = target.getAttribute('data-modal-include-button');

      setModalId(modalIncludeId);
      setModalType(modalIncludeType);
      setModalTitle(modalIncludeTitle);
      setModalClose(modalIncludeClose);
      setModalButton(modalIncludeButton);

      if (modalIncludeId) {
        const res = await showDialog(modalIncludeId);

        if (res === 'main') {
          consoleLog('respond main');
        }

        if (res === 'sub') {
          consoleLog('respond sub');
        }
      }
    };

    if (loaded && !failed && bodyRef.current) {
      bodyRef.current.addEventListener('click', listener);
    } else {
      bodyRef.current?.removeEventListener('click', listener);
    }
  }, [loaded, failed, showDialog]);

  return (
    <div className={cn(classnames)} data-testid="include" data-include-id={id}>
      {body}
      {modalId && modalType && (
        <Modal
          id={modalId}
          type={modalType}
          title={modalTitle}
          showCloseButton={modalClose === 'X'}
          buttonLabel={modalButton}
        />
      )}
    </div>
  );
};

export const Include = React.memo<Props>(IncludeComponent);
