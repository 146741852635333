import { AxiosResponse } from 'axios';
import { order } from 'ols-order-webservice-typescript-client';
import { IdentificationType } from '@/typings/IdentificationType';
import { IdentificationTargetType } from '@/typings/IdentificationTargetType';
import { consoleLog } from '@/utils/functions';
import { AuxiliaryDocumentType } from '@/typings/AuxiliaryDocumentType';

const { IdentificationApi } = order;

export class IdentificationApiService {
  private static instance: IdentificationApiService;

  private api: order.IdentificationApi;

  private constructor(config: order.Configuration) {
    this.api = new IdentificationApi(config);
  }

  public static getInstance(config: order.Configuration): IdentificationApiService {
    if (!this.instance) {
      this.instance = new IdentificationApiService(config);
    }

    return this.instance;
  }

  /**
   * BFF-IDE-001: 本人確認画像登録API
   * @param identificationTargetType -eKYC本人確認手続き申請のリクエストモデル
   * @param imageNo -
   * @param imageFile -
   * @param imageFileName -
   * @param identificationType -
   * @param auxiliaryDocumentType -
   * @returns
   */
  uploadIdentificationImages(
    identificationTargetType: IdentificationTargetType,
    imageNo: string,
    imageFile: unknown,
    imageFileName: string,
    identificationType: IdentificationType,
    auxiliaryDocumentType: AuxiliaryDocumentType | undefined
  ): Promise<order.IdentificationImagePostResponseV1> {
    consoleLog(
      'BFF-IDE-001: 本人確認画像登録API call',
      identificationTargetType,
      imageNo,
      imageFile,
      imageFileName,
      identificationType,
      auxiliaryDocumentType
    );
    return this.api
      .uploadIdentificationImageV1(
        identificationTargetType,
        imageNo,
        imageFile,
        imageFileName,
        identificationType,
        auxiliaryDocumentType
      )
      .then((res) => res.data);
  }

  /**
   * BFF-IDE-002: 本人確認画像取得API
   * @param identificationTargetType - 本人確認対象種別
   * @param imageNo - 本人確認画像No
   * @param identificationType - 本人確認書類種別
   * @param auxiliaryDocumentType - 補助書類種別
   * @returns 画像データ
   */
  getIdentificationImage(
    identificationTargetType: IdentificationTargetType,
    imageNo: string,
    identificationType: IdentificationType,
    auxiliaryDocumentType: AuxiliaryDocumentType
  ): Promise<AxiosResponse<ArrayBuffer>> {
    consoleLog(
      'BFF-IDE-002: 本人確認画像取得API call',
      identificationTargetType,
      imageNo,
      identificationType,
      auxiliaryDocumentType
    );
    return this.api
      .getUploadIdentificationImageV1(identificationTargetType, imageNo, identificationType, auxiliaryDocumentType, {
        responseType: 'arraybuffer'
      })
      .then((res) => res);
  }

  /**
   * BFF-IDE-003: eKYCを利用した本人確認手続き申請API
   * @param identificationEKycPostRequestV1 -eKYC本人確認手続き申請のリクエストモデル
   * @returns
   */
  createIdentification(
    identificationEKycPostRequestV1?: order.IdentificationEKycPostRequestV1
  ): Promise<order.IdentificationEKycPostResponseV1> {
    consoleLog('BFF-IDE-003: eKYCを利用した本人確認手続き申請API call', identificationEKycPostRequestV1);
    return this.api.createIdentificationV1(identificationEKycPostRequestV1).then((res) => res.data);
  }

  /**
   * BFF-IDE-004: OTP発行API
   * @returns
   */
  createOneTimePassword(
    CreateOneTimePasswordPostResponseV1?: order.CreateOneTimePasswordPostResponseV1
  ): Promise<order.CreateOneTimePasswordPostResponseV1> {
    consoleLog('BFF-IDE-004: OTP発行API call', CreateOneTimePasswordPostResponseV1);
    return this.api.createOneTimePasswordV1(CreateOneTimePasswordPostResponseV1).then((res) => res.data);
  }

  /**
   * BFF-IDE-005: OTPチェックAPI
   * @param oneTimePassword -ワンタイムパスワード
   * @returns
   */
  validOneTimePassword(oneTimePassword: string): Promise<order.ValidOneTimePasswordGetResponseV1> {
    consoleLog('BFF-IDE-004: OTPチェックAPI call', oneTimePassword);
    return this.api.validOneTimePasswordV1(oneTimePassword).then((res) => res.data);
  }
}
