import { order } from 'ols-order-webservice-typescript-client';
import { AppDispatch } from '@/store';
import {
  createLog as createLogAction,
  getUnavailableServiceStatuses as getUnavailableServiceStatusesAction,
  getAvailableEstimateOptions as getAvailableEstimateOptionsAction,
  getSystemSettings as getSystemSettingsAction,
  getMnpEffectiveness as getMnpEffectivenessAction,
  getUnavailableCharacters as getUnavailableCharactersAction,
  resetAvailableEstimateOptions as resetAvailableEstimateOptionsAction,
  resetMnpEffectiveness as resetMnpEffectivenessAction
} from './actions';

/**
 * BFF-SYS-002: ログ登録API 呼出しオペレータ
 * @param logPostRequestV1 - ログ情報
 * @returns
 */
export const createLog = (logPostRequestV1: order.LogPostRequestV1) => (
  dispatch: AppDispatch
): ReturnType<ReturnType<typeof createLogAction>> => dispatch(createLogAction({ logPostRequestV1 }));

/**
 * BFF-SYS-003: マスク対象取得API
 * @returns 各機能をマスクして利用不可とすべき対象を返却します。
 */
export const getUnavailableServiceStatuses = () => (
  dispatch: AppDispatch
): ReturnType<ReturnType<typeof getUnavailableServiceStatusesAction>> =>
  dispatch(getUnavailableServiceStatusesAction());

/**
 * BFF-SYS-004: 見積もり選択可能オプション取得API
 * @param auSmartpassState - auスマートパス加入状況
 * @param applemusicState - AppleMusic加入状況
 * @param youtubePremiumState - YouTubePremium加入状況
 * @param auBicycleSupportState - au自転車サポート加入状況
 * @param auWellnessState - auウェルネス加入状況
 * @param allstarPack1Available - AllstarPack1フラグ
 * @returns
 */
export const getAvailableEstimateOptions = (
  auSmartpassState: ' ' | '1' | '2' | 'E',
  applemusicState: ' ' | 'Y' | 'E',
  youtubePremiumState: ' ' | 'Y' | 'E',
  auBicycleSupportState: ' ' | 'Y' | 'E',
  auWellnessState: ' ' | 'Y' | 'E',
  allstarPack1Available: '0' | '1'
) => (dispatch: AppDispatch): ReturnType<ReturnType<typeof getAvailableEstimateOptionsAction>> =>
  dispatch(
    getAvailableEstimateOptionsAction({
      auSmartpassState,
      applemusicState,
      youtubePremiumState,
      auBicycleSupportState,
      auWellnessState,
      allstarPack1Available
    })
  );

/**
 * BFF-SYS-004: 見積もり選択可能オプション取得API 初期化
 * @returns
 */
export const resetAvailableEstimateOptions = () => (
  dispatch: AppDispatch
): ReturnType<typeof resetAvailableEstimateOptionsAction> => dispatch(resetAvailableEstimateOptionsAction());

/**
 * BFF-SYS-005: システム設定情報取得API
 * @returns
 */
export const getSystemSettings = () => (
  dispatch: AppDispatch
): ReturnType<ReturnType<typeof getSystemSettingsAction>> => dispatch(getSystemSettingsAction());

/**
 * BFF-SYS-006: MNP予約番号有効性確認API 初期化
 * @returns
 */
export const resetMnpEffectiveness = () => (dispatch: AppDispatch): ReturnType<typeof resetMnpEffectivenessAction> =>
  dispatch(resetMnpEffectivenessAction());

/**
 * BFF-SYS-006: MNP予約番号有効性確認API
 * @returns
 */
export const getMnpEffectiveness = (mnpReservationNo: string, mnpLimitDate: string) => (
  dispatch: AppDispatch
): ReturnType<ReturnType<typeof getMnpEffectivenessAction>> =>
  dispatch(
    getMnpEffectivenessAction({
      mnpReservationNo,
      mnpLimitDate
    })
  );

/**
 * BFF-SYS-007: 利用不可文字確認API
 * @returns
 */
export const getUnavailableCharacters = (
  unavailableCharactersPostRequestV1: order.UnavailableCharactersPostRequestV1
) => (dispatch: AppDispatch): ReturnType<ReturnType<typeof getUnavailableCharactersAction>> =>
  dispatch(
    getUnavailableCharactersAction({
      unavailableCharactersPostRequestV1
    })
  );
