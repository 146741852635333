import { AppDispatch } from '@/store';
import { AreaCode } from '@/typings/AreaCode';
import { ContractBranchCode } from '@/typings/ContractBranchCode';
import { MobileDeviceJudgment } from '@/typings/MobileDeviceJudgment';
import { NetworkType } from '@/typings/NetworkType';
import {
  getSalesProduct as getSalesProductAction,
  getCandidateAfterSaleServices as getCandidateAfterSaleServicesAction,
  getInstallmentPriceByProductStockCode as getInstallmentPriceByProductStockCodeAction,
  getOptionalProducts as getOptionalProductsActon,
  getOptionalProductDetail as getOptionalProductDetailAction,
  getDecisionDeviceChangeIncrease as getDecisionDeviceChangeIncreaseAction,
  resetDecisionDeviceChangeIncreaseServiceState,
  getCandidateAfterSaleServicesForAppleWatch as getCandidateAfterSaleServicesForAppleWatchAction,
  getDeliveryAvailableDate as getDeliveryAvailableDateAction,
  resetDeliveryAvailableDateServiceState
} from './actions';

/**
 * BFF-PRD-001: 販売商品情報取得API
 * @param productStockCode - 取得したい商品の商品コードを設定します。
 * @param deviceType - 端末種類を設定します。
 * @returns
 */
export const getSalesProduct = (productStockCode: string, deviceType: '1' | '2') => (
  dispatch: AppDispatch
): ReturnType<ReturnType<typeof getSalesProductAction>> =>
  dispatch(
    getSalesProductAction({
      productStockCode,
      deviceType
    })
  );

/**
 * BFF-PRD-002: 保証・サポートサービス取得API
 * @param productStockCode - 注文商品の商品コードを指定します。
 * @param tCategoryOptionCodes - ユーザーの契約関連情報に含まれるT系オプションコード一覧を指定します。
 * @param contractType - 契約種別を設定します。
 * @param auContractId - au契約IDを設定します。
 * @param acrossAftercareCategory - ACROSS1の故障紛失サポート区分を指定します。
 */
export const getCandidateAfterSaleServices = (
  productStockCode: string,
  tCategoryOptionCodes: Array<string>,
  contractType: '1' | '2' | '3' | '4' | '5',
  auContractId: string,
  acrossApplecareCode?: string,
  acrossAftercareCode?: string,
  acrossAftercareCategory?: string,
  acrossUseSupportCode?: string,
  leonApplecareCode?: string,
  leonDeviceCompensationCode?: string,
  uqUserStatus?: '0' | '1',
  simOnlyFlag?: boolean
) => (dispatch: AppDispatch): ReturnType<ReturnType<typeof getCandidateAfterSaleServicesAction>> =>
  dispatch(
    getCandidateAfterSaleServicesAction({
      productStockCode,
      tCategoryOptionCodes,
      contractType,
      auContractId,
      acrossApplecareCode,
      acrossAftercareCode,
      acrossAftercareCategory,
      acrossUseSupportCode,
      leonApplecareCode,
      leonDeviceCompensationCode,
      uqUserStatus,
      simOnlyFlag
    })
  );

/**
 * BFF-PRD-003: 端末割賦代金マスタAPI
 * @param productStockCode - 取得したい商品の商品コードを設定します。
 * @param companyType - 会社区分
 * @returns
 */
export const getInstallmentPriceByProductStockCode = (
  productStockCode: string,
  contractType: '1' | '2' | '3' | '4' | '5',
  areaCodeCode?: AreaCode,
  contractBranchCode?: ContractBranchCode,
  mvnoCode?: string,
  agencyCode?: string,
  kddioct?: string
) => (dispatch: AppDispatch): ReturnType<ReturnType<typeof getInstallmentPriceByProductStockCodeAction>> =>
  dispatch(
    getInstallmentPriceByProductStockCodeAction({
      productStockCode,
      contractType,
      areaCodeCode,
      contractBranchCode,
      mvnoCode,
      agencyCode,
      kddioct
    })
  );

/**
 * BFF-PRD-004: アクセサリー一覧取得API
 * @param productStockCode - 注文商品の商品コードを指定します。
 * @param contractType - 契約種別を設定します。
 * @param salesMobileDeviceCode - 販売移動機商品コード
 * @param orderNo - 注文番号
 * @param mobileDeviceJudgment - 移動機種判定 アカウント取得API（BFF-USR-001）から取得した値を指定してください。
 * @param auPhoneUsePeriod - au電話機利用期間 アカウント取得API（BFF-USR-001）から取得した値を指定してください。
 * @returns
 */
export const getOptionalProducts = (
  productStockCode: string,
  contractType: '1' | '2' | '3' | '4' | '5',
  salesMobileDeviceCode?: string,
  orderNo?: string,
  mobileDeviceJudgment?: MobileDeviceJudgment,
  auPhoneUsePeriod?: string
) => (dispatch: AppDispatch): ReturnType<ReturnType<typeof getOptionalProductsActon>> =>
  dispatch(
    getOptionalProductsActon({
      productStockCode,
      contractType,
      salesMobileDeviceCode,
      orderNo,
      mobileDeviceJudgment,
      auPhoneUsePeriod
    })
  );

/**
 * BFF-PRD-005: アクセサリー詳細情報取得API
 * @param productStockCode - 取得したい商品の商品コードを設定します。
 * @returns
 */
export const getOptionalProductDetail = (productStockCode: string) => (
  dispatch: AppDispatch
): ReturnType<ReturnType<typeof getOptionalProductDetailAction>> =>
  dispatch(
    getOptionalProductDetailAction({
      productStockCode
    })
  );

/**
 * BFF-PRD-006: 機種変更区分取得API
 * @param networkType - 販売商品情報に含まれるネットワーク区分を指定します。
 * @param romIcCardDeviceCategory - 注文商品のROM機/ICカードを指定します。
 * @param eSimFlag - eSIMフラグを指定します。
 * @param auPhoneUsePeriod - au電話機利用期間
 * @param densyu - 電種を指定します。
 * @param mobileDeviceJudgment - 移動機種判定
 * @param productAttribute - 商品属性を指定します。
 * @param microSimType - microSIM区分を指定します。
 * @param singleDeviceType -シングル端末種類
 * @param winIsSeriesDeviceCategory - WIN/ISシリーズ/iPhone
 * @returns
 */
export const getDecisionDeviceChangeIncrease = (
  networkType: NetworkType,
  romIcCardDeviceCategory: '5' | '6' | '7' | '8' | '9' | '10' | '12' | '13' | '14' | '15',
  eSimFlag: boolean,
  auPhoneUsePeriod: string,
  densyu?: string,
  mobileDeviceJudgment?: MobileDeviceJudgment,
  productAttribute?: string,
  microSim?: '0' | '1',
  singleDeviceType?: '1' | '2' | '3' | '4',
  winIsSeriesDeviceCategory?: '1' | '2' | '3' | '4' | '5'
) => (dispatch: AppDispatch): ReturnType<ReturnType<typeof getDecisionDeviceChangeIncreaseAction>> =>
  dispatch(
    getDecisionDeviceChangeIncreaseAction({
      networkType,
      romIcCardDeviceCategory,
      eSimFlag,
      auPhoneUsePeriod,
      densyu,
      mobileDeviceJudgment,
      productAttribute,
      microSim,
      singleDeviceType,
      winIsSeriesDeviceCategory
    })
  );

/**
 * BFF-PRD-006: 機種変更区分取得API初期化
 * @returns
 */
export const resetDecisionDeviceChangeIncrease = () => (
  dispatch: AppDispatch
): ReturnType<typeof resetDecisionDeviceChangeIncreaseServiceState> =>
  dispatch(resetDecisionDeviceChangeIncreaseServiceState());

/**
 * BFF-PRD-007: AppleWatch用保証・サポートサービス取得API
 * @param productStockCode - 注文商品の商品コードを指定します。
 * @param appleWatchProductStockCode - AppleWatch用AppleCareの情報を取得したいAppleWatchの商品コードを指定します。
 * @returns
 */
export const getCandidateAfterSaleServicesForAppleWatch = (
  productStockCode: string,
  appleWatchProductStockCode: string | undefined,
  contractType: '1' | '2' | '3' | '4' | '5' | '8' | '9'
) => (dispatch: AppDispatch): ReturnType<ReturnType<typeof getCandidateAfterSaleServicesForAppleWatchAction>> =>
  dispatch(
    getCandidateAfterSaleServicesForAppleWatchAction({
      productStockCode,
      appleWatchProductStockCode,
      contractType
    })
  );

/**
 * BFF-PRD-008: お届け可能日取得API
 * @param sameDayDeliveryAvailable - 即日配送可否
 * @param postalCode - 郵便番号
 * @param contractType - 契約種別を設定します。
 * @param productStockCode - 注文商品の商品コードを指定します。
 * @param optionGoodsList - OP品の商品コード
 * @returns
 */
export const getDeliveryAvailableDate = (
  productStockCode: string,
  sameDayDeliveryAvailable: '1' | '0',
  postalCode: string,
  contractType?: '1' | '2' | '3' | '4' | '5' | '8' | '9',
  optionGoodsList?: Array<string>
) => (dispatch: AppDispatch): ReturnType<ReturnType<typeof getDeliveryAvailableDateAction>> =>
  dispatch(
    getDeliveryAvailableDateAction({
      contractType,
      productStockCode,
      sameDayDeliveryAvailable,
      optionGoodsList,
      postalCode
    })
  );

/**
 * BFF-PRD-008: お届け可能日取得API初期化
 * @returns
 */
export const resetDeliveryAvailableDateService = () => (
  dispatch: AppDispatch
): ReturnType<typeof resetDeliveryAvailableDateServiceState> => dispatch(resetDeliveryAvailableDateServiceState());
