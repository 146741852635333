import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { order } from 'ols-order-webservice-typescript-client';
import { AxiosError } from 'axios';
import { AuPayCardPaymentDiscounts } from '@/services/auPayCardPaymentDiscountsApi';
import { apiConfigProvider } from '@/services/http/apiConfig';
import { RootState } from '@/store';
import { ServiceState } from '@/typings/ServiceState';
import { ErrorInfo } from '@/typings/ErrorInfo';
import { handleAxiosError } from '@/services/handleAxiosError';

const initialState: ServiceState<order.AuPayCardDiscountPaymentsGetResponseV1> = {
  loaded: false,
  failed: false,
  response: null,
  error: null
};

/**
 * BFF-CHP-005: 選択可能auPayカードお支払い割情報取得API
 */
export const getAuPayCardPaymentDiscountsV1 = createAsyncThunk<
  // Api正常時レスポンスの型
  order.AuPayCardDiscountPaymentsGetResponseV1,
  // Apiリクエストパラメータの型
  {
    dataPlanCode: string;
    paymentMethodAuPayCard?: order.PaymentMethodAuPayCardBffEnum | undefined;
  },
  // Api異常時レスポンスの型
  { rejectValue: ErrorInfo }
>(
  'auPayCardPaymentDiscounts/getAuPayCardPaymentDiscountsV1',
  async ({ dataPlanCode, paymentMethodAuPayCard }, { rejectWithValue }) => {
    const config = apiConfigProvider();
    try {
      return await AuPayCardPaymentDiscounts.getInstance(config).getAuPayCardPaymentDiscounts(
        dataPlanCode,
        paymentMethodAuPayCard
      );
    } catch (err) {
      const errInfo: AxiosError<order.ApiErrors> = err;
      return rejectWithValue(handleAxiosError(errInfo, 'BFF-CHP-005'));
    }
  }
);

const slice = createSlice({
  name: 'auPayCardPaymentDiscounts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAuPayCardPaymentDiscountsV1.pending, () => initialState)
      .addCase(getAuPayCardPaymentDiscountsV1.rejected, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: true,
          response: null,
          error: action.payload || null
        }
      }))
      .addCase(getAuPayCardPaymentDiscountsV1.fulfilled, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: false,
          response: action.payload,
          error: null
        }
      }));
  }
});

export const getAuPayCardPaymentDiscountsV1Result = (
  state: RootState
): RootState['entity']['auPayCardPaymentDiscountsApi'] => state.entity.auPayCardPaymentDiscountsApi;

export const { actions, reducer } = slice;
