/**
 * 月々のお支払い方法を表すコード値
 */

export type MethodOfPayment = '0' | '1';

/**
 * 月々のお支払い方法Enum
 */
export const MethodOfPayments = {
  CREDIT_CARD: '0',
  ACCOUNT_TRANSFER: '1'
} as const;

/**
 * 月々のお支払い方法ラベル
 */
export const MethodOfPaymentLabels = {
  '0': 'クレジットカード',
  '1': '口座振替'
} as const;
