import { createSlice, PayloadAction } from '@reduxjs/toolkit';

/**
 * ワンタイムパスワード状態
 */
export type OnetimePasswordStatusState = {
  /**
   * 発行回数
   */
  issueCount: number;
  /**
   * エラー回数
   */
  errorCount: number;
};

const initialState: OnetimePasswordStatusState = {
  issueCount: 0,
  errorCount: 0
};

const slice = createSlice({
  name: 'onetimePasswordStatus',
  initialState,
  reducers: {
    setOnetimePasswordStatus: (
      state: OnetimePasswordStatusState,
      action: PayloadAction<Partial<OnetimePasswordStatusState>>
    ) => ({
      ...state,
      ...action.payload
    }),
    resetOnetimePasswordStatus: (state: OnetimePasswordStatusState) => ({
      ...state,
      ...initialState
    }),
    //  発行回数カウントアップ
    incrementIssueCount: (state: OnetimePasswordStatusState) => {
      const count = state.issueCount;
      return {
        ...state,
        issueCount: count + 1
      };
    },

    //  エラー回数カウントアップ
    incrementErrorCount: (state: OnetimePasswordStatusState) => {
      const count = state.errorCount;
      return {
        ...state,
        errorCount: count + 1
      };
    },
    //  エラー回数リセット
    resetErrorCounts: (state: OnetimePasswordStatusState) => ({
      ...state,
      errorCount: 0
    })
  }
});

export const { actions, reducer } = slice;
