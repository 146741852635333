import { order } from 'ols-order-webservice-typescript-client';
import { consoleLog } from '@/utils/functions';

const { ChargesPlanApi } = order;

export class AuPayCardPaymentDiscounts {
  private static instance: AuPayCardPaymentDiscounts;

  private api: order.ChargesPlanApi;

  private constructor(config: order.Configuration) {
    this.api = new ChargesPlanApi(config);
  }

  public static getInstance(config: order.Configuration): AuPayCardPaymentDiscounts {
    if (!this.instance) {
      this.instance = new AuPayCardPaymentDiscounts(config);
    }

    return this.instance;
  }

  /**
   * BFF-CHP-005: 選択可能auPayカードお支払い割情報取得API
   * @returns
   */
  getAuPayCardPaymentDiscounts(
    dataPlanCode: string,
    paymentMethodAuPayCard?: order.PaymentMethodAuPayCardBffEnum | undefined
  ): Promise<order.AuPayCardDiscountPaymentsGetResponseV1> {
    consoleLog('BFF-CHP-005: 選択可能auPayカードお支払い割情報取得API call');
    return this.api.getAuPayCardPaymentDiscountsV1(dataPlanCode, paymentMethodAuPayCard).then((res) => res.data);
  }
}
