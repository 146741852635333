import { AppDispatch } from '@/store';
import { SystemState } from './systemSlice';
import { setSystem, resetSystem, incrementPaymentRetryCountAction, resetPaymentRetryCountAction } from './actions';

/**
 * システム情報更新オペレータ
 * @param state - ステート
 * @returns
 */
export const set = (state: Partial<SystemState>) => (dispatch: AppDispatch): ReturnType<typeof setSystem> =>
  dispatch(setSystem(state));

/**
 * システム情報初期化オペレータ
 * @returns
 */
export const reset = () => (dispatch: AppDispatch): ReturnType<typeof resetSystem> => dispatch(resetSystem());

/**
 * 決済リトライ回数初期化オペレータ
 * @param state - ステート
 * @returns
 */
export const resetPaymentRetryCount = () => (dispatch: AppDispatch): ReturnType<typeof resetPaymentRetryCountAction> =>
  dispatch(resetPaymentRetryCountAction());

/**
 * 決済リトライ回数初期化インクリメント更新オペレータ
 * @param state - ステート
 * @returns
 */
export const incrementPaymentRetryCount = () => (
  dispatch: AppDispatch
): ReturnType<typeof incrementPaymentRetryCountAction> => dispatch(incrementPaymentRetryCountAction());
