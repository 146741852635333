export const {
  REACT_APP_ENV = '',
  NODE_ENV = 'production',
  /**
   * ブランドID
   */
  REACT_APP_BRAND_ID = 'au',
  /**
   * INCLUDEスタブモード
   */
  REACT_APP_INCLUDE_STUB_MODE,
  /**
   * コンソールログ出力有効状態
   */
  REACT_APP_ENABLE_CONSOLE_LOG,
  /**
   * トスアップローカルモード
   */
  REACT_APP_TOS_UP_LOCAL_MODE,
  /**
   * BFF API ベースパス
   */
  REACT_APP_BFF_BASE_PATH,
  /**
   * ご注文履歴 URL
   */
  REACT_APP_ORDER_HISTORY_URL,
  /**
   * COCOA MyauログアウトURL
   */
  REACT_APP_LOGOUT_URL,
  /**
   * カート内商品削除URL取得
   */
  REACT_APP_DELETE_CART_URL,
  /**
   * ご契約種別選択画面にて「←カートへ戻る」を押下した際のURL
   */
  REACT_APP_CART_SCREEN_URL,
  /**
   * ご契約種別選択画面にて「←TOPへ戻る」を押下した際のURL
   */
  REACT_APP_TOP_SCREEN_URL,
  /**
   * ご契約種別選択画面にて「オンラインショップへ戻る」※番号移行を押下した際のURL
   */
  REACT_APP_UQ_SILK_SCREEN_URL,
  /**
   * ご契約種別選択画面にて「←TOPへ戻る」（ウォッチナンバー受付）を押下した際のURL
   */
  REACT_APP_WATCHNUMBER_URL,
  /**
   * ご契約種別選択画面にて「トップへ戻る」を押下した際のURL
   */
  REACT_APP_NEW_OPEN_TOP_SCREEN_URL,
  /**
   * 注文履歴URL
   */
  REACT_APP_ORDER_URL,
  /**
   * Web予約URL
   */
  REACT_APP_ONLINE_YOYAKU,
  REACT_APP_ONLINE_YOYAKU_NOTOP,
  /**
   * 汎用エラーリンク先URL
   */
  REACT_APP_KANNI_ORD_USE_IMPSBL_ERROR,
  REACT_APP_OLS_USE_IMPSBL_ERROR,
  REACT_APP_ORD_IMPSBL_ERROR,
  REACT_APP_UQ_KAZOKUWARI_ERROR,
  /**
   * 汎用エラーフッターリンクURL
   */
  REACT_APP_ERROR_DISP_FOOTER_LINK_URL1,
  REACT_APP_ERROR_DISP_FOOTER_LINK_URL2,
  REACT_APP_ERROR_DISP_FOOTER_LINK_URL3,
  REACT_APP_ERROR_DISP_FOOTER_LINK_URL4,
  REACT_APP_ERROR_DISP_FOOTER_LINK_URL5,
  REACT_APP_ERROR_DISP_FOOTER_LINK_URL6,
  REACT_APP_ERROR_DISP_FOOTER_LINK_URL7,
  REACT_APP_ERROR_DISP_FOOTER_LINK_URL8,
  /**
   * 進むボタン押下時 URL
   */
  REACT_APP_GO_NEXT_URL,

  /**
   * 契約種別画面(FP) URL
   */
  REACT_APP_FP_CONTRACT_TYPE_URL,

  /**
   * 〇〇を選び直すのリンク先URL
   */
  REACT_APP_RESELECT_NEW_URL,
  REACT_APP_RESELECT_SIM_URL,
  REACT_APP_RESELECT_USED_URL,
  REACT_APP_RESELECT_UQ_SILK_URL,
  REACT_APP_RESELECT_WATCHNUMBER,

  /**
   * GMOトークン ショップID
   */
  REACT_APP_GMO_PAYMENT_SHOP_ID,

  /**
   * トークン・会社情報取得API requestUri
   */
  REACT_APP_TOKEN_CORP_INFO_REQUEST_URI,

  /**
   * トークン・会社情報取得API 加盟店ID
   */
  REACT_APP_TOKEN_CORP_INFO_MEMBER_SHOP_ID,

  /**
   * トークン・会社情報取得API APIキー
   */
  REACT_APP_TOKEN_CORP_INFO_MEMBER_API_KEY,

  /**
   * トークン・会社情報取得API システムID
   */
  REACT_APP_TOKEN_CORP_INFO_SYSTEM_ID,

  /**
   * 画像格納ファイルパス
   */
  REACT_APP_GOODS_IMG_FILE_STORAGE,

  /*
   * GTM
   */
  REACT_APP_GTM_ID,
  /**
   * GMOトークン化サービスtoken
   */
  REACT_APP_GMO_3DS_SCRIPT_SOURCE,

  /**
   * GMOトークン化サービスsnare
   */
  REACT_APP_GMO_SNARE_SCRIPT_SOURCE,

  /**
   * 3Dセキュア認証結果受取API
   */
  REACT_APP_GMO_IDENTIFICATION_RESULT,

  /**
   * リクエストへのアプリケーションリビジョン
   */
  REACT_APP_REVISION,
  /**
   * 汎用エラー画面logo設置URL
   */
  REACT_APP_AU_TOP_URL,
  /**
   * オープンかえトクau電話番号有ログインURL
   */
  REACT_APP_ESIM_LOGIN_AU_ID_YES_ERROR,
  /**
   * オープンかえトクau電話番号有ログインtargetURL
   */
  REACT_APP_ESIM_LOGIN_AU_ID_YES_ERROR_TARGET,
  /**
   * eSIMオープンauID作成URL
   */
  REACT_APP_ESIM_AU_ID_NEW_CREATE,
  /**
   * 見積保存機能非表示モード
   */
  REACT_APP_DISABLE_ESTIMATE_SAVE,
  /**
   * ワンタイムパスワード入力可能最大回数
   */
  REACT_APP_ONE_TIME_PASSWORD_INPUT_MAX,
  /**
   * ワンタイムパスワード発行可能最大回数
   */
  REACT_APP_ONE_TIME_PASSWORD_REISSUE_MAX,
  /**
   * AppleWatch同時購入上限数
   */
  REACT_APP_APPLE_WATCH_SIMULTANEOUS_PURCHASE_MAX_NUMBER,
  /**
   * MNPワンストップ結果受取APIのURL
   */
  REACT_APP_MNP_ONESTOP_RESULT,
  /**
   * アフィリエイト用Cookieのドメイン
   */
  REACT_APP_DOMAIN,
  /**
   * トスアップ用のCookieドメイン
   */
  REACT_APP_TOSSUP_DOMAIN,
  /**
   * 代理店コード用のCookieドメイン
   */
  REACT_APP_AG_CD_DOMAIN,
  /**
   * 動作環境・Cookie情報の利用、広告配信等についてのURL
   */
  REACT_APP_TERMS_REQUIREMENTS_URL,
  /**
   * AOITORIのTOP画面へ遷移するURL
   */
  REACT_APP_SELF_AU_URL,
  /**
   * お問い合わせのURL
   */
  REACT_APP_INQUIRY_AU_URL,
  /**
   * auFSのURL
   */
  REACT_APP_AU_PAY_CARD_APPLY_INTENTION_URL,
  /**
   * auじぶん銀行のURL
   */
  REACT_APP_AU_JIBUN_BANK_APPLY_INTENTION_URL,
  /**
   * MyauのURL
   */
  REACT_APP_PAYMENT_METHOD_CHANGE_INTENTION_URL,
  /**
   * au PAY残高チャージのURL
   */
  REACT_APP_AUPAY_BALANCE_CHARGE,
  /**
   * ユーザー認証要求のベースURL
   */
  REACT_APP_FRONT_BASE_PATH,
  /**
   * ユーザー認証要求のURL
   */
  REACT_APP_USER_PERMIT_BRIDGE
} = process.env;
