/**
 * ご利用SIM
 */
export type UseSim = '1' | '2';
export const UseSims = {
  SimIcCard: '1',
  ESim: '2'
} as const;
export const UseSimLabels = {
  '1': 'SIMカード（au ICカード）',
  '2': 'eSIM'
} as const;

const isUseSims = (code: string): code is UseSim => code in UseSimLabels;

export const getUseSimLabel = (code: string): string => {
  if (isUseSims(code)) {
    return UseSimLabels[code];
  }
  return '';
};
