import { AppDispatch } from '@/store';
import { ErrorState } from './errorSlice';
import { setError, resetError } from './actions';

/**
 * エラー情報更新オペレータ
 * @param errorState - エラー情報
 * @returns
 */
export const set = (errorState: Partial<ErrorState>) => (dispatch: AppDispatch): ReturnType<typeof setError> =>
  dispatch(setError(errorState));

/**
 * エラー情報初期化オペレータ
 * @returns
 */
export const reset = () => (dispatch: AppDispatch): ReturnType<typeof resetError> => dispatch(resetError());
