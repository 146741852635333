import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { order } from 'ols-order-webservice-typescript-client';
import { UserApiService } from '@/services/userApiService';
import { apiConfigProvider } from '@/services/http/apiConfig';
import { handleAxiosError } from '@/services/handleAxiosError';
import { ErrorInfo } from '@/typings/ErrorInfo';

/**
 * BFF-USR-001: アカウント取得API
 */
export const getAccount = createAsyncThunk<
  // Api正常時レスポンスの型
  order.AccountGetResponseV1,
  // Apiリクエストパラメータの型
  void,
  // Api異常時レスポンスの型
  { rejectValue: ErrorInfo }
>('userService/getAccount', async (_, { rejectWithValue }) => {
  const config = apiConfigProvider();
  try {
    return await UserApiService.getInstance(config).getAccount();
  } catch (err) {
    const errInfo: AxiosError<order.ApiErrors> = err;
    return rejectWithValue(handleAxiosError(errInfo, 'BFF-USR-001'));
  }
});

/**
 * BFF-USR-002: ポイント取得API
 */
export const getPoint = createAsyncThunk<
  // Api正常時レスポンスの型
  order.PointsGetResponseV1,
  // Apiリクエストパラメータの型
  {
    auContractId: string;
  },
  // Api異常時レスポンスの型
  { rejectValue: ErrorInfo }
>('userService/getPoint', async ({ auContractId }, { rejectWithValue }) => {
  const config = apiConfigProvider();
  try {
    return await UserApiService.getInstance(config).getPoint(auContractId);
  } catch (err) {
    const errInfo: AxiosError<order.ApiErrors> = err;
    return rejectWithValue(handleAxiosError(errInfo, 'BFF-USR-002'));
  }
});

/**
 * BFF-USR-003: 契約取得API
 */
export const getContract = createAsyncThunk<
  // Api正常時レスポンスの型
  order.ContractGetResponseV1,
  // Apiリクエストパラメータの型
  {
    auContractId: string;
    tCategoryOptionCodes: Array<string>;
    birthday?: string;
    auPhoneNo?: string;
  },
  // Api異常時レスポンスの型
  { rejectValue: ErrorInfo }
>(
  'userService/getContract',
  async ({ auContractId, tCategoryOptionCodes, birthday, auPhoneNo }, { rejectWithValue }) => {
    const config = apiConfigProvider();
    try {
      return await UserApiService.getInstance(config).getContract(
        auContractId,
        tCategoryOptionCodes,
        birthday,
        auPhoneNo
      );
    } catch (err) {
      const errInfo: AxiosError<order.ApiErrors> = err;
      return rejectWithValue(handleAxiosError(errInfo, 'BFF-USR-003'));
    }
  }
);

/**
 * BFF-USR-004: アカウント追加情報取得API
 */
export const getAccountComplements = createAsyncThunk<
  // Api正常時レスポンスの型
  order.AccountComplementsGetResponseV1,
  // Apiリクエストパラメータの型
  void,
  // Api異常時レスポンスの型
  { rejectValue: ErrorInfo }
>('userService/getAccountComplements', async (_, { rejectWithValue }) => {
  const config = apiConfigProvider();
  try {
    return await UserApiService.getInstance(config).getAccountComplements();
  } catch (err) {
    const errInfo: AxiosError<order.ApiErrors> = err;
    return rejectWithValue(handleAxiosError(errInfo, 'BFF-USR-004'));
  }
});

/**
 * BFF-USR-005: auかんたん決済残高情報取得API
 */
export const getAuEasyPayments = createAsyncThunk<
  // Api正常時レスポンスの型
  order.AuPaySimplePaymentGetResponseV1,
  // Apiリクエストパラメータの型
  {
    processType: '1' | '2' | '3';
  },
  // Api異常時レスポンスの型
  { rejectValue: ErrorInfo }
>('userService/getAuEasyPayments', async ({ processType }, { rejectWithValue }) => {
  const config = apiConfigProvider();
  try {
    return await UserApiService.getInstance(config).getAuEasyPayments(processType);
  } catch (err) {
    const errInfo: AxiosError<order.ApiErrors> = err;
    return rejectWithValue(handleAxiosError(errInfo, 'BFF-USR-005'));
  }
});
