/*
 * 遷移先URL
 */
export type ScreenUrlPath =
  | '/'
  | '/app/POD0200.seam'
  | '/start'
  | '/select-contract-type'
  | '/select-tel-number'
  | '/select-tel-number/callback'
  | '/open-au-id-auth/callback'
  | '/select-mno'
  | '/select-mno/callback'
  | '/select-buy'
  | '/select-accessory'
  | '/select-accessory/'
  | '/order'
  | '/confirm'
  | '/account-transfer'
  | '/account-transfer/callback'
  | '/easy-payment'
  | '/easy-payment-error'
  | '/easy-payment/callback'
  | '/payment'
  | '/payment/auth'
  | '/payment/auth/callback'
  | '/complete'
  | '/error/'
  | '/system-error';
export const ScreenUrlPaths = {
  Root: '/',
  EntryPointOld: '/app/POD0200.seam',
  EntryPoint: '/start',
  ContractType: '/select-contract-type',
  PhoneNumberSelection: '/select-tel-number',
  PhoneNumberSelectionCallback: '/select-tel-number/callback',
  openAuIdAuth: '/open-au-id-auth/callback',
  MnpCompanySelection: '/select-mno',
  MnpCompanySelectionCallback: '/select-mno/callback',
  ProcedureSelection: '/select-buy',
  CategorySelection: '/select-accessory',
  AccessorySelection: '/select-accessory/',
  Estimate: '/order',
  Confirming: '/confirm',
  AccountTransfer: '/account-transfer',
  AccountTransferCallBack: '/account-transfer/callback',
  AuEasyPayment: '/easy-payment',
  AuEasyPaymentError: '/easy-payment-error',
  AuEasyPaymentCallBack: '/easy-payment/callback',
  PaymentInformationInput: '/payment',
  PaymentInformationAuth: '/payment/auth',
  PaymentInformationAuthCallback: '/payment/auth/callback',
  Completed: '/complete',
  BusinessError: '/error/',
  SystemError: '/system-error'
} as const;
