import { order } from 'ols-order-webservice-typescript-client';
import { SexType } from '@/typings/Gender';
import { UsePersonSameValues } from '@/typings/UsePersonSameValue';
import { OldUqContractorCategory } from '@/typings/OldUqContractorCategory';
import { divideName } from './divideName';
import { hanToZenOrEmpty } from './char';

interface ContractInfo {
  isOldUqUser: boolean;
  contractorSei: string;
  contractorMei: string;
  contractorSeiMei: string;
  contractorSeiMeiFull: string;
  contractorSeiKana: string;
  contractorMeiKana: string;
  contractorSeiMeiKana: string;
  contractorSeiMeiKanaFull: string;
  telephoneNumber: string;
  contractorTelephoneNumber: string;
  contractorBirthday: string;
  usePersonSame: string;
  // 以下任意項目
  userBirthday?: string;
  contractorPost?: string;
  customerAddress?: string;
  contractorGender?: SexType;
  contractorEmailAddress?: string;
  mvnoCode?: string;
  companyType?: order.ConSsyBffEnum;
  usePersonLastName?: string;
  usePersonFirstName?: string;
  usePersonFullName?: string;
  usePersonLastNameKana?: string;
  usePersonFirstNameKana?: string;
  usePersonFullNameKana?: string;
  usePersonBirthday?: string;
  billingPostalCode?: string;
  contractorCategory?: OldUqContractorCategory;
  smartPhoneStartPlanAvailable?: undefined;
}

const defaultValues = (): ContractInfo => ({
  isOldUqUser: false,
  contractorSei: '',
  contractorMei: '',
  contractorSeiMei: '',
  contractorSeiMeiFull: '',
  contractorSeiKana: '',
  contractorMeiKana: '',
  contractorSeiMeiKana: '',
  contractorSeiMeiKanaFull: '',
  telephoneNumber: '',
  contractorTelephoneNumber: '',
  contractorBirthday: '',
  usePersonSame: '',
  // 以下任意項目
  userBirthday: undefined,
  contractorPost: undefined,
  customerAddress: undefined,
  contractorEmailAddress: undefined,
  contractorGender: undefined,
  mvnoCode: undefined,
  companyType: undefined,
  usePersonLastName: undefined,
  usePersonFirstName: undefined,
  usePersonFullName: undefined,
  usePersonLastNameKana: undefined,
  usePersonFirstNameKana: undefined,
  usePersonFullNameKana: undefined,
  usePersonBirthday: undefined,
  billingPostalCode: undefined,
  smartPhoneStartPlanAvailable: undefined
});
const NAME_FULL_SPACE = '　';

/**
 * 顧客情報取得
 * @remarks
 * 契約種別とアカウント情報取得APIの返却値から顧客情報の取得元を判別し返却します。
 * LEON⇒stateへの設定時に変換・編集が必要ある場合はここで変換・編集を行う。
 * @param isNumberMigration - 番号移行判定フラグ
 * @param accountInfo - BFF-USR-001:アカウント取得APIレスポンス
 * @param telNum - 選択電話番号
 */
export const getCustomerInformation = (
  isNumberMigration: boolean,
  accountInfo: order.AccountGetResponseV1 | null,
  telNum?: string
): ContractInfo => {
  const onlineShopSubscriberInfo = accountInfo?.user?.onlineShopSubscriberList?.find(
    (v) => v.auPhoneNo?.toString() === telNum
  );
  const contractInfo = defaultValues();
  if (telNum && !onlineShopSubscriberInfo && isNumberMigration) {
    contractInfo.isOldUqUser = true;
    // 番号移行かつ旧UQの場合はLEONから顧客情報取得
    const uqUser = accountInfo?.uqUser;
    // LEONからの返却値がC21注文登録セット対象項目の桁数を超えてしまう場合を考慮し、
    // ①不要な後ろ空白を削除
    // ②姓と名を連結し、姓名は全角へ、姓名（カナ）は半角に変換後全角に変換する（変換不可な文字を除外するため）
    // ③合計30文字までを切り出す
    contractInfo.contractorSeiMei = hanToZenOrEmpty(
      `${uqUser?.customer?.lastName?.trimEnd()}${NAME_FULL_SPACE}${uqUser?.customer?.firstName?.trimEnd()}`
    )
      .substring(0, 30)
      .trimEnd();
    contractInfo.contractorSeiMeiFull = `${uqUser?.customer?.lastName?.trimEnd()}${NAME_FULL_SPACE}${uqUser?.customer?.firstName?.trimEnd()}`;
    contractInfo.contractorSei = divideName(contractInfo.contractorSeiMei).lastName;
    contractInfo.contractorMei = divideName(contractInfo.contractorSeiMei).firstName;
    contractInfo.contractorSeiMeiKana = hanToZenOrEmpty(
      `${uqUser?.customer?.lastNameKana?.trimEnd()}${NAME_FULL_SPACE}${uqUser?.customer?.firstNameKana?.trimEnd()}`
    )
      .substring(0, 30)
      .trimEnd();
    contractInfo.contractorSeiMeiKanaFull = `${uqUser?.customer?.lastNameKana?.trimEnd()}${NAME_FULL_SPACE}${uqUser?.customer?.firstNameKana?.trimEnd()}`;
    contractInfo.contractorSeiKana = divideName(contractInfo.contractorSeiMeiKana).lastName;
    contractInfo.contractorMeiKana = divideName(contractInfo.contractorSeiMeiKana).firstName;

    contractInfo.contractorGender = (uqUser?.customer?.sexType as SexType) || undefined;
    contractInfo.contractorBirthday = uqUser?.customer?.birthday || '';
    contractInfo.userBirthday = uqUser?.user?.userBirthday || undefined;
    contractInfo.contractorTelephoneNumber = uqUser?.customer?.phoneNumber1 || '';
    contractInfo.contractorPost = uqUser?.customer?.postalCode || undefined;
    contractInfo.customerAddress = `${uqUser?.customer.customerAddress1 ?? ''}${
      uqUser?.customer.customerAddress2 ?? ''
    }${uqUser?.customer?.customerAddress3 ?? ''}`;
    contractInfo.telephoneNumber = uqUser?.line?.phoneNumber || '';
    contractInfo.mvnoCode = uqUser?.contract?.mvnoCompanyCode || undefined;
    contractInfo.usePersonSame = !uqUser?.user?.userType
      ? UsePersonSameValues.diffContract
      : UsePersonSameValues.sameContract;
    contractInfo.usePersonLastName = uqUser?.user?.userLastName || undefined;
    contractInfo.usePersonFirstName = uqUser?.user?.userFirstName || undefined;
    contractInfo.usePersonLastNameKana = uqUser?.user?.userLastNameKana || undefined;
    contractInfo.usePersonFirstNameKana = uqUser?.user?.userFirstNameKana || undefined;
    contractInfo.userBirthday = uqUser?.user?.userBirthday || undefined;
    contractInfo.contractorCategory = uqUser?.customer?.contractorCategory || undefined;
  } else {
    // au・新UQユーザーの場合、Faceから顧客情報取得
    const user = accountInfo?.user;
    // ①不要な後ろ空白を削除
    // ②姓名は全角へ、姓名（カナ）は半角に変換後全角に変換する（変換不可な文字を除外するため）
    // ③合計30文字までを切り出す
    const contractorSeiMei = hanToZenOrEmpty(user?.kanjiFullNameSeiMei || '')
      .substring(0, 30)
      .trimEnd();
    const contractorSeiMeiKana = hanToZenOrEmpty(user?.kanaFullNameSeiMei || '')
      .substring(0, 30)
      .trimEnd();
    const userName = hanToZenOrEmpty(onlineShopSubscriberInfo?.userName || '')
      .substring(0, 30)
      .trimEnd();
    const userNameKana = hanToZenOrEmpty(onlineShopSubscriberInfo?.userNameKana || '')
      .substring(0, 30)
      .trimEnd();

    contractInfo.contractorSeiMei = contractorSeiMei;
    contractInfo.contractorSeiMeiFull = user?.kanjiFullNameSeiMei?.trimEnd() || '';
    contractInfo.contractorSei = divideName(contractorSeiMei).lastName;
    contractInfo.contractorMei = divideName(contractorSeiMei).firstName;
    contractInfo.contractorSeiMeiKana = contractorSeiMeiKana;
    contractInfo.contractorSeiMeiKanaFull = user?.kanaFullNameSeiMei?.trimEnd() || '';
    contractInfo.contractorSeiKana = divideName(contractorSeiMeiKana).lastName;
    contractInfo.contractorMeiKana = divideName(contractorSeiMeiKana).firstName;
    contractInfo.contractorGender = user?.gender || undefined;
    contractInfo.contractorBirthday = user?.birthday || '';
    contractInfo.contractorPost = user?.customerPostalNo || undefined;
    contractInfo.contractorTelephoneNumber = user?.customerPhoneNo || '';
    contractInfo.customerAddress = user?.customerAddress || undefined;
    contractInfo.contractorEmailAddress = user?.customerEzmailAddress || undefined;
    contractInfo.usePersonLastName = divideName(userName).lastName;
    contractInfo.usePersonFirstName = divideName(userName).firstName;
    contractInfo.usePersonFullName = userName;
    contractInfo.usePersonLastNameKana = divideName(userNameKana).lastName;
    contractInfo.usePersonFirstNameKana = divideName(userNameKana).firstName;
    contractInfo.usePersonFullNameKana = userNameKana;
    contractInfo.userBirthday = onlineShopSubscriberInfo?.userBirthDate || undefined;
    contractInfo.companyType = onlineShopSubscriberInfo?.companyType || undefined;
    contractInfo.usePersonSame = UsePersonSameValues.sameContract;
    contractInfo.billingPostalCode = onlineShopSubscriberInfo?.billingPostalCode || undefined;
  }
  return contractInfo;
};
