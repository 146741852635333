import { order } from 'ols-order-webservice-typescript-client';
import { ActionCreatorWithoutPayload } from '@reduxjs/toolkit';
import { AppDispatch } from '@/store';
import {
  createPaymentStatus as createPaymentStatusAction,
  resetCreatePaymentStatus as resetCreatePaymentStatusAction,
  createPayment as createPaymentAction,
  createAuthenticatedPayment as createAuthenticatedPaymentAction,
  createOrder as createOrderAction,
  createOrderConfirmation as createOrderConfirmationAction,
  createAccountTransfer as createAccountTransferAction,
  validAccountTransfer as validAccountTransferAction,
  resetValidAccountTransfer as resetValidAccountTransferAction,
  paymentAuEasyPayment as createPaymentAuEasyPaymentAction,
  resetCreatePayment as resetCreatePaymentAction,
  resetAuthenticatedPayment as resetAuthenticatedPaymentAction,
  resetCreateOrder as resetCreateOrderAction,
  resetCreateOrderConfirmation as resetCreateOrderConfirmationAction,
  createPaymentMethod as createPaymentMethodAction,
  resetPaymentMethod as resetPaymentMethodAction,
  resetAccountTransfer as resetAccountTransferAction,
  resetPaymentAuEasyPayment as resetPaymentAuEasyPaymentAction
} from './actions';

const resetPayment = resetCreatePaymentAction as ActionCreatorWithoutPayload<string>;
const resetPaymentStatus = resetCreatePaymentStatusAction as ActionCreatorWithoutPayload<string>;
const resetAuthenticatedPayment = resetAuthenticatedPaymentAction as ActionCreatorWithoutPayload<string>;
const resetOrder = resetCreateOrderAction as ActionCreatorWithoutPayload<string>;
const resetOrderConfirmation = resetCreateOrderConfirmationAction as ActionCreatorWithoutPayload<string>;
const resetPaymentMethod = resetPaymentMethodAction as ActionCreatorWithoutPayload<string>;
const resetAccountTransfer = resetAccountTransferAction as ActionCreatorWithoutPayload<string>;
const resetPaymentAuEasyPayment = resetPaymentAuEasyPaymentAction as ActionCreatorWithoutPayload<string>;
const resetValidAccountTransfer = resetValidAccountTransferAction as ActionCreatorWithoutPayload<string>;

/**
 * BFF-ORD-001: 決済ステータス情報登録API
 * @param void -引数なし
 * @returns
 */
export const createPaymentStatus = () => (
  dispatch: AppDispatch
): ReturnType<ReturnType<typeof createPaymentStatusAction>> => dispatch(createPaymentStatusAction());

/**
 * BFF-ORD-001: 決済ステータス情報登録API 初期化
 * @returns
 */
export const resetCreatePaymentStatus = () => (dispatch: AppDispatch): ReturnType<typeof resetPaymentStatus> =>
  dispatch(resetPaymentStatus());

/**
 * BFF-ORD-002: 決済実行API
 * @param paymentPostRequestV1 -決済実行のリクエストモデル
 * @returns
 */
export const createPayment = (paymentPostRequestV1?: order.PaymentPostRequestV1) => (
  dispatch: AppDispatch
): ReturnType<ReturnType<typeof createPaymentAction>> =>
  dispatch(
    createPaymentAction({
      paymentPostRequestV1
    })
  );

/**
 * BFF-ORD-002: 決済実行API 初期化
 * @returns
 */
export const resetCreatePayment = () => (dispatch: AppDispatch): ReturnType<typeof resetPayment> =>
  dispatch(resetPayment());

/**
 * BFF-ORD-003: 認証後決済実行API
 * @param authenticatedPaymentPostRequestV1 -認証後決済実行のリクエストモデル
 * @returns
 */
export const createAuthenticatedPayment = (
  authenticatedPaymentPostRequestV1?: order.AuthenticatedPaymentPostRequestV1
) => (dispatch: AppDispatch): ReturnType<ReturnType<typeof createAuthenticatedPaymentAction>> =>
  dispatch(
    createAuthenticatedPaymentAction({
      authenticatedPaymentPostRequestV1
    })
  );

/**
 * BFF-ORD-003: 認証後決済実行API 初期化
 * @returns
 */
export const resetCreateAuthenticatedPayment = () => (
  dispatch: AppDispatch
): ReturnType<typeof resetAuthenticatedPayment> => dispatch(resetAuthenticatedPayment());

/**
 * BFF-ORD-004: 注文情報登録API
 * @param orderPostRequestV1 -注文登録のリクエストモデル
 * @returns
 */
export const createOrder = (orderPostRequestV1?: order.OrderPostRequestV1) => (
  dispatch: AppDispatch
): ReturnType<ReturnType<typeof createOrderAction>> =>
  dispatch(
    createOrderAction({
      orderPostRequestV1
    })
  );

/**
 * BFF-ORD-004: 注文情報登録API 初期化
 * @returns
 */
export const resetCreateOrder = () => (dispatch: AppDispatch): ReturnType<typeof resetOrder> => dispatch(resetOrder());

/**
 * BFF-ORD-005: 注文時確認事項登録API
 * @param orderConformationPostRequestV1 -注文時確認事項登録のリクエストモデル
 * @returns
 */
export const createOrderConfirmation = (orderConformationPostRequestV1?: order.OrderConformationPostRequestV1) => (
  dispatch: AppDispatch
): ReturnType<ReturnType<typeof createOrderConfirmationAction>> =>
  dispatch(
    createOrderConfirmationAction({
      orderConformationPostRequestV1
    })
  );

/**
 * BFF-ORD-005: 注文時確認事項登録API 初期化
 * @returns
 */
export const resetCreateOrderConfirmation = () => (dispatch: AppDispatch): ReturnType<typeof resetOrderConfirmation> =>
  dispatch(resetOrderConfirmation());

/**
 * BFF-ORD-008: お支払い方法登録API
 * @param createPaymentMethodPostRequestV1 -お支払い方法登録のリクエストモデル
 * @returns
 */
export const createPaymentMethod = (createPaymentMethodPostRequestV1?: order.CreatePaymentMethodPostRequestV1) => (
  dispatch: AppDispatch
): ReturnType<ReturnType<typeof createPaymentMethodAction>> =>
  dispatch(
    createPaymentMethodAction({
      createPaymentMethodPostRequestV1
    })
  );

/**
 * BFF-ORD-008: お支払い方法登録API 初期化
 * @returns
 */
export const resetCreatePaymentMethod = () => (dispatch: AppDispatch): ReturnType<typeof resetPaymentMethod> =>
  dispatch(resetPaymentMethod());

/**
 * BFF-ORD-009: 口振GW受取文字列チェックAPI
 * @param validPaymentMethodPostRequestV1 -口振GW受取文字列チェックのリクエストモデル
 * @returns
 */
export const validAccountTransfer = (validPaymentMethodPostRequestV1: string) => (
  dispatch: AppDispatch
): ReturnType<ReturnType<typeof validAccountTransferAction>> =>
  dispatch(
    validAccountTransferAction({
      validPaymentMethodPostRequestV1
    })
  );

/**
 *  BFF-ORD-009: 口振GW受取文字列チェックAPI 初期化
 * @returns
 */
export const resetCreateValidAccountTransfer = () => (
  dispatch: AppDispatch
): ReturnType<typeof resetValidAccountTransfer> => dispatch(resetValidAccountTransfer());

/**
 *  BFF-ORD-010: 口振GW受取文字列登録API 初期化
 * @returns
 */
export const resetCreateAccountTransfer = () => (dispatch: AppDispatch): ReturnType<typeof resetAccountTransfer> =>
  dispatch(resetAccountTransfer());

/**
 * BFF-ORD-010: 口振GW受取文字列登録API
 * @returns
 */
export const createAccountTransfer = () => (
  dispatch: AppDispatch
): ReturnType<ReturnType<typeof createAccountTransferAction>> => dispatch(createAccountTransferAction());

/**
 * BFF-ORD-011: auかんたん決済実行API
 * @param paymentAuEasyPaymentPostRequestV1 -auかんたん決済実行のリクエストモデル
 * @returns
 */
export const createPaymentAuEasyPayment = (
  paymentAuEasyPaymentPostRequestV1?: order.PaymentAuEasyPaymentPostRequestV1
) => (dispatch: AppDispatch): ReturnType<ReturnType<typeof createPaymentAuEasyPaymentAction>> =>
  dispatch(
    createPaymentAuEasyPaymentAction({
      paymentAuEasyPaymentPostRequestV1
    })
  );

/**
 * BFF-ORD-011: auかんたん決済実行API 初期化
 * @returns
 */
export const resetCreatePaymentAuEasyPayment = () => (
  dispatch: AppDispatch
): ReturnType<typeof resetPaymentAuEasyPayment> => dispatch(resetPaymentAuEasyPayment());
