import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ContractDetailScreenNames } from '@/typings/ContractDetailScreenNames';

/**
 * hideFlag: 非表示判定フラグ
 * →デフォルトでfalse。コンポーネントが非表示の場合trueになる。
 *  基本的にはラジオボタンが選択されたら次のコンポーネントを表示していくが、自身が非表示の場合ラジオボタン無しなのでこのフラグをtrueにすることで次のコンポーネントを表示する
 *  初期化タイミングは各コンポーネントが描画されるタイミングと前のコンポーネントの値に変化があったタイミング
 * returnValueFlag: Storeから値復帰を行うかの判定フラグ
 * →デフォルトでtrue
 *  そのコンポーネントより前のコンポーネントにて値復帰が失敗、もしくは違う選択肢が選ばれた際にfalseとなる。
 *  自身のコンポーネントでユーザーが違う選択肢を選んだ場合や復帰処理に失敗した場合に次のコンポーネントのreturnValueFlagもfalseに設定する。
 *  自身のreturnValueFlagがfalseだと自身のfirstTimeFlagをfalseにするのに加え、次のコンポーネントのreturnValueFlagもfalseにするといった風に連鎖的に後続のreturnValueFlagもfalseとなる。
 *  値復帰を行う条件の一つとして使用
 *  初期化タイミングは「ポイント・クーポン利用へ進む」が押下されたタイミング
 * firstTimeFlag: 初回以降値復帰を行うかの判定フラグ
 * →デフォルトでtrue
 *  前のコンポーネントで値復帰が失敗していないか(returnValueFlag)、違う選択肢が選ばれていないか(returnValueFlag)、storeに値はあるか
 *  上記の条件を満たさなければfirstTimeFlagはfalseとなる。また同時に次のコンポーネントのreturnValueFlagもfalseに設定する。
 *  初期化タイミングは「ポイント・クーポン利用へ進む」が押下されたタイミング
 */
export type SectionStatusDetail = { hideFlag: boolean; returnValueFlag: boolean; firstTimeFlag: boolean };

export type ContractDetailScreenStatuses = Record<ContractDetailScreenNames, SectionStatusDetail>;

export type twoContractDetailScreenNames = {
  currentScreenName: ContractDetailScreenNames;
  nextScreenName: ContractDetailScreenNames;
};

const init = { hideFlag: false, returnValueFlag: true, firstTimeFlag: true };

const initialState: ContractDetailScreenStatuses = {
  ratePlanCategoryScreen: { ...init },
  ratePlanScreen: { ...init },
  increasedOptionScreen: { ...init },
  phoneNumberForTabletSharePlanScreen: { ...init },
  callOptionScreen: { ...init },
  additionalServiceScreen: { ...init },
  additionalServicePointScreen: { ...init },
  additionalServiceRefundScreen: { ...init },
  contractTwoYearScreen: { ...init },
  auPayCardDiscountScreen: { ...init },
  financialDiscountScreen: { ...init },
  familyDiscountPlusScreen: { ...init },
  auSmartValueScreen: { ...init },
  auSmartValueMineScreen: { ...init },
  internetConnectionServiceScreen: { ...init },
  subscribedOptionListScreen: { ...init },
  auSmartPassScreen: { ...init },
  auWellnessScreen: { ...init },
  appleMusicScreen: { ...init },
  youTubePremiumScreen: { ...init },
  auBicycleSupportScreen: { ...init },
  afterCareSaleServiceCodeScreen: { ...init },
  afterCareSaleServiceCodeForAppleWatchScreen: { ...init },
  contractAppleCarePlusScreen: { ...init },
  warrantySupportScreen: { ...init },
  campaignDiscountScreen: { ...init },
  monthlyDiscountCampaignScreen: { ...init },
  afterDayCampaignScreen: { ...init },
  programPrivilegeUseScreen: { ...init },
  recoverKitScreen: { ...init },
  changePrivilegeScreen: { ...init },
  tradeInProgramScreen: { ...init },
  simOnlyWarrantyServiceScreen: { ...init },
  modelPricePaymentMethodsScreen: { ...init },
  accessoryPaymentMethodsScreen: { ...init },
  accessoryPaymentMethodsBulkScreen: { ...init },
  useSimScreen: { ...init },
  simLockFreeScreen: { ...init }
};

const slice = createSlice({
  name: 'contractDetailScreenStatuses',
  initialState,
  reducers: {
    setScreenStatus: (
      state: ContractDetailScreenStatuses,
      action: PayloadAction<Partial<ContractDetailScreenStatuses>>
    ) => ({
      ...state,
      ...action.payload
    }),
    resetScreenStatus: (state: ContractDetailScreenStatuses) => ({
      ...state,
      ...initialState
    }),
    setTrueToHideFlag: (state: ContractDetailScreenStatuses, action: PayloadAction<ContractDetailScreenNames>) => ({
      ...state,
      [action.payload]: {
        hideFlag: true,
        returnValueFlag: state[action.payload].returnValueFlag,
        firstTimeFlag: state[action.payload].firstTimeFlag
      }
    }),
    setFalseToHideFlag: (state: ContractDetailScreenStatuses, action: PayloadAction<ContractDetailScreenNames>) => ({
      ...state,
      [action.payload]: {
        hideFlag: false,
        returnValueFlag: state[action.payload].returnValueFlag,
        firstTimeFlag: state[action.payload].firstTimeFlag
      }
    }),
    setFalseToFirstTimeFlag: (
      state: ContractDetailScreenStatuses,
      action: PayloadAction<ContractDetailScreenNames>
    ) => ({
      ...state,
      [action.payload]: {
        hideFlag: state[action.payload].hideFlag,
        returnValueFlag: state[action.payload].returnValueFlag,
        firstTimeFlag: false
      }
    }),
    changeTwoScreenStatus: (
      state: ContractDetailScreenStatuses,
      action: PayloadAction<twoContractDetailScreenNames>
    ) => ({
      ...state,
      [action.payload.currentScreenName]: {
        hideFlag: state[action.payload.currentScreenName].hideFlag,
        returnValueFlag: state[action.payload.currentScreenName].returnValueFlag,
        firstTimeFlag: false
      },
      [action.payload.nextScreenName]: {
        hideFlag: state[action.payload.nextScreenName].hideFlag,
        returnValueFlag: false,
        firstTimeFlag: state[action.payload.nextScreenName].firstTimeFlag
      }
    })
  }
});

export const { actions, reducer } = slice;
