import type { RootState } from '@/store';

/**
 * 商品情報取得セレクタ
 * @param state - ステート
 * @returns OP品の商品情報
 */
export const getGoodsList = (state: RootState): RootState['flow']['accessory']['goodsList'] =>
  state.flow.accessory.goodsList;

/**
 * カテゴリー一覧表示順リスト取得セレクタ
 * @param state - ステート
 * @returns カテゴリー一覧表示順リスト
 */
export const getOrderedCategoryList = (state: RootState): RootState['flow']['accessory']['orderedCategoryList'] =>
  state.flow.accessory.orderedCategoryList;

/**
 * 無料同梱品ワーニング表示フラグ取得セレクタ
 * @param state - ステート
 * @returns 無料同梱品ワーニング表示フラグ
 */
export const getFreeBundledGoodsWarningDisplayFlag = (
  state: RootState
): RootState['flow']['accessory']['freeBundledGoodsWarningDisplayFlag'] =>
  state.flow.accessory.freeBundledGoodsWarningDisplayFlag;
