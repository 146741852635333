/**
 * 受取場所を表すコード値
 */
export type ReceivingPlaceCode = '1' | '2' | '4';
export const ReceivingPlaceCodes = {
  CONTRACTOR_ADDRESS: '1',
  INVOICE_ADDRESS: '2',
  AT_AU_SHOP: '4'
} as const;
export const ReceivingPlaceCodeLabels = {
  '1': '契約者住所',
  '2': '請求書送付先住所',
  '4': 'au Style／auショップ'
} as const;
export const FixReceivingPlaceCodeLabels = {
  '1': '契約者住所で受け取り：',
  '2': '請求書送付先住所で受け取り：',
  '4': 'au Style／auショップ'
} as const;
