import { uploadIdentificationImagesSlice, validOneTimePasswordSlice } from './identificationServiceSlice';

export {
  createIdentification,
  uploadIdentificationImages,
  getIdentificationImage,
  createOneTimePassword,
  validOneTimePassword
} from './thunk';

export const { resetUploadIdentificationImages } = uploadIdentificationImagesSlice.actions;
export const { resetValidOneTimePasswords } = validOneTimePasswordSlice.actions;
