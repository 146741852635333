import { AppDispatch } from '@/store';
import { getAddress as getAddressAction } from './actions';

/**
 * BFF-ADD-001: 住所情報取得API
 * @param postalCode - 取得したい住所の郵便番号
 * @returns
 */
export const getAddress = (postalCode: string) => (
  dispatch: AppDispatch
): ReturnType<ReturnType<typeof getAddressAction>> =>
  dispatch(
    getAddressAction({
      postalCode
    })
  );
