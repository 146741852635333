import { CaseReducer, combineReducers, createSlice } from '@reduxjs/toolkit';
import { order } from 'ols-order-webservice-typescript-client';
import { ServiceState } from '@/typings/ServiceState';
import {
  createLog,
  getUnavailableServiceStatuses,
  getAvailableEstimateOptions,
  getSystemSettings,
  getMnpEffectiveness,
  getUnavailableCharacters
} from './thunk';

const initialState = {
  loaded: false,
  failed: false,
  response: null,
  error: null
};

/**
 * BFF-SYS-002: ログ登録API
 */
export const createLogSlice = createSlice<ServiceState<void>, Record<string, never>>({
  name: 'systemService/createLog',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createLog.pending, () => initialState)
      .addCase(createLog.rejected, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: true,
          response: null,
          error: action.payload || null
        }
      }))
      .addCase(createLog.fulfilled, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: false,
          response: null,
          error: null
        }
      }));
  }
});

/**
 * BFF-SYS-003: マスク対象取得API
 */
export const getUnavailableServiceStatusesSlice = createSlice<
  ServiceState<order.UnavailableServiceStatusGroupV1[]>,
  Record<string, never>
>({
  name: 'systemService/getUnavailableServiceStatuses',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUnavailableServiceStatuses.pending, () => initialState)
      .addCase(getUnavailableServiceStatuses.rejected, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: true,
          response: null,
          error: action.payload || null
        }
      }))
      .addCase(getUnavailableServiceStatuses.fulfilled, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: false,
          response: action.payload,
          error: null
        }
      }));
  }
});

/**
 * BFF-SYS-004: 見積もり選択可能オプション取得API
 */
export const getAvailableEstimateOptionsSlice = createSlice<
  ServiceState<order.AvailableEstimateOptionsGetResponseV1>,
  Record<string, CaseReducer>
>({
  name: 'systemService/getAvailableEstimateOptions',
  initialState,
  reducers: {
    resetAvailableEstimateOptions: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAvailableEstimateOptions.pending, () => initialState)
      .addCase(getAvailableEstimateOptions.rejected, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: true,
          response: null,
          error: action.payload || null
        }
      }))
      .addCase(getAvailableEstimateOptions.fulfilled, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: false,
          response: action.payload,
          error: null
        }
      }));
  }
});

/**
 * BFF-SYS-005: システム設定情報取得API
 */
export const getSystemSettingsSlice = createSlice<
  ServiceState<order.SystemSettingsGetResponseV1>,
  Record<string, never>
>({
  name: 'systemService/getSystemSettings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSystemSettings.pending, () => initialState)
      .addCase(getSystemSettings.rejected, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: true,
          response: null,
          error: action.payload || null
        }
      }))
      .addCase(getSystemSettings.fulfilled, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: false,
          response: action.payload,
          error: null
        }
      }));
  }
});

/**
 * BFF-SYS-006: MNP予約番号有効性確認API
 */
export const getMnpEffectivenessSlice = createSlice<
  ServiceState<order.MnpEffectivenessConformationGetResponseV1>,
  Record<string, CaseReducer>
>({
  name: 'systemService/getMnpEffectiveness',
  initialState,
  reducers: {
    resetMnpEffectiveness: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMnpEffectiveness.pending, () => initialState)
      .addCase(getMnpEffectiveness.rejected, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: true,
          response: null,
          error: action.payload || null
        }
      }))
      .addCase(getMnpEffectiveness.fulfilled, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: false,
          response: action.payload,
          error: null
        }
      }));
  }
});

/**
 * BFF-SYS-007: 利用不可文字確認API
 */
export const getUnavailableCharactersSlice = createSlice<
  ServiceState<order.UnavailableCharactersPostResponseV1>,
  Record<string, never>
>({
  name: 'systemService/getUnavailableCharacters',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUnavailableCharacters.pending, () => initialState)
      .addCase(getUnavailableCharacters.rejected, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: true,
          response: null,
          error: action.payload || null
        }
      }))
      .addCase(getUnavailableCharacters.fulfilled, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: false,
          response: action.payload,
          error: null
        }
      }));
  }
});

export const reducers = combineReducers({
  createLog: createLogSlice.reducer,
  getUnavailableServiceStatuses: getUnavailableServiceStatusesSlice.reducer,
  getAvailableEstimateOptions: getAvailableEstimateOptionsSlice.reducer,
  getSystemSettings: getSystemSettingsSlice.reducer,
  getMnpEffectiveness: getMnpEffectivenessSlice.reducer,
  getUnavailableCharacters: getUnavailableCharactersSlice.reducer
});
