import axios, { Canceler } from 'axios';
import { order } from 'ols-order-webservice-typescript-client';
import { consoleLog } from '@/utils/functions';

const { EstimateApi } = order;

export class EstimateApiService {
  private static instance: EstimateApiService;

  private api: order.EstimateApi;

  private constructor(config: order.Configuration) {
    this.api = new EstimateApi(config);
  }

  public static getInstance(config: order.Configuration): EstimateApiService {
    if (!this.instance) {
      this.instance = new EstimateApiService(config);
    }

    return this.instance;
  }

  /**
   * BFF-EST-001: 注文見積もり情報取得API
   * @param void -引数なし
   * @returns
   */
  getEstimatingOrder(): Promise<void> {
    consoleLog('BFF-EST-001: 注文見積もり情報取得API call');
    return this.api.getEstimatingOrderV1().then((res) => res.data);
  }

  /**
   * BFF-EST-002: 注文見積もり情報登録API
   * @param estimatingOrderPostRequestV1 -注文見積もり情報のリクエストモデル
   * @returns
   */
  createEstimatingOrder(
    estimatingOrderPostRequestV1?: order.EstimatingOrderPostRequestV1
  ): Promise<order.EstimatingOrderPostResponseV1> {
    consoleLog('BFF-EST-002: 注文見積もり情報登録API call', estimatingOrderPostRequestV1);
    return this.api.createEstimatingOrderV1(estimatingOrderPostRequestV1).then((res) => res.data);
  }

  /**
   * BFF-EST-003: 本申込情報取得API
   * @param orderNo -注文番号
   * @returns
   */
  getPendingReservationsInfo(orderNo: string): Promise<order.PendingReservationsInfoGetResponseV1> {
    consoleLog('BFF-EST-003: 本申込情報取得API call', orderNo);
    return this.api.getPendingReservationsInfoV1(orderNo).then((res) => res.data);
  }

  /**
   * BFF-EST-004: 見積保存メール情報取得API
   * @param hashId -ハッシュ値
   * @returns
   */
  getEstimateSaveMailByEstimateId(hashId: string): Promise<order.EstimateSaveMailByEstimateIdGetResponseV1> {
    consoleLog('BFF-EST-004: 見積保存メール情報取得API call', hashId);
    return this.api.getEstimateSaveMailByEstimateIdV1(hashId).then((res) => res.data);
  }

  /**
   * BFF-EST-005: 見積保存メール情報登録API
   * @param estimateSaveMailByEstimateId -見積保存メール情報登録APIのリクエストモデル
   * @returns
   */
  createEstimateSaveMailByEstimateId(estimateSaveMailByEstimateId?: order.EstimateSaveMailByEstimateId): Promise<void> {
    consoleLog('BFF-EST-005: 見積保存メール情報登録API call', estimateSaveMailByEstimateId);
    return this.api.createEstimateSaveMailByEstimateIdV1(estimateSaveMailByEstimateId).then((res) => res.data);
  }

  /**
   * BFF-EST-006: 料金費用内訳計算API
   * @param estimateAmountsPostRequestV1 -料金費用内訳計算APIのリクエストモデル
   * @returns
   */

  private cancelEstimatedAmounts: Canceler | undefined;

  postEstimatedAmounts(
    estimateAmountsPostRequestV1?: order.EstimateAmountsPostRequestV1
  ): Promise<order.EstimateAmountsPostResponseV1> {
    if (this.cancelEstimatedAmounts) {
      this.cancelEstimatedAmounts();
    }

    const { CancelToken } = axios;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const source = CancelToken.source();
    consoleLog('BFF-EST-006: 料金費用内訳計算API call', estimateAmountsPostRequestV1);
    return this.api
      .postEstimatedAmountsV1(estimateAmountsPostRequestV1, {
        cancelToken: new CancelToken((c: Canceler) => {
          this.cancelEstimatedAmounts = c;
        })
      })
      .then((res) => res.data);
  }

  /**
   * BFF-EST-007: 割引施策セッション初期化API
   * @param void -引数なし
   * @returns
   */
  getEstimateSessionClear(): Promise<void> {
    consoleLog('BFF-EST-007: 割引施策セッション初期化API call');
    return this.api.getEstimateSessionClearV1().then((res) => res.data);
  }

  /**
   * BFF-EST-009: シミュレーション情報取得API
   * @param offerId -オファーID
   * @param ottId -ワンタイムトークンID
   * @returns
   */
  getSimulationInfo(offerId: string, ottId?: string): Promise<order.SimulationInfoGetResponseV1> {
    consoleLog('BFF-EST-009: シミュレーション情報取得API call', offerId, ottId);
    return this.api.getSimulationInfoV1(offerId, ottId).then((res) => res.data);
  }

  /**
   * BFF-EST-010: 暫定料金費用内訳計算API
   * @param estimateTemporaryAmountsPostRequest -暫定料金費用内訳計算APIのリクエストモデル
   * @returns
   */
  postEstimatedTemporaryAmounts(
    estimateTemporaryAmountsPostRequest?: order.EstimateTemporaryAmountsPostRequestV1
  ): Promise<order.EstimateTemporaryAmountsPostResponseV1> {
    consoleLog('BFF-EST-010: 暫定料金費用内訳計算API call', estimateTemporaryAmountsPostRequest);
    return this.api.postEstimatedTemporaryAmountsV1(estimateTemporaryAmountsPostRequest).then((res) => res.data);
  }
}
