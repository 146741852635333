import { parse, isLastDayOfMonth } from 'date-fns';
import type { RootState } from '@/store';
import type {} from './systemSlice';

/**
 * システム情報取得
 * @param state - ステート
 * @returns
 */
export const get = (state: RootState): RootState['flow']['system'] => state.flow.system;

/**
 * 月末日判定
 * @param state - ステート
 * @returns true:月末日、false:月末日ではない
 */
export const systemDateIsLastDayOfMonth = (state: RootState): boolean => {
  const { systemDate } = state.flow.system;
  const dt = parse(systemDate, 'yyyyMMdd', new Date());
  return isLastDayOfMonth(dt);
};

/**
 * アクセサリーの同時購入上限数取得
 * @param state - ステート
 * @returns アクセサリーの同時購入上限数
 */
export const getAccessorySimultaneousPurchaseMaxNumber = (
  state: RootState
): RootState['flow']['system']['accessorySimultaneousPurchaseMaxNumber'] =>
  state.flow.system.accessorySimultaneousPurchaseMaxNumber;

/**
 * アクセサリーの同時購入種類上限数取得
 * @param state - ステート
 * @returns アクセサリーの同時購入種類上限数
 */
export const getAccessorySimultaneousPurchaseTypeMaxNumber = (
  state: RootState
): RootState['flow']['system']['accessorySimultaneousPurchaseTypeMaxNumber'] =>
  state.flow.system.accessorySimultaneousPurchaseTypeMaxNumber;

/**
 * 決済リトライ回数取得
 * @param state - ステート
 * @returns 決済リトライ回数
 */
export const getPaymentRetryCount = (state: RootState): RootState['flow']['system']['paymentRetryCount'] =>
  state.flow.system.paymentRetryCount;

/**
 * 3D認証エラーメッセージ
 * @param state - ステート
 * @returns 3D認証エラーメッセージ
 */
export const getAuth3dErrorMessage = (state: RootState): RootState['flow']['system']['auth3dErrorMessage'] =>
  state.flow.system.auth3dErrorMessage;

/**
 * 決済リトライ上限回数
 */
const PaymentRetryUpperLimit = 2;

/**
 * リトライオーバー判定
 * @param state - ステート
 * @returns
 * - @param boolean - リトライオーバー判定結果 true:リトライオーバー, false:リトライセーフ
 */
export const isPaymentRetryOver = (state: RootState): boolean =>
  state.flow.system.paymentRetryCount >= PaymentRetryUpperLimit;

/**
 * リトライ上限判定
 * @param state - ステート
 * @returns
 * - @param boolean - リトライオーバー判定結果 true:リトライ上限, false:リトライ上限以外
 */
export const isPaymentRetryLimit = (state: RootState): boolean =>
  state.flow.system.paymentRetryCount === PaymentRetryUpperLimit;
