import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { order } from 'ols-order-webservice-typescript-client';
import { OrderPaymentApiService } from '@/services/orderPaymentApiService';
import { apiConfigProvider } from '@/services/http/apiConfig';
import { ErrorInfo } from '@/typings/ErrorInfo';
import { handleAxiosError } from '@/services/handleAxiosError';

/**
 * BFF-ORD-001: 決済ステータス情報登録API
 */
export const createPaymentStatus = createAsyncThunk<
  // Api正常時レスポンスの型
  void,
  // Apiリクエストパラメータの型
  void,
  // Api異常時レスポンスの型
  { rejectValue: ErrorInfo }
>('orderPaymentService/createPaymentStatus', async (_, { rejectWithValue }) => {
  const config = apiConfigProvider();
  try {
    return await OrderPaymentApiService.getInstance(config).createPaymentStatus();
  } catch (err) {
    const errInfo: AxiosError<order.ApiErrors> = err;
    return rejectWithValue(handleAxiosError(errInfo, 'BFF-ORD-001'));
  }
});

/**
 * BFF-ORD-002: 決済実行API
 */
export const createPayment = createAsyncThunk<
  // Api正常時レスポンスの型
  order.PaymentPostResponseV1,
  // Apiリクエストパラメータの型
  {
    paymentPostRequestV1?: order.PaymentPostRequestV1;
  },
  // Api異常時レスポンスの型
  { rejectValue: ErrorInfo }
>('orderPaymentApiService/createPayment', async ({ paymentPostRequestV1 }, { rejectWithValue }) => {
  const config = apiConfigProvider();
  try {
    return await OrderPaymentApiService.getInstance(config).createPayment(paymentPostRequestV1);
  } catch (err) {
    const errInfo: AxiosError<order.ApiErrors> = err;
    return rejectWithValue(handleAxiosError(errInfo, 'BFF-ORD-002'));
  }
});

/**
 * BFF-ORD-003: 認証後決済実行API
 */
export const createAuthenticatedPayment = createAsyncThunk<
  // Api正常時レスポンスの型
  void,
  // Apiリクエストパラメータの型
  {
    authenticatedPaymentPostRequestV1?: order.AuthenticatedPaymentPostRequestV1;
  },
  // Api異常時レスポンスの型
  { rejectValue: ErrorInfo }
>(
  'orderPaymentApiService/createAuthenticatedPayment',
  async ({ authenticatedPaymentPostRequestV1 }, { rejectWithValue }) => {
    const config = apiConfigProvider();
    try {
      return await OrderPaymentApiService.getInstance(config).createAuthenticatedPayment(
        authenticatedPaymentPostRequestV1
      );
    } catch (err) {
      const errInfo: AxiosError<order.ApiErrors> = err;
      return rejectWithValue(handleAxiosError(errInfo, 'BFF-ORD-003'));
    }
  }
);

/**
 * BFF-ORD-004: 注文情報登録API
 */
export const createOrder = createAsyncThunk<
  // Api正常時レスポンスの型
  order.OrderPostResponseV1,
  // Apiリクエストパラメータの型
  {
    orderPostRequestV1?: order.OrderPostRequestV1;
  },
  // Api異常時レスポンスの型
  { rejectValue: ErrorInfo }
>('orderPaymentApiService/createOrder', async ({ orderPostRequestV1 }, { rejectWithValue }) => {
  const config = apiConfigProvider();
  try {
    return await OrderPaymentApiService.getInstance(config).createOrder(orderPostRequestV1);
  } catch (err) {
    const errInfo: AxiosError<order.ApiErrors> = err;
    return rejectWithValue(handleAxiosError(errInfo, 'BFF-ORD-004'));
  }
});

/**
 * BFF-ORD-005: 注文時確認事項登録API
 */
export const createOrderConfirmation = createAsyncThunk<
  // Api正常時レスポンスの型
  void,
  // Apiリクエストパラメータの型
  {
    orderConformationPostRequestV1?: order.OrderConformationPostRequestV1;
  },
  // Api異常時レスポンスの型
  { rejectValue: ErrorInfo }
>('orderPaymentApiService/createOrderConfirmation', async ({ orderConformationPostRequestV1 }, { rejectWithValue }) => {
  const config = apiConfigProvider();
  try {
    return await OrderPaymentApiService.getInstance(config).createOrderConfirmation(orderConformationPostRequestV1);
  } catch (err) {
    const errInfo: AxiosError<order.ApiErrors> = err;
    return rejectWithValue(handleAxiosError(errInfo, 'BFF-ORD-005'));
  }
});

/**
 * BFF-ORD-008: お支払い方法登録API
 */
export const createPaymentMethod = createAsyncThunk<
  // Api正常時レスポンスの型
  order.CreatePaymentMethodPostResponseV1,
  // Apiリクエストパラメータの型
  {
    createPaymentMethodPostRequestV1?: order.CreatePaymentMethodPostRequestV1;
  },
  // Api異常時レスポンスの型
  { rejectValue: ErrorInfo }
>('orderPaymentApiService/createPaymentMethod', async ({ createPaymentMethodPostRequestV1 }, { rejectWithValue }) => {
  const config = apiConfigProvider();
  try {
    return await OrderPaymentApiService.getInstance(config).createPaymentMethod(createPaymentMethodPostRequestV1);
  } catch (err) {
    const errInfo: AxiosError<order.ApiErrors> = err;
    return rejectWithValue(handleAxiosError(errInfo, 'BFF-ORD-008'));
  }
});

/**
 * BFF-ORD-009: 口振GW受取文字列チェックAPI
 */
export const validAccountTransfer = createAsyncThunk<
  // Api正常時レスポンスの型
  void,
  // Apiリクエストパラメータの型
  {
    validPaymentMethodPostRequestV1: string;
  },
  // Api異常時レスポンスの型
  { rejectValue: ErrorInfo }
>('orderPaymentApiService/validAccountTransfer', async ({ validPaymentMethodPostRequestV1 }, { rejectWithValue }) => {
  const config = apiConfigProvider();
  try {
    return await OrderPaymentApiService.getInstance(config).validAccountTransfer(validPaymentMethodPostRequestV1);
  } catch (err) {
    const errInfo: AxiosError<order.ApiErrors> = err;
    return rejectWithValue(handleAxiosError(errInfo, 'BFF-ORD-009'));
  }
});

/**
 * BFF-ORD-010: 口振GW受取文字列登録API
 */
export const createAccountTransfer = createAsyncThunk<
  // Api正常時レスポンスの型
  void,
  void,
  // Api異常時レスポンスの型
  { rejectValue: ErrorInfo }
>('orderPaymentApiService/createAccountTransfer', async (_, { rejectWithValue }) => {
  const config = apiConfigProvider();
  try {
    return await OrderPaymentApiService.getInstance(config).createAccountTransfer();
  } catch (err) {
    const errInfo: AxiosError<order.ApiErrors> = err;
    return rejectWithValue(handleAxiosError(errInfo, 'BFF-ORD-010'));
  }
});

/**
 * BFF-ORD-011: auかんたん決済実行API
 */
export const paymentAuEasyPayment = createAsyncThunk<
  // Api正常時レスポンスの型
  order.PaymentAuEasyPaymentPostResponseV1,
  // Apiリクエストパラメータの型
  {
    paymentAuEasyPaymentPostRequestV1?: order.PaymentAuEasyPaymentPostRequestV1;
  },
  // Api異常時レスポンスの型
  { rejectValue: ErrorInfo }
>('orderPaymentApiService/paymentAuEasyPayment', async ({ paymentAuEasyPaymentPostRequestV1 }, { rejectWithValue }) => {
  const config = apiConfigProvider();
  try {
    return await OrderPaymentApiService.getInstance(config).paymentAuEasyPayment(paymentAuEasyPaymentPostRequestV1);
  } catch (err) {
    const errInfo: AxiosError<order.ApiErrors> = err;
    return rejectWithValue(handleAxiosError(errInfo, 'BFF-ORD-011'));
  }
});
