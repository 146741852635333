/**
 * 契約都道府県
 */
export type AreaCode =
  | '01'
  | '02'
  | '03'
  | '04'
  | '05'
  | '06'
  | '07'
  | '08'
  | '09'
  | '10'
  | '11'
  | '12'
  | '13'
  | '14'
  | '15'
  | '16'
  | '17'
  | '18'
  | '19'
  | '20'
  | '21'
  | '22'
  | '23'
  | '24'
  | '25'
  | '26'
  | '27'
  | '28'
  | '29'
  | '30'
  | '31'
  | '32'
  | '33'
  | '34'
  | '35'
  | '36'
  | '37'
  | '38'
  | '39'
  | '40'
  | '41'
  | '42'
  | '43'
  | '44'
  | '45'
  | '46'
  | '47';
export const AreaCodes = {
  北海道: '01',
  青森県: '02',
  岩手県: '03',
  宮城県: '04',
  秋田県: '05',
  山形県: '06',
  福島県: '07',
  茨城県: '08',
  栃木県: '09',
  群馬県: '10',
  埼玉県: '11',
  千葉県: '12',
  東京都: '13',
  神奈川県: '14',
  新潟県: '15',
  富山県: '16',
  石川県: '17',
  福井県: '18',
  山梨県: '19',
  長野県: '20',
  岐阜県: '21',
  静岡県: '22',
  愛知県: '23',
  三重県: '24',
  滋賀県: '25',
  京都府: '26',
  大阪府: '27',
  兵庫県: '28',
  奈良県: '29',
  和歌山県: '30',
  鳥取県: '31',
  島根県: '32',
  岡山県: '33',
  広島県: '34',
  山口県: '35',
  徳島県: '36',
  香川県: '37',
  愛媛県: '38',
  高知県: '39',
  福岡県: '40',
  佐賀県: '41',
  長崎県: '42',
  熊本県: '43',
  大分県: '44',
  宮崎県: '45',
  鹿児島県: '46',
  沖縄県: '47'
} as const;
export const AreaCodeLabels = {
  '01': '北海道',
  '02': '青森県',
  '03': '岩手県',
  '04': '宮城県',
  '05': '秋田県',
  '06': '山形県',
  '07': '福島県',
  '08': '茨城県',
  '09': '栃木県',
  '10': '群馬県',
  '11': '埼玉県',
  '12': '千葉県',
  '13': '東京都',
  '14': '神奈川県',
  '15': '新潟県',
  '16': '富山県',
  '17': '石川県',
  '18': '福井県',
  '19': '山梨県',
  '20': '長野県',
  '21': '岐阜県',
  '22': '静岡県',
  '23': '愛知県',
  '24': '三重県',
  '25': '滋賀県',
  '26': '京都府',
  '27': '大阪府',
  '28': '兵庫県',
  '29': '奈良県',
  '30': '和歌山県',
  '31': '鳥取県',
  '32': '島根県',
  '33': '岡山県',
  '34': '広島県',
  '35': '山口県',
  '36': '徳島県',
  '37': '香川県',
  '38': '愛媛県',
  '39': '高知県',
  '40': '福岡県',
  '41': '佐賀県',
  '42': '長崎県',
  '43': '熊本県',
  '44': '大分県',
  '45': '宮崎県',
  '46': '鹿児島県',
  '47': '沖縄県'
} as const;

/**
 *
 * @param cd - チェック対象エリアコード
 * @returns AreaCodeに変換できるならTrue
 */
export const isAreaCode = (cd: string): cd is AreaCode => cd in AreaCodeLabels;
