import type { RootState } from '@/store';

/**
 * ワンタイムパスワード状態取得
 * @param state - ステート
 * @returns
 */
export const get = (state: RootState): RootState['flow']['onetimePasswordStatus'] => state.flow.onetimePasswordStatus;

/**
 * 発行回数
 * @param state - ステート
 * @returns 発行回数
 */
export const getIssueCount = (state: RootState): RootState['flow']['onetimePasswordStatus']['issueCount'] =>
  state.flow.onetimePasswordStatus.issueCount;

/**
 * エラー回数
 * @param state - ステート
 * @returns エラー回数
 */
export const getErrorCount = (state: RootState): RootState['flow']['onetimePasswordStatus']['errorCount'] =>
  state.flow.onetimePasswordStatus.errorCount;
