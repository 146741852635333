import {
  getAccountSlice,
  getPointSlice,
  getContractSlice,
  getAccountComplementsSlice,
  getAuEasyPaymentSlice
} from './userServiceSlice';

export { getAccount, getPoint, getContract, getAccountComplements, getAuEasyPayments } from './thunk';
export const { resetAccountsServiceState } = getAccountSlice.actions;
export const { resetPointsServiceState } = getPointSlice.actions;
export const { resetContractsServiceState } = getContractSlice.actions;
export const { resetAccountComplementsServiceState } = getAccountComplementsSlice.actions;
export const { resetAuEasyPaymentsServiceState } = getAuEasyPaymentSlice.actions;
