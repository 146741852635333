import { combineReducers, createSlice } from '@reduxjs/toolkit';
import { order } from 'ols-order-webservice-typescript-client';
import { ServiceState } from '@/typings/ServiceState';
import { getCouponList } from './thunk';

export type State = {
  [couponNo: string]: ServiceState<order.CandidateCouponsGetResponseV1>;
};
const initialState: State = {};

/**
 * BFF-DCP-002: 割引施策一覧取得API
 */
export const getCouponListSlice = createSlice({
  name: 'couponService/getCouponList',
  initialState,
  reducers: {
    resetServiceState: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(getCouponList.fulfilled, (state, action) => ({
      ...state,
      [action.meta.arg.couponNo]: {
        loaded: true,
        failed: false,
        response: action.payload,
        error: null
      }
    }));

    // 読込エラー
    builder.addCase(getCouponList.rejected, (state, action) => ({
      ...state,
      [action.meta.arg.couponNo]: {
        loaded: true,
        failed: true,
        response: null,
        error: action.payload || null
      }
    }));
  }
});

export const reducers = combineReducers({
  getCouponList: getCouponListSlice.reducer
});

export const { actions } = getCouponListSlice;
