import { GetCouponListParameter } from '@/services/discountCampaignApiService';
import { AppDispatch } from '@/store';
import { getCouponList as getCouponListAction, resetServiceState as resetServiceStateAction } from './actions';

/**
 * BFF-DCP-002: 割引施策一覧取得API
 * @param getCouponListParameter - BFF-DCP-002: 割引施策一覧取得API引数
 * @returns
 */
export const getCouponList = (getCouponListParameter: GetCouponListParameter) => (
  dispatch: AppDispatch
): ReturnType<ReturnType<typeof getCouponListAction>> => dispatch(getCouponListAction(getCouponListParameter));

/**
 * BFF-DCP-002: 割引施策一覧取得APIステートリセット関数
 * @returns
 */
export const resetServiceState = () => (dispatch: AppDispatch): ReturnType<typeof resetServiceStateAction> =>
  dispatch(resetServiceStateAction());
