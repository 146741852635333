import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { order } from 'ols-order-webservice-typescript-client';
import { AreaCode } from '@/typings/AreaCode';
import { ProductApiService } from '@/services/productApiService';
import { apiConfigProvider } from '@/services/http/apiConfig';
import { ErrorInfo } from '@/typings/ErrorInfo';
import { handleAxiosError } from '@/services/handleAxiosError';
import { ContractBranchCode } from '@/typings/ContractBranchCode';
import { CompanyType } from '@/typings/CompanyType';
import { MobileDeviceJudgment } from '@/typings/MobileDeviceJudgment';
import { NetworkType } from '@/typings/NetworkType';

/**
 * BFF-PRD-001: 販売商品情報取得API
 */
export const getSalesProduct = createAsyncThunk<
  // Api正常時レスポンスの型
  order.SalesProductGetResponseV1,
  // Apiリクエストパラメータの型
  {
    productStockCode: string;
    deviceType: '1' | '2';
  },
  // Api異常時レスポンスの型
  { rejectValue: ErrorInfo }
>('productService/getSalesProduct', async ({ productStockCode, deviceType }, { rejectWithValue }) => {
  const config = apiConfigProvider();
  try {
    return await ProductApiService.getInstance(config).getSalesProduct(productStockCode, deviceType);
  } catch (err) {
    const errInfo: AxiosError<order.ApiErrors> = err;
    return rejectWithValue(handleAxiosError(errInfo, 'BFF-PRD-001'));
  }
});

/**
 * BFF-PRD-002: 保証・サポートサービス取得API
 */
export const getCandidateAfterSaleServices = createAsyncThunk<
  // Api正常時レスポンスの型
  order.CandidateAfterSaleServicesGetResponseV1,
  // Apiリクエストパラメータの型
  {
    productStockCode: string;
    tCategoryOptionCodes: Array<string>;
    contractType: '1' | '2' | '3' | '4' | '5';
    auContractId: string;
    acrossApplecareCode?: string;
    acrossAftercareCode?: string;
    acrossAftercareCategory?: string;
    acrossUseSupportCode?: string;
    leonApplecareCode?: string;
    leonDeviceCompensationCode?: string;
    uqUserStatus?: '0' | '1';
    simOnlyFlag?: boolean;
  },
  // Api異常時レスポンスの型
  { rejectValue: ErrorInfo }
>(
  'productService/getCandidateAfterSaleServices',
  async (
    {
      productStockCode,
      tCategoryOptionCodes,
      contractType,
      auContractId,
      acrossApplecareCode,
      acrossAftercareCode,
      acrossAftercareCategory,
      acrossUseSupportCode,
      leonApplecareCode,
      leonDeviceCompensationCode,
      uqUserStatus,
      simOnlyFlag
    },
    { rejectWithValue }
  ) => {
    const config = apiConfigProvider();
    try {
      return await ProductApiService.getInstance(config).getCandidateAfterSaleServices(
        productStockCode,
        tCategoryOptionCodes,
        contractType,
        auContractId,
        acrossApplecareCode,
        acrossAftercareCode,
        acrossAftercareCategory,
        acrossUseSupportCode,
        leonApplecareCode,
        leonDeviceCompensationCode,
        uqUserStatus,
        simOnlyFlag
      );
    } catch (err) {
      const errInfo: AxiosError<order.ApiErrors> = err;
      return rejectWithValue(handleAxiosError(errInfo, 'BFF-PRD-002'));
    }
  }
);

/**
 * BFF-PRD-003: 端末割賦代金マスタAPI
 */
export const getInstallmentPriceByProductStockCode = createAsyncThunk<
  // Api正常時レスポンスの型
  order.DeviceInstallmentPriceByProductStockCodeGetResponseV1,
  // Apiリクエストパラメータの型
  {
    productStockCode: string;
    contractType: '1' | '2' | '3' | '4' | '5';
    areaCodeCode?: AreaCode;
    contractBranchCode?: ContractBranchCode;
    mvnoCode?: string;
    agencyCode?: string;
    kddioct?: string;
    companyType?: CompanyType;
  },
  // Api異常時レスポンスの型
  { rejectValue: ErrorInfo }
>(
  'productService/getInstallmentPriceByProductStockCode',
  async (
    { productStockCode, contractType, areaCodeCode, contractBranchCode, mvnoCode, agencyCode, kddioct },
    { rejectWithValue }
  ) => {
    const config = apiConfigProvider();
    try {
      return await ProductApiService.getInstance(config).getInstallmentPriceByProductStockCode(
        productStockCode,
        contractType,
        areaCodeCode,
        contractBranchCode,
        mvnoCode,
        agencyCode,
        kddioct
      );
    } catch (err) {
      const errInfo: AxiosError<order.ApiErrors> = err;
      return rejectWithValue(handleAxiosError(errInfo, 'BFF-PRD-003'));
    }
  }
);

/**
 * BFF-PRD-004: アクセサリー一覧取得API
 */
export const getOptionalProducts = createAsyncThunk<
  // Api正常時レスポンスの型
  order.AccessoriesGetResponseV1,
  // Apiリクエストパラメータの型
  {
    productStockCode: string;
    contractType: '1' | '2' | '3' | '4' | '5';
    salesMobileDeviceCode?: string;
    orderNo?: string;
    mobileDeviceJudgment?: MobileDeviceJudgment;
    auPhoneUsePeriod?: string;
    mvnoCode?: string;
  },
  // Api異常時レスポンスの型
  { rejectValue: ErrorInfo }
>(
  'productService/getOptionalProducts',
  async (
    {
      productStockCode,
      contractType,
      salesMobileDeviceCode,
      orderNo,
      mobileDeviceJudgment,
      auPhoneUsePeriod,
      mvnoCode
    },
    { rejectWithValue }
  ) => {
    const config = apiConfigProvider();
    try {
      return await ProductApiService.getInstance(config).getOptionalProducts(
        productStockCode,
        contractType,
        salesMobileDeviceCode,
        orderNo,
        mobileDeviceJudgment,
        auPhoneUsePeriod,
        mvnoCode
      );
    } catch (err) {
      const errInfo: AxiosError<order.ApiErrors> = err;
      return rejectWithValue(handleAxiosError(errInfo, 'BFF-PRD-004'));
    }
  }
);

/**
 * BFF-PRD-005: アクセサリー詳細情報取得API
 */
export const getOptionalProductDetail = createAsyncThunk<
  // Api正常時レスポンスの型
  order.AccessoryDetailGetResponseV1,
  // Apiリクエストパラメータの型
  {
    productStockCode: string;
  },
  // Api異常時レスポンスの型
  { rejectValue: ErrorInfo }
>('productService/getOptionalProductDetail', async ({ productStockCode }, { rejectWithValue }) => {
  const config = apiConfigProvider();
  try {
    return await ProductApiService.getInstance(config).getOptionalProductDetail(productStockCode);
  } catch (err) {
    const errInfo: AxiosError<order.ApiErrors> = err;
    return rejectWithValue(handleAxiosError(errInfo, 'BFF-PRD-005'));
  }
});

/**
 * BFF-PRD-006: 機種変更区分取得API
 */
export const getDecisionDeviceChangeIncrease = createAsyncThunk<
  // Api正常時レスポンスの型
  order.DeviceChangeTypeGetResponseV1,
  // Apiリクエストパラメータの型
  {
    networkType: NetworkType;
    romIcCardDeviceCategory: '5' | '6' | '7' | '8' | '9' | '10' | '12' | '13' | '14' | '15';
    eSimFlag: boolean;
    auPhoneUsePeriod: string;
    densyu?: string;
    mobileDeviceJudgment?: MobileDeviceJudgment;
    productAttribute?: string;
    microSim?: '0' | '1';
    singleDeviceType?: '1' | '2' | '3' | '4';
    winIsSeriesDeviceCategory?: '1' | '2' | '3' | '4' | '5';
  },
  // Api異常時レスポンスの型
  { rejectValue: ErrorInfo }
>(
  'productService/getDecisionDeviceChangeIncrease',
  async (
    {
      networkType,
      romIcCardDeviceCategory,
      eSimFlag,
      auPhoneUsePeriod,
      densyu,
      mobileDeviceJudgment,
      productAttribute,
      microSim,
      singleDeviceType,
      winIsSeriesDeviceCategory
    },
    { rejectWithValue }
  ) => {
    const config = apiConfigProvider();
    try {
      return await ProductApiService.getInstance(config).getDecisionDeviceChangeIncrease(
        networkType,
        romIcCardDeviceCategory,
        eSimFlag,
        auPhoneUsePeriod,
        densyu,
        mobileDeviceJudgment,
        productAttribute,
        microSim,
        singleDeviceType,
        winIsSeriesDeviceCategory
      );
    } catch (err) {
      const errInfo: AxiosError<order.ApiErrors> = err;
      return rejectWithValue(handleAxiosError(errInfo, 'BFF-PRD-006'));
    }
  }
);

/**
 * BFF-PRD-007: AppleWatch用保証・サポートサービス取得API
 */
export const getCandidateAfterSaleServicesForAppleWatch = createAsyncThunk<
  // Api正常時レスポンスの型
  order.CandidateAfterSaleServicesForAppleWatchGetResponseV1,
  // Apiリクエストパラメータの型
  {
    productStockCode: string;
    appleWatchProductStockCode: string | undefined;
    contractType: '1' | '2' | '3' | '4' | '5' | '8' | '9';
  },
  // Api異常時レスポンスの型
  { rejectValue: ErrorInfo }
>(
  'productService/getCandidateAfterSaleServicesForAppleWatch',
  async ({ productStockCode, appleWatchProductStockCode, contractType }, { rejectWithValue }) => {
    const config = apiConfigProvider();
    try {
      return await ProductApiService.getInstance(config).getCandidateAfterSaleServicesForAppleWatch(
        productStockCode,
        appleWatchProductStockCode,
        contractType
      );
    } catch (err) {
      const errInfo: AxiosError<order.ApiErrors> = err;
      return rejectWithValue(handleAxiosError(errInfo, 'BFF-PRD-007'));
    }
  }
);

/**
 * BFF-PRD-008: お届け可能日取得API
 */
export const getDeliveryAvailableDate = createAsyncThunk<
  // Api正常時レスポンスの型
  order.DeliveryAvailableDateListGetResponseV1,
  // Apiリクエストパラメータの型
  {
    productStockCode: string;
    sameDayDeliveryAvailable: '1' | '0';
    postalCode: string;
    contractType?: '1' | '2' | '3' | '4' | '5' | '8' | '9';
    optionGoodsList?: Array<string>;
  },
  // Api異常時レスポンスの型
  { rejectValue: ErrorInfo }
>(
  'productService/getDeliveryAvailableDate',
  async (
    { productStockCode, sameDayDeliveryAvailable, postalCode, contractType, optionGoodsList },
    { rejectWithValue }
  ) => {
    const config = apiConfigProvider();
    try {
      return await ProductApiService.getInstance(config).getDeliveryAvailableDate(
        productStockCode,
        sameDayDeliveryAvailable,
        postalCode,
        contractType,
        optionGoodsList
      );
    } catch (err) {
      const errInfo: AxiosError<order.ApiErrors> = err;
      return rejectWithValue(handleAxiosError(errInfo, 'BFF-PRD-008'));
    }
  }
);
