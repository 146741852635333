import { order } from 'ols-order-webservice-typescript-client';
import promiseRetry from 'promise-retry';
import { ContractType } from '@/typings/ContractType';
import { OsType } from '@/typings/OsType';
import { REACT_APP_INCLUDE_STUB_MODE } from '@/config/env';
import { sleep } from '@/utils/async';
import { consoleLog } from '@/utils/functions';

const { SystemApi } = order;

const retryConfig = {
  retries: 2,
  randomize: true
};

const alternativeResponse: (
  ids: string[],
  productStockCode?: string,
  contractType?: ContractType,
  deviceCategory?: order.DeviceCategoryBffEnum,
  osType?: OsType,
  contractorBirthday?: string,
  userBirthday?: string
) => order.IncludeContentResponseV1[] = (
  ids,
  productStockCode,
  contractType,
  deviceCategory,
  osType,
  contractorBirthday,
  userBirthday
) =>
  ids.map((i) => ({
    productStockCode,
    contentId: i,
    content: '',
    contractType: contractType as order.ContractTypeBffEnum | undefined,
    deviceCategory: deviceCategory as order.DeviceCategoryBffEnum | undefined,
    osType: osType as order.OsTypeBffEnum | undefined,
    contractorBirthday,
    userBirthday
  }));

export class SystemApiService {
  private static instance: SystemApiService;

  private api: order.SystemApi;

  private constructor(config: order.Configuration) {
    this.api = new SystemApi(config);
  }

  public static getInstance(config: order.Configuration): SystemApiService {
    if (!this.instance) {
      this.instance = new SystemApiService(config);
    }

    return this.instance;
  }

  /**
   * BFF-SYS-001: includeコンテンツ取得API
   * @param includeIds - 取得したいincludeのIDを複数設定します。
   * @param productStockCode - 注文商品の商品コードを指定します。
   * @param contractType - 契約種別
   * @param deviceCategory - 端末カテゴリ
   * @param osType - OS種類を設定します。
   * @returns
   */
  async getIncludes(
    includeIds: Array<string>,
    productStockCode?: string,
    contractType?: ContractType,
    deviceCategory?: order.DeviceCategoryBffEnum,
    osType?: OsType,
    contractorBirthday?: string,
    userBirthday?: string
  ): Promise<order.IncludeContentResponseV1[]> {
    if (REACT_APP_INCLUDE_STUB_MODE === 'true') {
      await sleep();
      return includeIds.map((id) => ({
        contentId: id,
        productStockCode,
        contractType: contractType as order.ContractTypeBffEnum,
        deviceCategory: deviceCategory as order.DeviceCategoryBffEnum,
        osType: osType as order.OsTypeBffEnum,
        contractorBirthday,
        userBirthday,
        content: `
        <div class="alert--notices" style="color: darkgreen; font-size: smaller">
          インクルード「${id}」@stubモードです。<br />
          ※商品コード:[${productStockCode}] 契約種別:[${contractType}] 機種:[${deviceCategory}] OS:[${osType}] 契約者生年月日:[${contractorBirthday}] 利用者生年月日:[${userBirthday}]<br />
          <p>
            モーダル表示：
            <a
              data-modal-include-id="1_modal"
              data-modal-include-type="modal"
              data-modal-include-close="-"
            >modal</a>
            <a
              data-modal-include-id="1_notes"
              data-modal-include-type="notes"
            >notes</a>
            <a
              data-modal-include-id="1_important_info"
              data-modal-include-type="important_info"
            >important_info</a>
          </p>
          <p>
            <a data-modal-include-id="1_modal" data-modal-include-type="modal">
                <span><span></span><span>Nested</span><span></span></span>
            </a>
          </p>
          <p>
            <a href="https://www.nri-net.com" target="_blank">
              target="_blank" でのリンク
            </a>
          </p>
        </div>
      `
      }));
    }

    return promiseRetry(
      (retry) =>
        this.api
          .getIncludesV1({
            includeIds,
            productStockCode,
            contractType: contractType as order.ContractTypeBffEnum,
            deviceCategory: deviceCategory as order.DeviceCategoryBffEnum,
            osType: osType as order.OsTypeBffEnum,
            contractorBirthday,
            userBirthday
          })
          .catch(retry),
      retryConfig
    ).then((res) => {
      // 取得結果が0件の場合
      if (res.data.length === 0) {
        return alternativeResponse(
          includeIds,
          productStockCode,
          contractType,
          deviceCategory,
          osType,
          contractorBirthday,
          userBirthday
        );
      }
      return res.data;
    });
  }

  /**
   * BFF-SYS-002: ログ登録API
   * @param logPostRequestV1 - ログ情報
   * @returns
   */
  createLog(logPostRequestV1: order.LogPostRequestV1): Promise<void> {
    consoleLog('BFF-SYS-002: ログ登録API call', logPostRequestV1);
    return this.api.createLogV1(logPostRequestV1).then((res) => res.data);
  }

  /**
   * BFF-SYS-003: マスク対象取得API
   * @returns 各機能をマスクして利用不可とすべき対象を返却します。
   */
  getUnavailableServiceStatuses(): Promise<order.UnavailableServiceStatusGroupV1[]> {
    consoleLog('BFF-SYS-003: マスク対象取得API call');
    return this.api.getUnavailableServiceStatusesV1().then((res) => res.data);
  }

  /**
   * BFF-SYS-004: 見積もり選択可能オプション取得API
   * @param auSmartpassState - auスマートパス加入状況
   * @param applemusicState - AppleMusic加入状況
   * @param youtubePremiumState - YouTubePremium加入状況
   * @param auBicycleSupportState - au自転車サポート加入状況
   * @param auWellnessState - auウェルネス加入状況
   * @param allstarPack1Available - AllstarPack1フラグ
   * @returns
   */
  getAvailableEstimateOptions(
    auSmartpassState: ' ' | '1' | '2' | 'E',
    applemusicState: ' ' | 'Y' | 'E',
    youtubePremiumState: ' ' | 'Y' | 'E',
    auBicycleSupportState: ' ' | 'Y' | 'E',
    auWellnessState: ' ' | 'Y' | 'E',
    allstarPack1Available: '0' | '1'
  ): Promise<order.AvailableEstimateOptionsGetResponseV1> {
    consoleLog(
      'BFF-SYS-004: 見積もり選択可能オプション取得API call',
      auSmartpassState,
      applemusicState,
      youtubePremiumState,
      auBicycleSupportState,
      auWellnessState,
      allstarPack1Available
    );
    return this.api
      .getAvailableEstimateOptionsV1(
        auSmartpassState,
        applemusicState,
        youtubePremiumState,
        auBicycleSupportState,
        auWellnessState,
        allstarPack1Available
      )
      .then((res) => res.data);
  }

  /**
   * BFF-SYS-005: システム設定情報取得API
   * @returns
   */
  getSystemSettings(): Promise<order.SystemSettingsGetResponseV1> {
    consoleLog('BFF-SYS-005: システム設定情報取得API call');
    return this.api.getSystemSettingsV1().then((res) => res.data);
  }

  /**
   * BFF-SYS-006: MNP予約番号有効性確認API
   * @returns
   */
  getMnpEffectiveness(
    mnpReservationNo: string,
    mnpLimitDate: string
  ): Promise<order.MnpEffectivenessConformationGetResponseV1> {
    consoleLog('BFF-SYS-006: MNP予約番号有効性確認API call', mnpReservationNo, mnpLimitDate);
    return this.api.getMnpEffectivenessV1(mnpReservationNo, mnpLimitDate).then((res) => res.data);
  }

  /**
   * BFF-SYS-007: 利用不可文字確認API
   * @returns
   */
  validUnavailableCharacters(
    unavailableCharactersPostRequestV1?: order.UnavailableCharactersPostRequestV1
  ): Promise<order.UnavailableCharactersPostResponseV1> {
    consoleLog('BFF-SYS-007: 利用不可文字確認API call', unavailableCharactersPostRequestV1);
    return this.api.validUnavailableCharactersV1(unavailableCharactersPostRequestV1).then((res) => res.data);
  }
}
