import { ActionCreatorWithoutPayload } from '@reduxjs/toolkit';
import { AppDispatch } from '@/store';
import {
  getAccount,
  getPoint,
  getContract,
  getAccountComplements,
  getAuEasyPayments,
  resetAccountsServiceState,
  resetPointsServiceState,
  resetContractsServiceState,
  resetAccountComplementsServiceState,
  resetAuEasyPaymentsServiceState
} from './actions';

const resetAccountsState = resetAccountsServiceState as ActionCreatorWithoutPayload<string>;
const resetPointsState = resetPointsServiceState as ActionCreatorWithoutPayload<string>;
const resetContractsState = resetContractsServiceState as ActionCreatorWithoutPayload<string>;
const resetAccountComplementsState = resetAccountComplementsServiceState as ActionCreatorWithoutPayload<string>;
const resetAuEasyPaymentState = resetAuEasyPaymentsServiceState as ActionCreatorWithoutPayload<string>;

/**
 * BFF-USR-001: アカウント取得API
 * @returns
 */
export const getAccounts = () => (dispatch: AppDispatch): ReturnType<ReturnType<typeof getAccount>> =>
  dispatch(getAccount());

/**
 * BFF-USR-001: アカウント取得API初期化
 * @returns
 */
export const resetAccounts = () => (dispatch: AppDispatch): ReturnType<typeof resetAccountsState> =>
  dispatch(resetAccountsState());

/**
 * BFF-USR-002: ポイント取得API
 * @param auContractId - au契約ID
 * @returns
 */
export const getPoints = (auContractId: string) => (dispatch: AppDispatch): ReturnType<ReturnType<typeof getPoint>> =>
  dispatch(getPoint({ auContractId }));

/**
 * BFF-USR-002: ポイント取得API初期化
 * @returns
 */
export const resetPoints = () => (dispatch: AppDispatch): ReturnType<typeof resetPointsState> =>
  dispatch(resetPointsState());

/**
 * BFF-USR-003: 契約取得API
 * @param auContractId - au契約ID
 * @param tCategoryOptionCodes - ユーザーの契約関連情報に含まれるT系オプションコード一覧
 * @param contractType - 契約種別
 * @param birthday - 生年月日
 * @param auPhoneNo - au電話番号
 * @returns
 */
export const getContracts = (
  auContractId: string,
  tCategoryOptionCodes: Array<string>,
  birthday?: string,
  auPhoneNo?: string
) => (dispatch: AppDispatch): ReturnType<ReturnType<typeof getContract>> =>
  dispatch(
    getContract({
      auContractId,
      tCategoryOptionCodes,
      birthday,
      auPhoneNo
    })
  );

/**
 * BFF-USR-003: 契約取得API初期化
 * @returns
 */
export const resetContracts = () => (dispatch: AppDispatch): ReturnType<typeof resetContractsState> =>
  dispatch(resetContractsState());

/**
 * BFF-USR-004: アカウント追加情報取得API
 * @returns
 */
export const getComplements = () => (dispatch: AppDispatch): ReturnType<ReturnType<typeof getAccountComplements>> =>
  dispatch(getAccountComplements());

/**
 * BFF-USR-004: アカウント追加情報取得API初期化
 * @returns
 */
export const resetComplements = () => (dispatch: AppDispatch): ReturnType<typeof resetAccountComplementsState> =>
  dispatch(resetAccountComplementsState());

/**
 * BFF-USR-005: auかんたん決済残高情報取得API
 * @param processType -処理区分
 * @returns
 */
export const getAuEasyPayment = (processType: '1' | '2' | '3') => (
  dispatch: AppDispatch
): ReturnType<ReturnType<typeof getAuEasyPayments>> =>
  dispatch(
    getAuEasyPayments({
      processType
    })
  );

/**
 * BFF-USR-005: auかんたん決済残高情報取得API初期化
 * @returns
 */
export const resetAuEasyPayment = () => (dispatch: AppDispatch): ReturnType<typeof resetContractsState> =>
  dispatch(resetAuEasyPaymentState());
