import type { RootState } from '@/store';

/**
 * BFF-ORD-001: 決済ステータス情報登録API
 * @param state - ステート
 * @returns
 */
export const createPaymentStatusResult = (
  state: RootState
): RootState['entity']['orderPaymentService']['createPaymentStatus'] =>
  state.entity.orderPaymentService.createPaymentStatus;

/**
 * BFF-ORD-002: 決済実行API
 * @param state - ステート
 * @returns
 */
export const createPaymentResult = (state: RootState): RootState['entity']['orderPaymentService']['createPayment'] =>
  state.entity.orderPaymentService.createPayment;

/**
 * BFF-ORD-003: 認証後決済実行API
 * @param state - ステート
 * @returns
 */
export const createAuthenticatedPaymentResult = (
  state: RootState
): RootState['entity']['orderPaymentService']['createAuthenticatedPayment'] =>
  state.entity.orderPaymentService.createAuthenticatedPayment;

/**
 * BFF-ORD-004: 注文情報登録API
 * @param state - ステート
 * @returns
 */
export const createOrderResult = (state: RootState): RootState['entity']['orderPaymentService']['createOrder'] =>
  state.entity.orderPaymentService.createOrder;

/**
 * BFF-ORD-005: 注文時確認事項登録API
 * @param state - ステート
 * @returns
 */
export const createOrderConfirmationResult = (
  state: RootState
): RootState['entity']['orderPaymentService']['createOrderConfirmation'] =>
  state.entity.orderPaymentService.createOrderConfirmation;

/**
 * BFF-ORD-008: お支払い方法登録API
 * @param state - ステート
 * @returns
 */
export const createPaymentMethodResult = (
  state: RootState
): RootState['entity']['orderPaymentService']['createPaymentMethod'] =>
  state.entity.orderPaymentService.createPaymentMethod;

/**
 * BFF-ORD-009: 口振GW受取文字列チェックAPI
 * @param state - ステート
 * @returns
 */
export const validAccountTransferV1Result = (
  state: RootState
): RootState['entity']['orderPaymentService']['validAccountTransfer'] =>
  state.entity.orderPaymentService.validAccountTransfer;

/**
 * BBFF-ORD-010: 口振GW受取文字列登録API
 * @param state - ステート
 * @returns
 */
export const createAccountTransferResult = (
  state: RootState
): RootState['entity']['orderPaymentService']['createAccountTransfer'] =>
  state.entity.orderPaymentService.createAccountTransfer;

/**
 * BFF-ORD-011: auかんたん決済実行API
 * @param state - ステート
 * @returns
 */
export const paymentAuPaymentResult = (
  state: RootState
): RootState['entity']['orderPaymentService']['paymentAuEasyPayment'] =>
  state.entity.orderPaymentService.paymentAuEasyPayment;
