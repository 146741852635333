import { order } from 'ols-order-webservice-typescript-client';
import { AppDispatch } from '@/store';
import { CategoryInfo } from '@/typings/CategoryInfo';
import { setAccessory } from './actions';

/**
 * 商品情報設定オペレータ
 * @param goodsList - 商品情報
 * @returns
 */
export const setGoodsList = (goodsList: order.OptionGoodsListGetResponseV1[]) => (
  dispatch: AppDispatch
): ReturnType<typeof setAccessory> => dispatch(setAccessory({ goodsList }));

/**
 * 無料同梱品ワーニング表示フラグ設定オペレータ
 * @param freeBundledGoodsWarningDisplayFlag - 無料同梱品ワーニング表示フラグ
 * @returns
 */
export const setFreeBundledGoodsWarningDisplayFlag = (freeBundledGoodsWarningDisplayFlag: boolean) => (
  dispatch: AppDispatch
): ReturnType<typeof setAccessory> => dispatch(setAccessory({ freeBundledGoodsWarningDisplayFlag }));

/**
 * カテゴリー一覧表示順リスト設定オペレータ
 * @param orderedCategoryList - カテゴリー一覧表示順リスト
 * @returns
 */
export const setOrderedCategoryList = (orderedCategoryList: CategoryInfo[]) => (
  dispatch: AppDispatch
): ReturnType<typeof setAccessory> => dispatch(setAccessory({ orderedCategoryList }));

/**
 * カテゴリー一覧表示順リスト初期化オペレータ
 * @returns
 */
export const resetOrderedCategoryList = () => (dispatch: AppDispatch): ReturnType<typeof setAccessory> =>
  dispatch(setAccessory({ orderedCategoryList: [] }));
