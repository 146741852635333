import { combineReducers, createSlice } from '@reduxjs/toolkit';
import { order } from 'ols-order-webservice-typescript-client';
import { ServiceState } from '@/typings/ServiceState';
import { getAddress } from './thunk';

const initialState = {
  loaded: false,
  failed: false,
  response: null,
  error: null
};

/**
 * BFF-ADD-001: 住所情報取得API
 */
export const getAddressSlice = createSlice<ServiceState<order.AddressInfoGetResponseV1>, Record<string, never>>({
  name: 'addressService/getAddressV1',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAddress.pending, () => initialState)
      .addCase(getAddress.rejected, (state, action) => ({
        ...state,
        ...{
          loaded: false,
          failed: true,
          response: null,
          error: action.payload || null
        }
      }))
      .addCase(getAddress.fulfilled, (state, action) => ({
        ...state,
        ...{
          loaded: true,
          failed: false,
          response: action.payload,
          error: null
        }
      }));
  }
});

export const reducers = combineReducers({
  getAddressV1: getAddressSlice.reducer
});
