/**
 * UQ新旧ステータス
 * \'0\' - 新UQ
 * \'1\' - 旧UQ
 */
export type UqUserStatusType = '0' | '1';
export const UqUserStatusTypes = {
  newUq: '0',
  oldUq: '1'
} as const;
