import { AxiosError } from 'axios';
import { order } from 'ols-order-webservice-typescript-client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AgencyApiService } from '@/services/agencyApiService';
import { apiConfigProvider } from '@/services/http/apiConfig';
import { ErrorInfo } from '@/typings/ErrorInfo';
import { handleAxiosError } from '@/services/handleAxiosError';

/**
 * BFF-AGC-001: エリア情報取得API
 */
export const getAreas = createAsyncThunk<
  // Api正常時レスポンスの型
  order.AreasGetResponseV1,
  // Apiリクエストパラメータの型
  void,
  // Api異常時レスポンスの型
  { rejectValue: ErrorInfo }
>('agencyApiService/getAreas', async (_, { rejectWithValue }) => {
  const config = apiConfigProvider();
  try {
    return await AgencyApiService.getInstance(config).getAreas();
  } catch (err) {
    const errInfo: AxiosError<order.ApiErrors> = err;
    return rejectWithValue(handleAxiosError(errInfo, 'BFF-AGC-001'));
  }
});

/**
 * BFF-AGC-002: 都道府県情報取得API
 */
export const getPrefectures = createAsyncThunk<
  // Api正常時レスポンスの型
  order.PrefecturesGetResponseV1,
  // Apiリクエストパラメータの型
  { areaCode: string },
  // Api異常時レスポンスの型
  { rejectValue: ErrorInfo }
>('agencyApiService/getPrefectures', async ({ areaCode }, { rejectWithValue }) => {
  const config = apiConfigProvider();
  try {
    return await AgencyApiService.getInstance(config).getPrefectures(areaCode);
  } catch (err) {
    const errInfo: AxiosError<order.ApiErrors> = err;
    return rejectWithValue(handleAxiosError(errInfo, 'BFF-AGC-002'));
  }
});

/**
 * BFF-AGC-003: 市区町村情報取得API
 */
export const getDistricts = createAsyncThunk<
  // Api正常時レスポンスの型
  order.DistrictsGetResponseV1,
  // Apiリクエストパラメータの型
  { prefectureCode: string },
  // Api異常時レスポンスの型
  { rejectValue: ErrorInfo }
>('agencyApiService/getDistricts', async ({ prefectureCode }, { rejectWithValue }) => {
  const config = apiConfigProvider();
  try {
    return await AgencyApiService.getInstance(config).getDistricts(prefectureCode);
  } catch (err) {
    const errInfo: AxiosError<order.ApiErrors> = err;
    return rejectWithValue(handleAxiosError(errInfo, 'BFF-AGC-003'));
  }
});

/**
 * BFF-AGC-004: 受取可能店舗情報取得API
 */
export const getInstorePickupAgencies = createAsyncThunk<
  // Api正常時レスポンスの型
  order.InstorePickupAgenciesGetResponseV1,
  // Apiリクエストパラメータの型
  { districtCode: string },
  // Api異常時レスポンスの型
  { rejectValue: ErrorInfo }
>('agencyApiService/getInstorePickupAgencies', async ({ districtCode }, { rejectWithValue }) => {
  const config = apiConfigProvider();
  try {
    return await AgencyApiService.getInstance(config).getInstorePickupAgencies(districtCode);
  } catch (err) {
    const errInfo: AxiosError<order.ApiErrors> = err;
    return rejectWithValue(handleAxiosError(errInfo, 'BFF-AGC-004'));
  }
});
