import type { RootState } from '@/store';
import { PointCouponTitleCode } from '@/typings/PointCouponTitleCode';
import { ScreenIdValue } from '@/typings/ScreenId';

/**
 * 画面共通情報取得
 * @param state - ステート
 * @returns
 */
export const get = (state: RootState): RootState['flow']['viewCommon'] => state.flow.viewCommon;

/**
 * 遷移元画面ID取得
 * @param state - ステート
 * @returns 遷移元画面ID
 */
export const getLastScreenId = (state: RootState): '' | ScreenIdValue => {
  const { lastScreenId } = state.flow.viewCommon;
  return lastScreenId;
};

/**
 * ○○を選び直すURL取得
 * @param state - ステート
 * @returns ○○を選び直すURL
 */
export const getReselectButtonUrl = (state: RootState): string => {
  const { reselectButtonUrl } = state.flow.viewCommon;
  return reselectButtonUrl;
};

/**
 * auID・電話番号有無区分
 * @param state - ステート
 * @returns auID・電話番号有無区分
 */
export const getAuIdTelNumUmuKbn = (state: RootState): string => {
  const { auIdTelNumUmuKbn } = state.flow.viewCommon;
  return auIdTelNumUmuKbn;
};

/**
 * かんたん決済会員規約加入状態
 * @param state - ステート
 * @returns かんたん決済会員規約加入状態
 */
export const getAuEasyPaymentContStatus = (state: RootState): string => {
  const { auEasyPaymentContStatus } = state.flow.viewCommon;
  return auEasyPaymentContStatus;
};

/**
 * 接続元端末種別
 * @param state - ステート
 * @returns 接続元端末種別
 */
export const getAccessDeviceType = (state: RootState): string => {
  const { accessDeviceType } = state.flow.viewCommon;
  return accessDeviceType;
};

/**
 * 生年月日モーダル表示フラグ
 * @param state - ステート
 * @returns 生年月日モーダル表示フラグ
 */
export const getInputBirthdayConfirmFlag = (state: RootState): string | undefined => {
  const { inputBirthdayConfirmFlag } = state.flow.viewCommon;
  return inputBirthdayConfirmFlag;
};

/**
 * LIQUID審査申請API初回正常フラグ
 * @param state - ステート
 * @returns LIQUID審査申請API初回正常フラグ
 */
export const getLiquidClaimReviewInfoFirstNormalFlg = (state: RootState): boolean => {
  const { liquidClaimReviewInfoFirstNormalFlg } = state.flow.viewCommon;
  return liquidClaimReviewInfoFirstNormalFlg;
};

/**
 * LIQUID審査申請API正常フラグ(ブラウザバック判定用)
 * @param state - ステート
 * @returns LIQUID審査申請API結果引継ぎ用フラグ
 */
export const getLiquidClaimReviewTransferNormalFlg = (state: RootState): boolean => {
  const { liquidClaimReviewTransferNormalFlg } = state.flow.viewCommon;
  return liquidClaimReviewTransferNormalFlg;
};

/**
 * eKYCが一度でも選択されCVボタンが押下されたことを判定する用のフラグ
 * @param state - ステート
 * @returns eKYCが一度でも選択されCVボタンが押下されたことを判定する用のフラグ
 */
export const getEkycSelectedFlg = (state: RootState): boolean => {
  const { ekycSelectedFlg } = state.flow.viewCommon;
  return ekycSelectedFlg;
};

/**
 * ポイントクーポンの画面表示用文言
 * @param state - ステート
 * @returns ポイントクーポンの画面表示用文言
 */
export const getPointCouponTitleCode = (state: RootState): PointCouponTitleCode => {
  const { pointCouponTitleCode } = state.flow.viewCommon;
  return pointCouponTitleCode;
};

/**
 * 契約者住所
 * @param state - ステート
 * @returns 契約者住所
 */
export const getContractAddressInfo = (state: RootState): RootState['flow']['viewCommon']['ContractAddressInfo'] =>
  state.flow.viewCommon.ContractAddressInfo;

/**
 * 請求書送付先住所
 * @param state - ステート
 * @returns 請求書送付先住所
 */
export const getBillingAddressInfo = (state: RootState): RootState['flow']['viewCommon']['BillingAddressInfo'] =>
  state.flow.viewCommon.BillingAddressInfo;

/**
 * 入力された住所情報
 * @param state - ステート
 * @returns 入力された住所情報
 */
export const getInputAddressInfo = (state: RootState): RootState['flow']['viewCommon']['InputAddressInfo'] =>
  state.flow.viewCommon.InputAddressInfo;

/**
 * 遷移元画面ID(外部経由)取得
 * @param state - ステート
 * @returns 遷移元画面ID
 */
export const getExternalLastScreenId = (state: RootState): undefined | ScreenIdValue => {
  const { externalLastScreenId } = state.flow.viewCommon;
  return externalLastScreenId;
};

/**
 * スクロール先の要素のID取得
 * @param state - ステート
 * @returns スクロール先の要素のID
 */
export const getScrollTargetElementId = (state: RootState): undefined | string => {
  const { scrollTargetElementId } = state.flow.viewCommon;
  return scrollTargetElementId;
};
