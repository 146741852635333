import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getTokenCorpInfoApi,
  TokenCorpInfoRequestObject,
  TokenCorpInfoResponseObject
} from '@/services/tokenCorpInfoApi';

export type ApiState = {
  response: TokenCorpInfoResponseObject | null;
  status: 'idle' | 'succeeded' | 'failed';
};

const initialState: ApiState = {
  status: 'idle',
  response: null
};

export const fetchAsync = createAsyncThunk(
  'tokenCorpInfoApi/getTokenCorpInfoApi',
  async (request: TokenCorpInfoRequestObject) => {
    const response = await getTokenCorpInfoApi(request);
    return response;
  }
);

const TokenCorpInfoApiSlice = createSlice({
  name: 'TokenCorpInfoApi',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAsync.pending, () => initialState)
      .addCase(fetchAsync.rejected, (state, action) => ({
        ...state,
        ...{
          status: 'failed',
          response: null
        }
      }))
      .addCase(fetchAsync.fulfilled, (state, action) => ({
        ...state,
        ...{
          status: 'succeeded',
          response: action.payload
        }
      }));
  }
});

export const { actions, reducer } = TokenCorpInfoApiSlice;
