import { order } from 'ols-order-webservice-typescript-client';
import { NetworkType } from '@/typings/NetworkType';
import { consoleLog } from '@/utils/functions';

const { ChargesPlanApi } = order;

export class YearsContractInformation {
  private static instance: YearsContractInformation;

  private api: order.ChargesPlanApi;

  private constructor(config: order.Configuration) {
    this.api = new ChargesPlanApi(config);
  }

  public static getInstance(config: order.Configuration): YearsContractInformation {
    if (!this.instance) {
      this.instance = new YearsContractInformation(config);
    }

    return this.instance;
  }

  /**
   * BFF-CHP-003: 選択可能年契約情報取得API
   * @param dataPlanCode -データプランコード
   * @param callOptionCode -通話オプションコード
   * @param networkType -ネットワーク区分
   * @param rCategoryOptionCodes -R系オプションコード
   * @returns
   */
  getYearsContractInformation(
    dataPlanCode: string,
    callOptionCode: string,
    networkType: NetworkType,
    rCategoryOptionCodes: Array<string>
  ): Promise<order.AnnualContractsGetResponseV1> {
    consoleLog(
      'BFF-CHP-003: 選択可能年契約情報取得API call',
      dataPlanCode,
      callOptionCode,
      networkType,
      rCategoryOptionCodes
    );
    return this.api
      .getCandidateAnnualContractsV1(dataPlanCode, callOptionCode, networkType, rCategoryOptionCodes)
      .then((res) => res.data);
  }
}
