/**
 * 利用者の選択
 */
export type UsePersonSameValue = '0' | '1';
export const UsePersonSameValues = {
  sameContract: '0',
  diffContract: '1'
} as const;
export const UsePersonSameValueLabels = {
  '0': '契約者と同じ',
  '1': '契約者と異なる'
} as const;
