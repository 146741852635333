import type { RootState } from '@/store';

/**
 * BFF-AGC-001: エリア情報取得API
 * @param state - ステート
 * @returns
 */
export const getAreasResult = (state: RootState): RootState['entity']['agencyService']['getAreas'] =>
  state.entity.agencyService.getAreas;

/**
 * BFF-AGC-002: 都道府県情報取得API
 * @param state - ステート
 * @returns
 */
export const getPrefecturesResult = (state: RootState): RootState['entity']['agencyService']['getPrefectures'] =>
  state.entity.agencyService.getPrefectures;

/**
 * BFF-AGC-003: 市区町村情報取得API
 * @param state - ステート
 * @returns
 */
export const getDistrictsResult = (state: RootState): RootState['entity']['agencyService']['getDistricts'] =>
  state.entity.agencyService.getDistricts;

/**
 * BFF-AGC-004: 受取可能店舗情報取得API
 * @param state - ステート
 * @returns
 */
export const getInstorePickupAgenciesResult = (
  state: RootState
): RootState['entity']['agencyService']['getInstorePickupAgencies'] =>
  state.entity.agencyService.getInstorePickupAgencies;
