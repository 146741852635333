import DOMPurify from 'dompurify';

export const setupDompurify: () => void = () => {
  DOMPurify.addHook('afterSanitizeAttributes', (node) => {
    // target="_blank" なリンク
    if ('target' in node) {
      (node as HTMLAnchorElement).setAttribute('target', '_blank');
      (node as HTMLAnchorElement).setAttribute('rel', 'noopener');
    }
  });
};
