import type { RootState } from '@/store';

/**
 * BFF-IDE-001: 本人確認画像登録API
 * @param state - ステート
 * @returns
 */
export const uploadIdentificationImage = (
  state: RootState
): RootState['entity']['identificationService']['uploadIdentificationImages'] =>
  state.entity.identificationService.uploadIdentificationImages;

/**
 * BFF-IDE-002: 本人確認画像取得API
 * @param state - ステート
 * @returns
 */

export const getIdentificationImage = (
  state: RootState
): RootState['entity']['identificationService']['getIdentificationImage'] =>
  state.entity.identificationService.getIdentificationImage;

/**
 * BFF-IDE-003: eKYCを利用した本人確認手続き申請API
 * @param state - ステート
 * @returns
 */
export const createIdentificationResult = (
  state: RootState
): RootState['entity']['identificationService']['createIdentification'] =>
  state.entity.identificationService.createIdentification;

/**
 * BFF-IDE-004: OTP発行API
 * @param state - ステート
 * @returns
 */
export const createOneTimePassword = (
  state: RootState
): RootState['entity']['identificationService']['createOneTimePassword'] =>
  state.entity.identificationService.createOneTimePassword;

/**
 * BFF-IDE-005: OTPチェックAPI
 * @param state - ステート
 * @returns
 */
export const validOneTimePassword = (
  state: RootState
): RootState['entity']['identificationService']['validOneTimePassword'] =>
  state.entity.identificationService.validOneTimePassword;
