import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { order } from 'ols-order-webservice-typescript-client';
import { AddressApiService } from '@/services/addressApiService';
import { apiConfigProvider } from '@/services/http/apiConfig';
import { ErrorInfo } from '@/typings/ErrorInfo';
import { handleAxiosError } from '@/services/handleAxiosError';

/**
 * BFF-ADD-001: 住所情報取得API
 */
export const getAddress = createAsyncThunk<
  // Api正常時レスポンスの型
  order.AddressInfoGetResponseV1,
  // Apiリクエストパラメータの型
  {
    postalCode: string;
  },
  // Api異常時レスポンスの型
  { rejectValue: ErrorInfo }
>('addressService/getAddressV1', async ({ postalCode }, { rejectWithValue }) => {
  const config = apiConfigProvider();
  try {
    return await AddressApiService.getInstance(config).getAddressV1(postalCode);
  } catch (err) {
    const errInfo: AxiosError<order.ApiErrors> = err;
    return rejectWithValue(handleAxiosError(errInfo, 'BFF-ADD-001'));
  }
});
