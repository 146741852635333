import { hanToZen } from './char';
/**
 * 姓名分割
 * 受け取った姓名に空白がある場合、姓と名に分割し返却します。
 * 姓名に空白がない場合かつ姓名の桁数が奇数の場合、余剰分を名に含めます。
 * 姓名に空白がある場合は最初の空白で区切ります。
 * 例）
 * 「エイユウタロウ」⇒「エイユ」「ウタロウ」
 * 「エイユウ タロウ」⇒「エイユウ」「タロウ」
 * 「エイ ユウ タロウ」⇒「エイ」「ユウ タロウ」
 * @param name - 姓名
 * @returns
 */

export const divideName = (name: string | undefined): { lastName: string; firstName: string } => {
  const HalfSpace = ' ';
  const FullSpace = '　';
  const pattern = `[${HalfSpace}${FullSpace}]`;

  let lastName = '';
  let firstName = '';
  if (name && name.length > 0) {
    const regExp = new RegExp(pattern, 'ig');
    if (regExp.test(name)) {
      const splitValue = name.split(/\s/);
      const [l, ...f] = splitValue;
      lastName = hanToZen(l);
      firstName = hanToZen(f.join(FullSpace));
    } else {
      const firstNameIndex = name.length;
      const lastNameIndex = Math.floor(firstNameIndex / 2);
      lastName = hanToZen(name.substring(0, lastNameIndex));
      firstName = hanToZen(name.substring(lastNameIndex, firstNameIndex));
    }
  }
  return { lastName, firstName };
};
